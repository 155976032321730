import React, { useState, useContext, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Badge, Layout, Menu, Popover } from 'antd'
import { useRouteMatch, useHistory, Link } from 'react-router-dom'
import { Modal } from 'antd'
import {
  BarChartOutlined,
  BulbOutlined,
  CommentOutlined,
  DotChartOutlined,
  HomeOutlined,
  SettingOutlined,
  ShoppingOutlined,
  TagsOutlined,
  FileSearchOutlined,
  QuestionCircleOutlined,
  EyeOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  AppstoreOutlined,
  InboxOutlined,
  AlertOutlined,
  DashboardOutlined,
  AmazonOutlined,
  GoogleOutlined,
} from '@ant-design/icons'
import { AuthContext } from 'features/auth'
import mixpanel from 'features/trackers/mixpanel'
import { ShareButton } from 'features/project/features/share'
import { Header } from './header'
import {
  NETWORK_URL,
  FEEDBACK_URL,
  KEYWORDS_URL,
  PRODUCTS_URL,
  SOURCES_URL,
  SETTINGS_URL,
  CHARTS_URL,
  RECOMMENDATIONS_URL,
  SAVED_VIEWS_URL,
  trimText,
  ALERTS_URL,
  DASHBOARD_URL,
  CATALOG_URL,
  PDP_URL,
  GEMINI_URL,
  AUTO_INSIGHTS_URL,
} from '../utils'
import { isInitialFlow } from '../../home/utils'
import { profileURL } from 'utils/links'
import {
  getSavedViews,
  updateLastRecentlyUsed,
} from '../features/saved-vews/model'
import { useSavedViews } from '../features/saved-vews/utils'
import { useQuery } from 'react-query'
import { useProjectStore } from '../projectStore/projectStore'
import { ProjectState } from '../types'
import { Loader } from '../../../shared/components'
import { fetchProjects } from '../model'
import { useClickOutside } from '../../../shared/hooks'
import {
  cardBorderGrey,
  newRed,
  secondary,
  secondaryRed,
} from '../../../assets/styles/variables'
import { SavedView } from '../features/saved-vews/types'
import { getNotifications } from '../features/notifications/model'
import { getFeatureFlags } from '../../url-params/model'
import {
  AutoStoriesOutlined,
  BookOutlined,
  CenterFocusWeakOutlined,
  DashboardCustomizeOutlined,
  MenuBookOutlined,
} from '@mui/icons-material'
import { useFeatureFlags } from '../hooks/useFeatureFlags'
import useResizeObserver from 'use-resize-observer'
import AIcon from '../../../assets/images/icons/AIcon_180x180.png'

const { Content, Sider } = Layout
type Props = { children: React.ReactNode }
const { confirm } = Modal

const viewImages: { [key: string]: JSX.Element } = {
  dashboard: <BarChartOutlined />,
  feedback: <CommentOutlined />,
  network: <DotChartOutlined />,
  keywords: <FileSearchOutlined />,
  default: <EyeOutlined />,
}

export const ProjectLayout: React.FC<Props> = ({ children }) => {
  const history = useHistory()
  const [collapsed, setCollapsed] = useState(false)
  const route = useProjectStore((state: ProjectState) => state.route)
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const details = useProjectStore((state: ProjectState) => state.details)
  const isComparative = useProjectStore(
    (state: ProjectState) => state.isComparative,
  )
  const notifications = useProjectStore(
    (state: ProjectState) => state.localNotifications,
  )

  // TODO usememo?
  const authContext = useContext(AuthContext)
  const { url } = useRouteMatch()
  const encodedUrl = encodeURI(url)
  const showMenuItems = !isInitialFlow(details?.state)
  const settings = details?.clientSettings?.project?.pages
  const [profileWidth, setProfileWidth] = useState(200)
  const [projectHover, setProjectHover] = useState(false)
  const { updateFilters } = useSavedViews()
  // TODO this should also consider whether is meetyogi user
  const isAdmin =
    details?.role === 'admin' || authContext.userDetails?.yogi_user

  const { data: savedViews } = useQuery(
    ['saved-views', projectId],
    () => {
      if (projectId) {
        return getSavedViews(projectId)
      } else {
        return []
      }
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId,
    },
  )

  const [views, setViews] = useState<SavedView[]>([])

  useEffect(() => {
    if (savedViews) {
      setViews(savedViews.filter((view) => !!view.author))
    }
  }, [savedViews])

  const { data: projectsData } = useQuery(
    ['project-search'],
    () => fetchProjects(),
    { refetchOnWindowFocus: false },
  )

  const projects = projectsData?.data

  // TODO maybe use this for a cleaner conversion from shortened to lengthened proj title
  useEffect(() => {
    if (collapsed) {
      setProfileWidth(80)
      return
    }
    const timer = setTimeout(() => setProfileWidth(200), 300)
    return () => clearTimeout(timer)
  }, [collapsed])

  useEffect(() => {
    setCollapsed(isComparative)
  }, [isComparative])

  // useEffect(() => {
  //   if (route.isDashboard) {
  //     setCollapsed(true)
  //   }
  // }, [route.isDashboard])

  const [showProjects, setShowProjects] = useState(false)

  const selectedProject = {
    proj_uuid: details.proj_uuid,
    title: details.title_override ?? details.title,
  }

  const selectedRoutes = [
    route.isCharts && '1',
    route.isFeedback && '2',
    route.isNetwork && '3',
    route.isKeywords && '4',
    route.isSavedViews && '5',
    route.isSource && '6',
    route.isProduct && '7',
    route.isRecommendations && '8',
    route.isSettings && '9',
    route.isCompass && '10',
    route.isAlerts && '11',
    route.isDashboard && '12',
    route.isFocus && '13',
    route.isPDP && '14',
    route.isGemini && '15',
    route.isAutomated && '16',
    route.isFindings && '17',
    route.isAutoInsights && '18',
    // route.isInsights && '10',
  ].filter(Boolean) as string[]

  const isPublicViewer = details?.role === 'public_viewer'
  const username = authContext.user?.attributes?.given_name ?? ''
  const is_trial = authContext.userDetails?.free_trial
  const search = window.location.search

  const projectSelectorRef = useRef<HTMLDivElement>(null)

  const { ref: projectSelectorSizeRef, height: headerHeight } =
    useResizeObserver<HTMLDivElement>()

  useClickOutside(projectSelectorRef, () => {
    if (showProjects) {
      setShowProjects(false)
    }
  })

  const { data: feature_flags } = useFeatureFlags()

  // if (!details || !settings) return <LogoSpinner>Loading</LogoSpinner>
  if (!details || !settings || !feature_flags) return <Loader />

  return (
    <Wrapper>
      <Header />
      <Layout style={{ minHeight: 'calc(100vh - 40px)' }}>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={setCollapsed}
          id="menu"
        >
          {projects && projects.length > 1 && (
            <ProjectSelector ref={projectSelectorRef}>
              <SelectedProject
                visible={showProjects}
                onClick={() => {
                  if (!(collapsed && projectHover)) {
                    setShowProjects(!showProjects)
                  }
                }}
                onMouseEnter={() => setProjectHover(true)}
                onMouseLeave={() => setProjectHover(false)}
                style={{
                  justifyContent: collapsed ? 'center' : 'space-between',
                }}
                ref={projectSelectorSizeRef}
              >
                <div style={{ display: 'flex' }}>
                  {collapsed ? (
                    <AppstoreOutlined style={{ fontSize: '16px' }} />
                  ) : (
                    selectedProject.title
                  )}
                </div>
                {!collapsed && (
                  <div>
                    {showProjects ? <CaretUpOutlined /> : <CaretDownOutlined />}
                  </div>
                )}
                <OtherProjectsHover visible={collapsed && projectHover}>
                  <Triangle />
                  {projects.map((project, index) => (
                    <ProjectOption
                      key={project.uuid}
                      hover={true}
                      first={index === 0}
                      onClick={() => {
                        if (project.uuid === selectedProject.proj_uuid) {
                          setShowProjects(false)
                          return
                        }
                        mixpanel.track('charts change', {
                          proj_uuid: projectId,
                          value: project.uuid,
                        })
                        window.location.pathname = '/project/' + project.uuid
                      }}
                      selected={project.uuid === selectedProject.proj_uuid}
                    >
                      {project.title}
                    </ProjectOption>
                  ))}
                </OtherProjectsHover>
              </SelectedProject>
              <OtherProjects visible={!collapsed && showProjects}>
                {projects
                  .filter((project) => project.uuid !== projectId)
                  .map((project) => (
                    <ProjectOption
                      key={project.uuid}
                      onClick={() => {
                        if (project.uuid === selectedProject.proj_uuid) {
                          setShowProjects(false)
                          return
                        }
                        mixpanel.track('charts change', {
                          proj_uuid: projectId,
                          value: project.uuid,
                        })
                        // TODO if we want to keep session we can, but its buggy
                        // setProjectId(project.uuid)
                        // setShowProjects(false)
                        // history.push('/project/' + project.uuid)
                        window.location.pathname = '/project/' + project.uuid
                      }}
                      selected={project.uuid === selectedProject.proj_uuid}
                    >
                      {project.title}
                    </ProjectOption>
                  ))}
              </OtherProjects>
            </ProjectSelector>
          )}
          <ScrollableMenu
            id="menu"
            theme="dark"
            selectedKeys={selectedRoutes}
            mode="inline"
            headerHeight={headerHeight ?? 0}
            inlineCollapsed={collapsed}
          >
            {/*{isPublicViewer || (*/}
            {/*  <Menu.Item key="0" icon={<HomeOutlined />}>*/}
            {/*    <StyledLink*/}
            {/*      to="/"*/}
            {/*      onClick={(e) => {*/}
            {/*        e.preventDefault()*/}
            {/*        confirm({*/}
            {/*          content: 'Do you want to return to All Projects?',*/}
            {/*          onOk() {*/}
            {/*            history.push('/')*/}
            {/*          },*/}
            {/*          onCancel() {},*/}
            {/*        })*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      All Projects*/}
            {/*    </StyledLink>*/}
            {/*  </Menu.Item>*/}
            {/*)}*/}
            {!isPublicViewer && !settings.compass?.hidden && (
              <Menu.Item key="10" id="compass-tab" icon={<HomeOutlined />}>
                <StyledLink
                  to={`${encodedUrl}`}
                  onClick={(e) => {
                    e.preventDefault()
                    mixpanel.track('compass', {
                      proj_uuid: projectId,
                    })
                    history.push(`${encodedUrl}` + search)
                  }}
                >
                  {/*{settings.insights?.alias}*/}
                  Home
                </StyledLink>
              </Menu.Item>
            )}
            {showMenuItems && (
              <>
                <Menu.Item
                  key="12"
                  id="dashboard-tab"
                  icon={<DashboardOutlined />}
                >
                  <StyledLink
                    to={`${encodedUrl}/${DASHBOARD_URL}` + search}
                    onClick={(e) => {
                      e.preventDefault()
                      mixpanel.track('custom dashboard', {
                        proj_uuid: projectId,
                      })
                      history.push(`${encodedUrl}/${DASHBOARD_URL}` + search)
                    }}
                  >
                    {'Dashboards'}
                  </StyledLink>
                </Menu.Item>
                {!settings.dashboard.hidden && (
                  <Menu.Item
                    key="1"
                    id="charts-tab"
                    icon={<BarChartOutlined />}
                  >
                    <StyledLink
                      to={`${encodedUrl}/${CHARTS_URL}` + search}
                      onClick={(e) => {
                        e.preventDefault()
                        mixpanel.track('dashboard', {
                          proj_uuid: projectId,
                        })
                        history.push(`${encodedUrl}/${CHARTS_URL}` + search)
                      }}
                    >
                      {settings.dashboard.alias}
                    </StyledLink>
                  </Menu.Item>
                )}
                {!settings.feedback.hidden && (
                  <Menu.Item
                    key="2"
                    id="feedback-tab"
                    icon={<CommentOutlined />}
                  >
                    <StyledLink
                      to={`${encodedUrl}/${FEEDBACK_URL}` + search}
                      onClick={(e) => {
                        e.preventDefault()
                        mixpanel.track('feedback', {
                          proj_uuid: projectId,
                        })
                        history.push(`${encodedUrl}/${FEEDBACK_URL}` + search)
                      }}
                    >
                      {settings.feedback.alias}
                    </StyledLink>
                  </Menu.Item>
                )}
                {!settings.network.hidden && (
                  <Menu.Item
                    key="3"
                    id="network-tab"
                    icon={<DotChartOutlined />}
                  >
                    <StyledLink
                      to={`${encodedUrl}/${NETWORK_URL}` + search}
                      onClick={(e) => {
                        e.preventDefault()
                        mixpanel.track('network', {
                          proj_uuid: projectId,
                        })
                        history.push(`${encodedUrl}/${NETWORK_URL}` + search)
                      }}
                    >
                      {settings.network.alias}
                    </StyledLink>
                  </Menu.Item>
                )}
                {!settings.keywords.hidden && (
                  <Menu.Item
                    key="4"
                    id="keywords-tab"
                    icon={<FileSearchOutlined />}
                  >
                    <StyledLink
                      to={`${encodedUrl}/${KEYWORDS_URL}${search}` + search}
                      onClick={(e) => {
                        e.preventDefault()
                        mixpanel.track('keywords', {
                          proj_uuid: projectId,
                        })
                        history.push(`${encodedUrl}/${KEYWORDS_URL}` + search)
                      }}
                    >
                      {settings.keywords.alias}
                    </StyledLink>
                  </Menu.Item>
                )}
              </>
            )}
            {!settings.catalog?.hidden && (
              <Menu.Item
                key="13"
                id="focus-tab"
                icon={<BookOutlined style={{ fontSize: 15, marginLeft: -1 }} />}
              >
                <StyledLink
                  to={`${encodedUrl}/${CATALOG_URL}` + search}
                  onClick={(e) => {
                    e.preventDefault()
                    mixpanel.track('catalog', {
                      proj_uuid: projectId,
                    })
                    history.push(`${encodedUrl}/${CATALOG_URL}` + search)
                  }}
                >
                  Catalog
                </StyledLink>
              </Menu.Item>
            )}
            {!settings.pdp?.hidden && feature_flags?.pdp_tool && (
              <Menu.Item key="14" id="pdp-tab" icon={<AmazonOutlined />}>
                <StyledLink
                  to={`${encodedUrl}/${PDP_URL}` + search}
                  onClick={(e) => {
                    e.preventDefault()
                    mixpanel.track('pdp insights', {
                      action: 'open',
                      proj_uuid: projectId,
                    })
                    history.push(`${encodedUrl}/${PDP_URL}` + search)
                  }}
                >
                  PDP Insights
                </StyledLink>
              </Menu.Item>
            )}
            {!settings.alerts?.hidden && (
              <Menu.Item
                key="11"
                id="alerts-tab"
                icon={
                  <StyledBadge
                    dot={
                      !!notifications?.find?.(
                        (el) =>
                          el.notification_type
                            ?.toLowerCase()
                            .includes('alert') && !el.acknowledged_at,
                      )
                    }
                    offset={collapsed ? [-2, 16] : [-2, 2]}
                  >
                    <AlertOutlined></AlertOutlined>
                  </StyledBadge>
                }
              >
                <StyledLink
                  to={`${encodedUrl}/${ALERTS_URL}`}
                  onClick={(e) => {
                    e.preventDefault()
                    mixpanel.track('alerts', {
                      proj_uuid: projectId,
                    })
                    history.push(`${encodedUrl}/${ALERTS_URL}` + search)
                  }}
                >
                  {settings?.alerts?.alias ?? 'Alerts'}
                  {!!notifications?.find?.(
                    (el) =>
                      el.notification_type?.toLowerCase().includes('alert') &&
                      !el.acknowledged_at,
                  ) && <NotificationIcon />}
                </StyledLink>
              </Menu.Item>
            )}
            {!settings?.saved_views?.hidden && !is_trial && (
              <>
                <Menu.SubMenu
                  key="5"
                  // id="saved-views-tab"
                  icon={
                    <InboxOutlined
                      style={{ color: 'rgba(255, 255, 255, 0.65)' }}
                      onClick={(e) => {
                        // e.preventDefault()
                        mixpanel.track('saved-views', {
                          proj_uuid: projectId,
                        })
                        history.push(
                          `${encodedUrl}/${SAVED_VIEWS_URL}` + search,
                        )
                      }}
                    />
                  }
                  title={settings?.saved_views?.alias || 'Saved Views'}
                >
                  {views &&
                    views
                      .sort((a, b) =>
                        a.last_time_used > b.last_time_used ? -1 : 1,
                      )
                      .slice(0, Math.min(3, views.length))
                      .map((view) => (
                        <Menu.Item
                          key={view.id}
                          icon={viewImages[view.page]}
                          style={{ paddingLeft: '24px' }}
                          onClick={() => {
                            mixpanel.track('saved view', {
                              action: 'load',
                              value: trimText(view.name, 35),
                              location: 'toolbar',
                            })

                            updateLastRecentlyUsed(projectId, view.uuid)
                            updateFilters(view)
                          }}
                        >
                          {trimText(view.name, 35)}
                        </Menu.Item>
                      ))}
                  <Menu.Item
                    key="all-saved-views"
                    style={{ paddingLeft: '16px', textAlign: 'center' }}
                    onClick={() => {
                      mixpanel.track('saved-views', {
                        proj_uuid: projectId,
                      })
                      history.push(`${encodedUrl}/${SAVED_VIEWS_URL}` + search)
                    }}
                  >
                    See all saved views...
                  </Menu.Item>
                </Menu.SubMenu>
              </>
            )}
            {feature_flags?.gemini && (
              <Menu.Item key="15" id="gemini-tab" icon={<GoogleOutlined />}>
                <StyledLink
                  to={`${encodedUrl}/${GEMINI_URL}` + search}
                  onClick={(e) => {
                    e.preventDefault()
                    mixpanel.track('gemini', {
                      action: 'open',
                      proj_uuid: projectId,
                    })
                    history.push(`${encodedUrl}/${GEMINI_URL}` + search)
                  }}
                >
                  Gemini
                </StyledLink>
              </Menu.Item>
            )}
            {feature_flags?.automated_insights && (
              <>
                {/*<Menu.Item*/}
                {/*  key="16"*/}
                {/*  id="automated-tab"*/}
                {/*  icon={*/}
                {/*    <img*/}
                {/*      style={{*/}
                {/*        height: '80%',*/}
                {/*      }}*/}
                {/*      src={AIcon}*/}
                {/*    />*/}
                {/*  }*/}
                {/*>*/}
                {/*  <StyledLink*/}
                {/*    to={`${encodedUrl}/${AUTOMATED_URL}` + search}*/}
                {/*    onClick={(e) => {*/}
                {/*      e.preventDefault()*/}
                {/*      mixpanel.track('automated', {*/}
                {/*        action: 'open',*/}
                {/*        proj_uuid: projectId,*/}
                {/*      })*/}
                {/*      history.push(`${encodedUrl}/${AUTOMATED_URL}` + search)*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    Automated*/}
                {/*  </StyledLink>*/}
                {/*</Menu.Item>*/}
                <Menu.Item
                  key="18"
                  id="auto-insights-tab"
                  icon={
                    <img
                      style={{
                        height: 22,
                        marginLeft: -4,
                      }}
                      src={AIcon}
                    />
                  }
                  className={'auto-insights-nav-item'}
                >
                  <StyledLink
                    to={`${encodedUrl}/${AUTO_INSIGHTS_URL}` + search}
                    onClick={(e) => {
                      e.preventDefault()
                      mixpanel.track('auto-insights', {
                        action: 'open',
                        proj_uuid: projectId,
                      })
                      history.push(
                        `${encodedUrl}/${AUTO_INSIGHTS_URL}` + search,
                      )
                    }}
                    style={{
                      marginLeft: -4,
                    }}
                  >
                    Auto Insights{' '}
                    <NewBadge style={{ marginLeft: 10 }}>New</NewBadge>
                  </StyledLink>
                </Menu.Item>
              </>
            )}
            {/*{feature_flags?.automated_insights && (*/}
            {/*  <Menu.Item*/}
            {/*    key="17"*/}
            {/*    id="findings-tab"*/}
            {/*    icon={<ThunderboltOutlined />}*/}
            {/*  >*/}
            {/*    <StyledLink*/}
            {/*      to={`${encodedUrl}/${FINDINGS_URL}` + search}*/}
            {/*      onClick={(e) => {*/}
            {/*        e.preventDefault()*/}
            {/*        mixpanel.track('findings', {*/}
            {/*          action: 'open',*/}
            {/*          proj_uuid: projectId,*/}
            {/*        })*/}
            {/*        history.push(`${encodedUrl}/${FINDINGS_URL}` + search)*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      Findings*/}
            {/*    </StyledLink>*/}
            {/*  </Menu.Item>*/}
            {/*)}*/}
            {!settings.sources.hidden && feature_flags.sources_products && (
              <Menu.Item key="6" id="sources-tab" icon={<ShoppingOutlined />}>
                <StyledLink
                  to={`${encodedUrl}/${SOURCES_URL}`}
                  onClick={(e) => {
                    e.preventDefault()
                    mixpanel.track('sources', {
                      proj_uuid: projectId,
                    })
                    history.push(`${encodedUrl}/${SOURCES_URL}` + search)
                  }}
                >
                  {settings.sources.alias}
                </StyledLink>
              </Menu.Item>
            )}
            {!settings.products.hidden && feature_flags.sources_products && (
              <Menu.Item key="7" id="product-tab" icon={<TagsOutlined />}>
                <StyledLink
                  to={`${encodedUrl}/${PRODUCTS_URL}`}
                  onClick={(e) => {
                    e.preventDefault()
                    mixpanel.track('products', {
                      proj_uuid: projectId,
                    })
                    history.push(`${encodedUrl}/${PRODUCTS_URL}` + search)
                  }}
                >
                  {settings.products.alias}
                </StyledLink>
              </Menu.Item>
            )}
            {!settings.recommendations.hidden && (
              <Menu.Item
                key="8"
                id="recommendations-tab"
                icon={<BulbOutlined />}
              >
                <StyledLink
                  to={`${encodedUrl}/${RECOMMENDATIONS_URL}`}
                  onClick={(e) => {
                    e.preventDefault()
                    mixpanel.track('reports', {
                      proj_uuid: projectId,
                    })
                    history.push(
                      `${encodedUrl}/${RECOMMENDATIONS_URL}` + search,
                    )
                  }}
                >
                  {settings.recommendations.alias}
                </StyledLink>
              </Menu.Item>
            )}
            {isAdmin && !isPublicViewer && !settings.settings.hidden && (
              <Menu.Item key="9" id="settings-tab" icon={<SettingOutlined />}>
                <StyledLink
                  to={`${encodedUrl}/${SETTINGS_URL}`}
                  onClick={(e) => {
                    e.preventDefault()
                    mixpanel.track('settings', {
                      proj_uuid: projectId,
                    })
                    history.push(`${encodedUrl}/${SETTINGS_URL}` + search)
                  }}
                >
                  {settings.settings.alias}
                </StyledLink>
              </Menu.Item>
            )}
            {showMenuItems && !is_trial && !settings.share.hidden && (
              <ShareButton />
            )}
          </ScrollableMenu>
          <HelpMenu
            id="help-tab"
            theme="dark"
            mode="inline"
            selectedKeys={[]}
            style={{ width: profileWidth }}
          >
            <Menu.Item
              key="a"
              icon={
                <QuestionCircleOutlined
                  onClick={() => {
                    mixpanel.track('help', {
                      proj_uuid: projectId,
                    })
                  }}
                />
              }
              className="help"
              onClick={() => {
                mixpanel.track('help', {
                  proj_uuid: projectId,
                })
                return (window as any).StonlyWidget('setYOffset', 0)
              }}
            >
              {profileWidth === 200 && <NameWrapper>Help</NameWrapper>}
            </Menu.Item>
          </HelpMenu>
          {!isPublicViewer && (
            <Popover
              placement="right"
              content={
                <PopoverWrapper>
                  <button
                    style={{
                      borderBottom: '1px solid rgba(0,0,0,0.06)',
                      paddingBottom: 5,
                    }}
                    onClick={() => {
                      history.push(profileURL, { proj_uuid: projectId })
                    }}
                  >
                    Profile
                  </button>
                  <button
                    style={{
                      paddingTop: 5,
                    }}
                    onClick={authContext.signOut}
                  >
                    Sign Out
                  </button>
                  {authContext?.userDetails?.yogi_user && (
                    <button
                      style={{
                        marginTop: 5,
                        borderTop: '1px solid rgba(0,0,0,0.06)',
                        paddingTop: 5,
                        paddingBottom: 5,
                      }}
                      onClick={() => {
                        history.push(`/project/${projectId}/${SETTINGS_URL}`, {
                          proj_uuid: projectId,
                        })
                      }}
                    >
                      Settings
                    </button>
                  )}
                </PopoverWrapper>
              }
              trigger="click"
            >
              <ProfileMenu
                id="profile-tab"
                theme="dark"
                mode="inline"
                selectedKeys={[]}
                style={{ width: profileWidth }}
              >
                <Menu.Item
                  key="9"
                  icon={
                    <AvatarWrapper collapsed={collapsed}>
                      {username[0]}
                    </AvatarWrapper>
                  }
                  className="profile"
                >
                  {profileWidth === 200 && (
                    <NameWrapper>{username}</NameWrapper>
                  )}
                </Menu.Item>
              </ProfileMenu>
            </Popover>
          )}
        </Sider>
        <Content>{children}</Content>
      </Layout>
    </Wrapper>
  )
}

const ScrollableMenu = styled(Menu)<{ headerHeight: number }>`
  overflow: auto;
  //max-height: calc(100vh - 300px);
  max-height: ${({ headerHeight }) =>
    headerHeight
      ? `calc(100vh - ${240 + headerHeight}px)`
      : 'calc(100vh - 300px)'};
  color: hsla(0, 0%, 100%, 0.65);
  background: #001529;

  //&::-webkit-scrollbar {
  //  background: transparent;
  //  width: 10px;
  //  color: white;
  //}

  // Grey
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.05);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${cardBorderGrey};
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.8);
  }

  &::-webkit-scrollbar-thumb:hover {
    // @ts-ignore
    //background: linear-gradient(left, #888, #777);
  }

  .ant-menu-item-selected {
    background-color: #28364f !important;
  }
`

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;

  .ant-menu-inline-collapsed > .profile {
    padding: 0 calc(50% - 24px / 2);

    & > span {
      display: inline-block;
      width: 0;
      opacity: 0;
      margin-left: 0;
    }
  }

  .ant-menu-inline-collapsed > .help {
    padding: 0 calc(50% - 24px / 2);

    & > span {
      display: flex;
      align-items: center;
      margin-left: 0;
    }
  }
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  &:hover {
    text-decoration: none;
  }
`

const HelpMenu = styled(Menu)`
  position: fixed;
  bottom: 98px;
  z-index: 1;
  height: 48px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  background: #001529;

  transition: width 0.2s;
  cursor: pointer;

  & .ant-menu-item-active > div {
    border: 2px solid rgba(255, 255, 255, 1);
    transition: border 0.3s;
  }

  & > li {
    display: flex;
    align-items: center;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`

const ProfileMenu = styled(Menu)`
  position: fixed;
  bottom: 48px;
  z-index: 1;
  height: 48px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  background: #001529;

  transition: width 0.2s;
  cursor: pointer;

  & .ant-menu-item-active > div {
    border: 2px solid rgba(255, 255, 255, 1);
    transition: border 0.3s;
  }

  & > li {
    display: flex;
    align-items: center;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`

const AvatarWrapper = styled.div<{ collapsed: boolean }>`
  min-width: 24px;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  border: 2px solid rgba(255, 255, 255, 0.65);
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  transition: border 0.3s;
  line-height: 20px !important;
  margin-left: ${(props) => (props.collapsed ? '-10px' : '0px')}!important;
  margin-top: ${(props) => (props.collapsed ? '-10px' : '0px')}!important;
`
const NameWrapper = styled.div`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 120px;
  text-align: left;
`
const PopoverWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 0;

  button {
    background: none;
    border: none;
    cursor: pointer;
  }
`
const ProjectSelector = styled.div`
  margin: 15px 20px 10px;
`
const SelectedProject = styled.div<{ visible: boolean }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  color: rgba(255, 255, 255, 0.65);
  cursor: pointer;
  transition: 0.2s;
  background: #273243;
  border-radius: ${(props) => (props.visible ? '6px 6px 0px 0px' : '6px')};

  &:hover {
    color: white;
  }
`
const OtherProjects = styled.div<{ visible: boolean }>`
  display: flex;
  align-items: end;
  flex-direction: column;
  max-height: ${(props) => (props.visible ? '200px' : '0px')};
  transition: max-height 0.2s ease-in;
  color: #fff;
  overflow: ${(props) => (props.visible ? 'auto' : 'hidden')};
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  // Grey
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background: #273243;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${cardBorderGrey};
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.8);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(to right, #888, #777);
  }
`
const OtherProjectsHover = styled.div<{ visible: boolean }>`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
  z-index: 101;
  position: absolute;
  width: 200px;
  max-height: 400px;
  left: 30px;
  top: 0px;
  padding: 0px 0px 0px 20px;
  border-radius: 6px;
  overflow: auto;

  // Grey
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background: #273243;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${cardBorderGrey};
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.8);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(to right, #888, #777);
  }
`
const ProjectOption = styled.div<{
  selected: boolean
  hover?: boolean
  first?: boolean
}>`
  width: 100%;
  //overflow-x: hidden;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  color: rgba(255, 255, 255, 0.65);
  cursor: pointer;
  transition: 0.2s;
  background: #3f4a5a;
  background: ${(props) => (props.selected ? '#343e4b' : '#3f4a5a')};
  border-bottom: 1px solid #273243;

  &:hover {
    color: white;
    background: #343e4b;
  }

  //Idk why first-child doesnt work here
  border-top-left-radius: ${(props) => (props.first ? '6px' : '0px')};
  border-top-right-radius: ${(props) => (props.first ? '6px' : '0px')};

  &:last-child {
    border-bottom: none;
    border-bottom-left-radius: ${(props) => (props.hover ? '6px' : '0px')};
    border-bottom-right-radius: ${(props) => (props.hover ? '6px' : '0px')};
  }
`
const Triangle = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  left: 11px;
  top: 10px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #343e4b;
`
const NotificationIcon = styled.div`
  border-radius: 100%;
  border: 3.5px solid var(--secondary-red);
`
const StyledBadge = styled(Badge)`
  color: inherit;
  span {
    transition: color 0.1s !important;
  }
`

const NewBadge = styled.span`
  background: ${newRed};
  line-height: 1.5;
  color: white;
  padding: 1px 5px 0px;
  border-radius: 10px;
  font-size: 12px;
  margin-left: 5px;
  border: 1px solid white;
`
