import React, { useState } from 'react'
import SelectItem from '../../FilterPanelSelector/components/SelectItem/SelectItem'
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons'
import {
  AppliedFilterOption,
  ApplyGroupFilterPayload,
  FilterOptionValue,
} from '../../../../../types'
import styled from 'styled-components'

type Props = {
  theme: string
  subthemes: FilterOptionValue[]
  applySelect: (value: ApplyGroupFilterPayload['value']) => void
  currentAppliedFilter: AppliedFilterOption | undefined
  storedValues: FilterOptionValue[]
}
const NestedThemeInput: React.FC<Props> = ({
  theme,
  subthemes,
  applySelect,
  currentAppliedFilter,
  storedValues,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <SelectItem
          value={{
            value: subthemes.length === 1 ? subthemes[0].value : theme,
            n_posts: subthemes.reduce(
              (acc: number, el: FilterOptionValue) => acc + el.n_posts,
              0
            ),
            identifiers: subthemes
              .map((el: FilterOptionValue) => el.identifiers)
              .flat(),
          }}
          isParent={true}
          applySelect={applySelect}
          currentAppliedFilter={currentAppliedFilter}
          storedValues={storedValues}
        ></SelectItem>

        {subthemes.length > 1 && (
          <Caret onClick={() => setIsOpen((prevState) => !prevState)}>
            {isOpen ? <CaretUpFilled /> : <CaretDownFilled />}
          </Caret>
        )}
      </div>
      {subthemes.length > 1 && (
        <CollapsedContent
          style={{ maxHeight: isOpen ? 30 * subthemes.length : 0 }}
        >
          {subthemes.map((subtheme: FilterOptionValue) => (
            <SelectItem
              value={subtheme}
              applySelect={applySelect}
              currentAppliedFilter={currentAppliedFilter}
              storedValues={storedValues}
            ></SelectItem>
          ))}
        </CollapsedContent>
      )}
    </div>
  )
}

export default NestedThemeInput

const CollapsedContent = styled.div`
  margin-left: 20px;
  transition: max-height 0.2s ease-out, opacity 0.3s ease-out;
  max-height: 0;
  overflow: hidden;
`

const Caret = styled.div`
  padding: 0px 5px;
  margin-left: -15px;
  cursor: pointer;
`
