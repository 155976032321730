import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react'
import styled from 'styled-components'
import { Tooltip, Empty, Button, Popover, PaginationProps, Badge } from 'antd'
import { CloseOutlined, MoreOutlined } from '@ant-design/icons'
import ChartJS from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import ChartAnnotations from 'chartjs-plugin-annotation'
import { isValuableChart, fontSizeDict } from '../../../../charts/utils'
import {
  generateReadableFilters,
  diff,
  arrayDiff,
  mergeFilters,
} from '../../../utils'
import { AppliedFilterOptionRange } from '../../../../filters/types'
import html2canvas from 'html2canvas'
import { Filters, ProjectState } from 'features/project/types'
import mixpanel from 'features/trackers/mixpanel'
import { useProjectStore } from '../../../../../projectStore/projectStore'
import {
  buttonBlue,
  lightText,
  secondaryRed,
} from '../../../../../../../assets/styles/variables'
import { useChartData } from '../../../../../hooks/useChartData'
import { Loader } from '../../../../../../../shared/components'
import { ChartHeaderFilterPanel } from './chart-header-filter-panel'
import { Layout } from 'react-grid-layout'
import _ from 'lodash'
import { Chart as ChartComponent } from '../../../../charts/components/chart'
import ChartTools from '../../../../charts/components/ChartTools'
import useResizeObserver from 'use-resize-observer'
import ChartTitle from '../../../../charts/components/ChartTitle'
import { usePrevFilters } from '../../../../../hooks/usePrevFilters'

ChartJS.register(ChartDataLabels, ChartAnnotations)
type Props = {
  filters: Filters
  chartId: string
  tier1: string
  comparativeIndex: number
  isComparative: boolean
  size?: string
  isLink?: boolean
  dashboardControls: any
  updateItem?: (
    index: string,
    filterValues: Filters | null,
    dashboardControls: any,
    title?: string,
  ) => void
  isEditing?: boolean
  removeItem?: () => void
  duplicateItem?: (
    item: any,
    layout: Layout,
    filters: Filters,
    dashboardControls: any,
  ) => void
  item: any
  index?: string
  layout?: Layout
  hideHeader?: boolean
  updateItemLayout?: (
    item: any,
    layout: Layout,
    filters: Filters,
    dashboardControls: any,
  ) => void
  disableControls?: boolean
  focused?: boolean
  priorityItem?: string
}

const exCharts = ['numerical_charts', 'table_charts']

export interface ChartHandle {
  onExportChart: () => Promise<
    { base64: string; aspectRatio: number } | undefined
  >
  index: string
}

export const Chart = forwardRef<ChartHandle, Props>(
  (
    {
      filters,
      chartId: curChartId,
      comparativeIndex,
      isComparative,
      size,
      isLink,
      dashboardControls,
      tier1,
      isEditing,
      removeItem,
      duplicateItem,
      item,
      index,
      updateItem,
      layout,
      hideHeader,
      updateItemLayout,
      disableControls,
      focused,
      priorityItem,
    },
    ref,
  ) => {
    const route = useProjectStore((state: ProjectState) => state.route)
    const defaultFilterList = useProjectStore(
      (state: ProjectState) => state.defaultFilterList,
    )
    const dateWindows = useProjectStore((state) => state.dateWindows)

    const [localDashboardControls, setLocalDashboardControls] =
      useState(dashboardControls)
    const [chartId, setChartId] = useState(curChartId)
    const [mergedFilters, setMergedFilters] = useState<Filters>(
      item.filterValues,
    )
    const [disabled, setDisabled] = useState<boolean>(true)

    const { ref: legendRef, width: legendWidth } =
      useResizeObserver<HTMLDivElement>()
    const { ref: splitRef, width: splitWidth } =
      useResizeObserver<HTMLDivElement>()

    const {
      fontSize,
      reviewsCount,
      timeInterval,
      postType,
      pageSize,
      isDelta,
      order,
      isUnlinked,
      comparePeriod,
      hideParentCategories,
      showCategories,
    } = localDashboardControls

    useEffect(() => {
      setChartId(curChartId)
    }, [curChartId])

    const {
      data: chartData,
      isLoading,
      isError,
      isFetching,
    } = useChartData(
      0,
      mergedFilters,
      tier1,
      chartId,
      postType,
      timeInterval,
      reviewsCount,
      disabled,
    )

    const [initialLegendWidthPercent, setInitialLegendWidthPercent] =
      useState(0)

    useEffect(() => {
      if (isFetching) {
        setInitialLegendWidthPercent(
          legendWidth ? (legendWidth / (splitWidth || 1)) * 100 : 0,
        )
      }
    }, [isFetching])

    const chart = chartData?.charts[0]
    const [currentChart, setCurrentChart] = useState(chart)

    useEffect(() => {
      setCurrentChart(chart)
    }, [chart])

    const wrapperRef = useRef<HTMLDivElement>(null)
    const chartWrapperRef = useRef<HTMLDivElement>(null)

    const [chartTitle, setChartTitle] = useState<string | undefined>(
      item?.title ?? chart?.title,
    )

    useEffect(() => {
      setChartTitle(item?.title ?? chart?.title)
    }, [item?.title])

    const [prevRange, setPrevRange] = useState<
      AppliedFilterOptionRange | undefined
    >(undefined)

    useEffect(() => {
      updateItem && index && updateItem(index, null, null, chartTitle)
    }, [chartTitle])

    // this could initialize to the diff of the master filters and the local filters
    const [localFilterValues, setLocalFilterValues] = useState<Filters>({
      values: diff(item.filterValues.values, filters?.values ?? []),
      searchQuery: arrayDiff(
        item.filterValues.searchQuery,
        filters?.searchQuery ?? [],
      ),
      searchCondition: arrayDiff(
        item.filterValues.searchCondition,
        filters?.searchCondition ?? [],
      ),
    })

    useEffect(() => {
      const mergedObj = mergeFilters(filters, localFilterValues, isUnlinked)
      setMergedFilters(mergedObj)
      if (!_.isEqual(item.filterValues, localFilterValues)) {
        if (index && updateItem) {
          updateItem(index, localFilterValues, null)
        }
      }
      // wait until merged to allow query
      setDisabled(false)
    }, [filters, localFilterValues, isUnlinked])

    const [paginationMin, setPaginationMin] = useState<number>(0)
    const [currentPage, setCurrentPage] = useState<number>(1)

    // duplicated in charts/features/chart.tsx /shrug
    const onPageChange: PaginationProps['onChange'] = (page, newPageSize) => {
      if (pageSize !== newPageSize) {
        mixpanel.track('custom dashboard', {
          action: 'page size',
          value: newPageSize,
        })
        isNaN(newPageSize)
          ? // @ts-ignore
            updateDashboardControls('pageSize', undefined)
          : updateDashboardControls('pageSize', newPageSize)
        setCurrentPage(1)
        setPaginationMin(0)
      } else {
        setPaginationMin(pageSize ? (page - 1) * pageSize : 1)
        setCurrentPage(page)
      }
    }

    const toggleOrder = () => {
      setCurrentPage(1)
      setPaginationMin(0)
      const newOrder = order === 'asc' ? 'desc' : 'asc'
      mixpanel.track('custom dashboard', {
        action: 'bar chart order',
        value: newOrder,
      })
      updateDashboardControls('order', newOrder)
    }

    useImperativeHandle(
      ref,
      () => ({
        onExportChart: async () => {
          if (wrapperRef.current) {
            const parentElement = wrapperRef.current.parentElement
            if (parentElement) {
              if (
                chartWrapperRef.current &&
                wrapperRef.current &&
                currentChart
              ) {
                let canvas
                canvas = await html2canvas(wrapperRef.current, { scale: 2 })
                const base64 = canvas.toDataURL('image/jpg')
                return {
                  base64,
                  aspectRatio: canvas.width / canvas.height,
                  title: chartTitle ?? currentChart.title,
                  filters: generateReadableFilters(
                    localFilterValues,
                    defaultFilterList,
                  ),
                  type: 'chart',
                }
              }
            }
          }
        },
        index: index ?? '0',
      }),
      [currentChart, localFilterValues, defaultFilterList, chartTitle],
    )

    const updateDashboardControls = (key: string, value: any) => {
      const tempDashboardControls = JSON.parse(
        JSON.stringify(localDashboardControls),
      )
      tempDashboardControls[key] = value
      setLocalDashboardControls(tempDashboardControls)
      if (updateItem && index) {
        updateItem(index, null, tempDashboardControls)
      }
    }

    useEffect(() => {
      updateDashboardControls('expandedCategories', [])
    }, [tier1])

    const { newDelta, dateFilter } = usePrevFilters({
      filters: mergedFilters,
      dateWindows,
      defaultFilterList,
      prevIsDelta: isDelta,
    })

    useEffect(() => {
      updateDashboardControls('isDelta', newDelta)
    }, [newDelta])

    useEffect(() => {
      if (!_.isEqual(prevRange, dateFilter)) {
        setPrevRange(dateFilter as AppliedFilterOptionRange)
      }
    }, [dateFilter])

    const isValuableChartEmpty =
      currentChart &&
      isValuableChart(currentChart) &&
      currentChart.values?.length === 0

    // prob a better way to do this branching but i need to stop refactoring
    if (isError && !isLoading) {
      return (
        <EmptyContainer className={'drag-handle'}>
          <ChartWrapper style={{ width: '100%', background: 'transparent' }}>
            <EmptyWrapper>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <ChartHeaderFilterPanel
                  filterValues={localFilterValues}
                  updateLocalFilters={setLocalFilterValues}
                  comparativeIndex={0}
                  mergedFilters={mergedFilters}
                  parentFilters={filters}
                  disabled={disableControls}
                  isEditing={!!isEditing}
                  isUnlinked={isUnlinked}
                />
                {isEditing && (
                  <>
                    <Popover
                      trigger={'click'}
                      content={
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '5px',
                          }}
                        >
                          <Button
                            onClick={() =>
                              duplicateItem &&
                              layout &&
                              duplicateItem(
                                item,
                                layout,
                                localFilterValues,
                                localDashboardControls,
                              )
                            }
                          >
                            Duplicate
                          </Button>
                          <Button onClick={() => removeItem && removeItem()}>
                            Delete
                          </Button>
                        </div>
                      }
                      placement="bottomRight"
                      overlayClassName="country-popover"
                    >
                      <Button
                        icon={<MoreOutlined />}
                        style={{ marginLeft: 10 }}
                      ></Button>
                    </Popover>
                  </>
                )}
              </div>
              <Empty description={'Error Retrieving Chart'} />
            </EmptyWrapper>
          </ChartWrapper>
        </EmptyContainer>
      )
    }

    if (!currentChart || isLoading) {
      return (
        <LoadingWrapper className={'drag-handle'}>
          <Loader style={{ height: '50%', maxHeight: 300, zIndex: 10 }} />
          <LoaderTitle>{`Loading Chart`}</LoaderTitle>
        </LoadingWrapper>
      )
    }

    if ((!currentChart || isValuableChartEmpty) && !currentChart?.msg) {
      return (
        <EmptyContainer className={'drag-handle'}>
          <ChartWrapper style={{ width: '100%', background: 'transparent' }}>
            <EmptyWrapper>
              <ChartHeaderFilterPanel
                filterValues={localFilterValues}
                updateLocalFilters={setLocalFilterValues}
                comparativeIndex={0}
                mergedFilters={mergedFilters}
                parentFilters={filters}
                disabled={disableControls}
                isEditing={!!isEditing}
                isUnlinked={isUnlinked}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <Empty
                  description={'No data to visualize with current filters'}
                />

                {!!reviewsCount && (
                  <ReviewCountWrapper>
                    <ReviewCount>
                      <div>
                        Minimum R&R Set to{' '}
                        <span style={{ fontWeight: 500 }}>{reviewsCount}</span>
                      </div>
                      <div
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: '5px',
                          color: secondaryRed,
                        }}
                        onClick={() => {
                          updateDashboardControls('reviewsCount', 0)
                        }}
                      >
                        <CloseOutlined style={{ marginTop: '-2px' }} /> Clear
                      </div>
                    </ReviewCount>
                  </ReviewCountWrapper>
                )}
              </div>
            </EmptyWrapper>
          </ChartWrapper>
        </EmptyContainer>
      )
    }

    const isCanvasChart =
      currentChart.chart_type &&
      !isValuableChartEmpty &&
      !exCharts.includes(currentChart.chart_type)

    // not ideal but header is different enough in cdash that this is the best way to handle it for now
    const headerContent = (
      <>
        {!hideHeader && (
          <div
            className={'drag-handle'}
            style={{ cursor: isEditing ? 'move' : 'inherit' }}
          >
            <div
              style={{
                padding: 10,
                paddingBottom: 0,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <ChartTitle
                currentChart={currentChart}
                isEditing={!!isEditing}
                fontSize={fontSize}
                chartTitle={chartTitle}
                setChartTitle={setChartTitle}
                filters={filters}
                dashboardControls={dashboardControls}
                isLink={isLink}
                updateDashboardControls={updateDashboardControls}
              />
              {isEditing && (
                <>
                  <Popover
                    trigger={'click'}
                    content={
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '5px',
                        }}
                      >
                        {layout && index && updateItemLayout && (
                          <Button
                            onClick={() => {
                              const layoutCopy = _.cloneDeep(layout)
                              layoutCopy.w = layout.w > 6 ? 6 : 12
                              updateItemLayout(
                                item,
                                layoutCopy,
                                localFilterValues,
                                localDashboardControls,
                              )
                            }}
                          >
                            {layout.w > 6
                              ? 'Make Half Width'
                              : 'Make Full Width'}
                          </Button>
                        )}
                        <Button
                          onClick={() =>
                            duplicateItem &&
                            layout &&
                            duplicateItem(
                              item,
                              layout,
                              localFilterValues,
                              localDashboardControls,
                            )
                          }
                        >
                          Duplicate
                        </Button>
                        <Button onClick={() => removeItem && removeItem()}>
                          Delete
                        </Button>
                      </div>
                    }
                    placement="bottomRight"
                    overlayClassName="country-popover"
                  >
                    <Button
                      icon={<MoreOutlined />}
                      style={{ marginLeft: 10, marginTop: 1 }}
                    ></Button>
                  </Popover>
                </>
              )}
            </div>
            <Flex isEditing={isEditing}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                data-html2canvas-ignore={'true'}
              >
                <ChartHeaderFilterPanel
                  filterValues={localFilterValues}
                  updateLocalFilters={setLocalFilterValues}
                  comparativeIndex={0}
                  mergedFilters={mergedFilters}
                  parentFilters={filters}
                  disabled={disableControls}
                  isEditing={!!isEditing}
                  isUnlinked={isUnlinked}
                />
              </div>
              <ChartTools
                wrapperRef={wrapperRef}
                chartWrapperRef={chartWrapperRef}
                currentChart={currentChart}
                chartData={chartData}
                localDashboardControls={localDashboardControls}
                updateLocalDashboardControls={updateDashboardControls}
                isEditing={isEditing}
                isDelta={isDelta}
                isUnlinked={isUnlinked}
                setChartId={setChartId}
                currentPage={currentPage}
                pageSize={pageSize}
                order={order}
                toggleOrder={toggleOrder}
                onPageChange={onPageChange}
                prevRange={prevRange}
                fontSize={fontSize}
                comparativeIndex={comparativeIndex}
                comparePeriod={comparePeriod}
                timeInterval={timeInterval}
                hideParentCategories={hideParentCategories}
                tier1={tier1}
                showCategories={showCategories}
              />
            </Flex>
          </div>
        )}
      </>
    )

    return (
      <Wrapper
        ref={wrapperRef}
        fontSize={fontSize}
        canvasChart={!!isCanvasChart}
        size={size}
        focused={focused}
      >
        <Tooltip
          title={
            'This chart is Unlinked from the Parent Filter and as such may have Filters applied that are inconsistent with the Parent Filter'
          }
        >
          <Badge
            count={!!isUnlinked ? '·' : 0}
            size="small"
            style={{ position: 'absolute', right: 0 }}
            className={
              isEditing ? 'unlinked-badge-edit' : 'unlinked-badge-view'
            }
          >
            <div></div>
          </Badge>
        </Tooltip>
        <>
          {currentChart && localDashboardControls && (
            <ChartWrapper ref={chartWrapperRef} isFocus={route.isFocus}>
              <ChartComponent
                key={currentChart.chart_id + '-' + isEditing ? 'edit' : 'view'}
                filters={mergedFilters}
                chart={currentChart}
                comparativeIndex={comparativeIndex}
                isComparative={isComparative}
                headerContent={headerContent}
                localDashboardControls={localDashboardControls}
                setLocalDashboardControls={setLocalDashboardControls}
                updateLocalDashboardControls={updateDashboardControls}
                localPaginationMin={paginationMin}
                legendRef={legendRef}
                splitRef={splitRef}
                initialLegendWidthPercent={initialLegendWidthPercent}
                // isEditing={!!isEditing}
                // hideHeader={hideHeader}
                priorityItem={priorityItem}
              />
            </ChartWrapper>
          )}
        </>
      </Wrapper>
    )
  },
)

type WrapperProps = {
  params: {
    fontSize?: string
    canvasChart?: boolean
    size: string | undefined
    isEditing?: boolean
    focused?: boolean
  }
}

const Wrapper = styled.div<{
  canvasChart?: boolean
  size?: string
  fontSize?: string
  focused?: boolean
}>`
  height: 100%;
  width: 100%;
  display: block;
  background: white;
  align-items: stretch;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  ${({ canvasChart, size }) => {
    if (canvasChart && !size) return 'min-height: 100%'
    if (!canvasChart) return 'height: 100%'
  }};
  ${({ focused }) =>
    focused
      ? `border: 2px solid ${buttonBlue}`
      : 'border: 2px solid transparent'};

  border-radius: var(--border-radius);
  overflow: auto;
  ${({ canvasChart, size }) => {
    if (canvasChart) return 'overflow: hidden'
  }};

  @media print {
    page-break-after: always;
    padding: var(--default-padding);
  }

  * {
    font-size: ${({ fontSize }) => fontSizeDict[fontSize || 'medium']};
  }

  .split {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .gutter-horizontal {
    background: rgba(0, 0, 0, 0.06);
    cursor: ew-resize;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
    background-repeat: no-repeat;
    background-position: 50%;
    cursor: ew-resize;
  }

  .unlinked-badge-view {
    position: absolute;
    left: 10px;
  }

  .unlinked-badge-edit {
    position: absolute;
    left: 5px;
    top: 5px;
  }
`

const ChartWrapper = styled.div<{
  canvasChart?: boolean
  size?: string
  isFocus?: boolean
}>`
  display: flex;
  flex-direction: row;
  height: ${({ isFocus }) => (isFocus ? '100%' : 'calc(100% - 30px)')};
  background: white;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  //overflow-x: auto;
`

const LoadingWrapper = styled.div<{ canvasChart?: boolean; size?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: white;
  border-radius: var(--border-radius);
  overflow-x: auto;
`

const Flex = styled.div<{ isEditing?: boolean }>`
  display: flex;
  align-items: center;
  padding: 10px;
  background: white;
  justify-content: space-between;

  border-radius: var(--border-radius) var(--border-radius) 0 0;
`

const EmptyContainer = styled.div<{ size?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: 100%;
  background: white;
  border-radius: var(--border-radius);
  overflow-x: auto;
`

const EmptyWrapper = styled.div`
  padding: 10px;
  width: 100%;
`

const ReviewCountWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`
const ReviewCount = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  background: white;
  border-radius: 8px;
  padding: 10px 20px;
  border: 2px solid ${lightText};
`
const LoaderTitle = styled.div`
  text-align: center;
  font-size: var(--font-size-l);
  font-weight: 500;
`
