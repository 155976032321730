import React, { ChangeEvent, useEffect, useState } from 'react'
import { Button, Badge, Tooltip, Popover, Input, Checkbox } from 'antd'
import {
  SearchOutlined,
  ExportOutlined,
  CopyOutlined,
  DownloadOutlined,
} from '@ant-design/icons'
import mixpanel from 'features/trackers/mixpanel'
import styled from 'styled-components'
import { useClickOutside } from 'shared/hooks'
import {
  getBadgeLabels,
  HardcodedFilterOptionsEnum,
  reducerToComputeFiltersCount,
  updateFilterList,
} from '../../../../filters/helpers'
import { PanelBadges } from '../../../../filters/features/badge-panel/panel-badges'
import { FilterPanel } from '../../../../filters/features/FilterPanel/FilterPanel'
import { SearchPanel } from '../../../../filters/features/search-panel/search-panel'
import Draggable from 'react-draggable'
import useResizeObserver from 'use-resize-observer'
import { useProjectStore } from '../../../../../projectStore/projectStore'
import { Filters, ProjectState } from '../../../../../types'
import { FilterPresetManager } from '../../../../filters/features/filter-preset/filter-preset'
import { YogiButton } from '../../../../../../../components/UI/YogiButton'
import { cardBorderGrey } from '../../../../../../../assets/styles/variables'
import { TuneOutlined } from '@mui/icons-material'
import * as Yup from 'yup'
import { downloadPosts } from '../../../../feedback'
import { toast } from 'react-toastify'
import { FilterOption } from '../../../../filters/types'
import DashboardDateSelect from './DashboardDateSelect'
import { FilterButton } from '../../../../filters/FilterButton'
import { useLocalFilterList } from '../../../../../hooks/useLocalFilterList'
import _ from 'lodash'
import { timer } from 'd3'
import { tooltip } from '../../../../../../../utils/tooltip-data'
import { onCopyChart } from '../../../../charts/utils'
import { UnionChart } from '../../../../charts/types'

const validationSchema = Yup.string().email('not an email').required()
type Props = {
  reset?: any
  settings?: any
  download?: any
  filterPanelHeight?: string
  chartType?: string
  filterValues: Filters
  setFilters: (filters: Filters) => void
  comparativeIndex: number
  isNew?: boolean
  isEdit?: boolean
  disabled?: boolean
  isCurrent: boolean
  setIsCurrent?: (isCurrent: boolean) => void
  timeResolution: string
  setTimeResolution?: (timeResolution: string) => void
  trailingDaysCount: number | undefined
  setTrailingDaysCount?: (timeResolution: number | undefined) => void
  copyRef?: any
  dashboardId?: number
  dashboardName?: string
}

export const DashboardFilterHeader = ({
  reset,
  settings,
  download,
  filterPanelHeight,
  filterValues,
  setFilters,
  comparativeIndex,
  isNew,
  isEdit,
  disabled,
  isCurrent,
  setIsCurrent,
  timeResolution,
  setTimeResolution,
  trailingDaysCount,
  setTrailingDaysCount,
  copyRef,
  dashboardId,
  dashboardName,
}: Props) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const details = useProjectStore((state: ProjectState) => state.details)
  const route = useProjectStore((state: ProjectState) => state.route)
  const countries = useProjectStore((state: ProjectState) => state.countries)
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList,
  )
  const dateWindows = useProjectStore((state) => state.dateWindows)

  const panelRef = React.createRef<HTMLDivElement>()
  const [downloadLoading, setDownloadLoading] = useState(false)
  const [publicViewerEmail, setPublicViewerEmail] = useState<string>('')
  const [publicEmailIsValid, setPublicEmailIsValid] = useState<boolean>(false)
  const [isFilterPanelVisible, setFilterPanelVisible] = useState(false)
  const [isSearchPanelVisible, setSearchPanelVisible] = useState(false)
  const [dragged, setDragged] = useState(false)
  const [dragDisabled, setDragDisabled] = useState(true)
  const { ref, width = 0 } = useResizeObserver()
  const isSmall = !!width && width < 600

  const [localOpenedOptions, setLocalOpenedOptions] = useState<string[]>([])
  const [filterList, setFilterList] = useState<FilterOption[]>([])
  const [filterOptionsLoading, setFilterOptionsLoading] = useState(false)
  const [filterApplied, setFilterApplied] = useState(false)
  const [selectedOption, setSelectedOption] = useState<string>('')

  const [isCopying, setIsCopying] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)

  const [renderHackIndex, setRenderHackIndex] = useState<number>(0)
  useEffect(() => {
    setRenderHackIndex((prevState) => prevState + 1)
  }, [isEdit])

  const isPublicViewer = details.role === 'public_viewer'

  const { data: filterListData, isLoading } = useLocalFilterList(
    filterValues,
    selectedOption,
    localOpenedOptions,
    filterList,
    setFilterList,
  )

  useEffect(() => {
    setFilterList(filterListData ?? [])
    setLocalOpenedOptions([])
    setSelectedOption('')
  }, [filterValues])

  useEffect(() => {
    if (filterListData) {
      // this caches the filter on a per-field basis
      setFilterList((prevState) =>
        updateFilterList(prevState, localOpenedOptions, filterListData),
      )
    }
  }, [filterListData])

  useClickOutside(panelRef, (e) => {
    if (
      (e.target && e.target?.id.includes('filter-badge')) ||
      (e.target && e.target?.id.includes('filters-button')) ||
      (e.path && e.path[1]?.id === 'filters-button') ||
      dragged
    )
      return
    setFilterPanelVisible(false)
  })

  const downloadReviews = async (printCurrent?: boolean) => {
    setDownloadLoading(true)
    try {
      await downloadPosts(projectId, {
        email: isPublicViewer ? publicViewerEmail : undefined,
        ...(printCurrent
          ? {
              search_terms: filterValues.searchQuery,
              search_criteria: filterValues.searchCondition,
              criteria: filterValues.values,
            }
          : {}),
      })
      toast.success('Success')
    } catch (e) {
      toast.error('Error')
    } finally {
      setDownloadLoading(false)
    }
  }

  const downloadContent = (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ marginBottom: '10px', width: '100%' }}>
        <YogiButton
          type={'dashed'}
          onClick={() => {
            mixpanel.track('posts download', { action: 'all', ...route })
            downloadReviews()
          }}
          style={{ width: '100%' }}
        >
          Export All Reviews
        </YogiButton>
      </div>
      <div style={{ width: '100%' }}>
        <YogiButton
          type={'dashed'}
          key="2"
          onClick={() => {
            downloadReviews(true)
            mixpanel.track('posts download', { action: 'filtered', ...route })
          }}
          style={{ width: '100%' }}
        >
          Export Filtered Reviews
        </YogiButton>
      </div>
      {isPublicViewer && (
        // TODO TEST/FIX THIS -- certainly breaks hardcoded heights
        <Header>
          <StyledInput
            placeholder="Email..."
            value={publicViewerEmail}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const value = e.target.value
              setPublicViewerEmail(value)
              validationSchema
                .validate(value)
                .then(() => setPublicEmailIsValid(true))
                .catch(() => setPublicEmailIsValid(false))
            }}
          />
        </Header>
      )}
    </div>
  )

  useEffect(() => {
    // find the latest completed entry for that time resolution
    // setFilters with that datefilter applied
    if (!isCurrent || !timeResolution || !dateWindows?.[timeResolution]) return
    let dateFilter = JSON.parse(dateWindows[timeResolution][1].value)
    if (timeResolution === 'Trailing') {
      const dateWindow = dateWindows[timeResolution].find(
        (el) => el.daysCount === (trailingDaysCount ?? 30),
      )
      dateFilter = dateWindow ? JSON.parse(dateWindow.value) : dateFilter
    }
    const filterCopy = _.cloneDeep(filterValues)
    filterCopy.values = filterCopy.values.filter(
      ({ field }) => field !== HardcodedFilterOptionsEnum.CREATE_TIME,
    )
    filterCopy.values.push({
      field: HardcodedFilterOptionsEnum.CREATE_TIME,
      min: dateFilter.min,
      max: dateFilter.max,
      value_type: 0, // TODO??
    })
    setFilters(filterCopy)
  }, [isCurrent, timeResolution, trailingDaysCount, dateWindows])

  return (
    <OuterContainer>
      <ResizeContainer ref={ref}>
        <Container style={{ width: `${width}px` }}>
          <Wrapper>
            <Left style={{ gap: isSmall ? 5 : 10 }}>
              <span style={{ marginRight: 5, zIndex: 2 }}>
                <FilterButton
                  filterValues={filterValues}
                  isFilterPanelVisible={isFilterPanelVisible}
                  setFilterPanelVisible={setFilterPanelVisible}
                  filterOptionsLoading={filterOptionsLoading || !filterList}
                  isSmall={isSmall}
                  filterList={filterListData ?? []}
                  disabled={disabled}
                />
              </span>
              <span style={{ marginRight: 5, zIndex: 2 }}>
                <Badge count={filterValues.searchQuery?.length} size="small">
                  <YogiButton
                    id="search-button"
                    type="dashed"
                    onClick={() => setSearchPanelVisible(!isSearchPanelVisible)}
                    style={{ padding: isSmall ? '0 10px' : '' }}
                    disabled={disabled}
                  >
                    {isSmall ? (
                      <span>
                        <SearchOutlined />
                      </span>
                    ) : (
                      <span>
                        <SearchOutlined
                          style={{
                            pointerEvents: 'none',
                            marginLeft: -3,
                            marginRight: 4,
                          }}
                        />{' '}
                        Search
                      </span>
                    )}
                  </YogiButton>
                </Badge>
              </span>
              <div>
                <PanelBadges
                  filterValues={filterValues}
                  comparativeIndex={comparativeIndex}
                  small={isSmall}
                  openFilterPanel={
                    disabled
                      ? undefined
                      : (field) => {
                          setSelectedOption(field)
                          setLocalOpenedOptions(
                            // @ts-ignore
                            ...new Set([...localOpenedOptions, field]),
                          )
                          setFilterPanelVisible(true)
                        }
                  }
                  updateLocalFilterValues={setFilters}
                  infoOnly={disabled}
                />
              </div>
              {isSearchPanelVisible && (
                <SearchPanelWrapper>
                  <SearchPanel
                    filterValues={filterValues}
                    closePanel={() => setSearchPanelVisible(false)}
                    comparativeIndex={comparativeIndex}
                    updateLocalFilterValues={setFilters}
                  />
                </SearchPanelWrapper>
              )}
            </Left>
            <Right style={{ gap: isSmall ? 5 : 10 }}>
              {/* TODO? */}
              {/*{downloadSettings && (*/}
              {/*  <DownloadSelector*/}
              {/*    loading={downloadSettings.loading}*/}
              {/*    onPrint={downloadSettings.onPrint}*/}
              {/*  />*/}
              {/*)}*/}
              {reset}
              {settings && (
                <Popover
                  content={settings}
                  title={<div style={{ padding: '5px' }}>Controls</div>}
                  trigger="click"
                  placement="bottom"
                >
                  <Tooltip placement="top" title="Controls">
                    <Button
                      type="text"
                      style={{
                        border: `2px solid ${cardBorderGrey}`,
                        borderRadius: '4px',
                        boxShadow: 'none',
                        display: 'flex',
                        gap: isSmall ? '0px' : '5px',
                      }}
                    >
                      <TuneOutlined fontSize={'small'} />{' '}
                      {isSmall ? '' : <span>Controls</span>}
                    </Button>
                  </Tooltip>
                </Popover>
              )}
              {/*todo incredibly stupid render hack - fixes bugginess when switching from view to edit*/}
              {isEdit && (
                <div
                  onClick={() => setIsCurrent && setIsCurrent(!isCurrent)}
                  style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
                >
                  <Checkbox
                    checked={isCurrent}
                    style={{ marginRight: 5 }}
                    disabled={!defaultFilterList?.length || !dateWindows}
                  />
                  Most Recent
                </div>
              )}
              <DateSelectorWrapper className="date-selector">
                <DashboardDateSelect
                  key={renderHackIndex}
                  filterValues={filterValues}
                  updateLocalFilterValues={setFilters}
                  filterList={filterList}
                  isDashboard
                  disabled={disabled}
                  isCurrent={isEdit ? isCurrent : false}
                  setTimeResolution={setTimeResolution}
                  setTrailingDaysCount={setTrailingDaysCount}
                  isEdit
                />
              </DateSelectorWrapper>
              {!isPublicViewer && (
                <>
                  <Popover
                    content={download ? download : downloadContent}
                    title={
                      <div style={{ padding: '5px' }}>Export via Email</div>
                    }
                    trigger="click"
                    placement="bottom"
                  >
                    <Tooltip
                      placement="topRight"
                      title="Export Reviews by Email"
                    >
                      <Button
                        type="text"
                        icon={<ExportOutlined />}
                        style={{
                          border: `2px solid ${cardBorderGrey}`,
                          borderRadius: '4px',
                          boxShadow: 'none',
                        }}
                      />
                    </Tooltip>
                  </Popover>
                  <FilterPresetManagerWrapper>
                    <FilterPresetManager
                      comparativeIndex={comparativeIndex}
                      filterValues={filterValues}
                      filterList={filterList ?? []}
                    />
                  </FilterPresetManagerWrapper>
                </>
              )}
              {copyRef && (
                <>
                  <Tooltip
                    title={'Copy Dashboard as PNG to Clipboard'}
                    placement={'topLeft'}
                  >
                    <Button
                      type="text"
                      icon={<CopyOutlined />}
                      loading={isCopying}
                      onClick={async () => {
                        setIsCopying(true)
                        mixpanel.track('dashboard download', {
                          action: 'copy',
                          ...route,
                          value: dashboardId,
                        })

                        onCopyChart(
                          copyRef,
                          copyRef,
                          dashboardName,
                          false,
                        ).then(() => {
                          setIsCopying(false)
                        })
                      }}
                      style={{
                        border: `2px solid ${cardBorderGrey}`,
                        borderRadius: '4px',
                        boxShadow: 'none',
                        marginLeft: '10px',
                      }}
                    />
                  </Tooltip>
                  <Tooltip
                    title={'Download Dashboard as PNG'}
                    placement={'topLeft'}
                  >
                    <Button
                      type="text"
                      icon={<DownloadOutlined />}
                      loading={isPrinting}
                      onClick={() => {
                        setIsPrinting(true)
                        mixpanel.track('dashboard download', {
                          action: 'image',
                          ...route,
                          value: dashboardId,
                        })
                        onCopyChart(copyRef, copyRef, dashboardName, true).then(
                          () => {
                            setIsPrinting(false)
                          },
                        )
                      }}
                      style={{
                        border: `2px solid ${cardBorderGrey}`,
                        borderRadius: '4px',
                        boxShadow: 'none',
                        marginRight: '10px',
                      }}
                    />
                  </Tooltip>
                </>
              )}
            </Right>
          </Wrapper>
        </Container>
      </ResizeContainer>
      {isFilterPanelVisible && (
        <Draggable onStart={() => setDragged(true)} disabled={dragDisabled}>
          <FilterPanelWrapper
            ref={panelRef}
            iscomparative={false}
            dragged={dragged}
          >
            <FilterPanel
              onClose={() => {
                setFilterApplied(true)
                setFilterPanelVisible(false)
                setDragged(false)
              }}
              height={filterPanelHeight}
              dragged={dragged}
              setDragDisabled={setDragDisabled}
              comparativeIndex={comparativeIndex}
              filterValues={filterValues}
              filterListData={filterListData}
              updateLocalFilterValues={setFilters}
              localOpenedOptions={localOpenedOptions}
              setLocalOpenedOptions={setLocalOpenedOptions}
              localSelectedOption={selectedOption}
              setLocalSelectedOption={setSelectedOption}
            />
          </FilterPanelWrapper>
        </Draggable>
      )}
    </OuterContainer>
  )
}

type FilterWrapperProps = {
  params: {
    isComparative: boolean
    dragged: boolean
  }
}

const OuterContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 11;
  padding: 10px 10px 0px;
`

const ResizeContainer = styled.div`
  width: 100%;
  position: relative;
`

const Container = styled.div`
  z-index: 10;
  min-height: var(--filter-height);
  height: var(--filter-height);
  background: white;
  display: flex;
  align-items: center;
  padding: 0;
  width: calc(100% - 30px);
  border-radius: var(--border-radius);
  box-shadow: var(--light-box-shadow);
`

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 0px 10px;
`

const Left = styled.div`
  display: flex;
  gap: 10px;
  margin-right: 10px;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;

  .ant-badge-count {
    top: 4px;
  }
`
const Right = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  .ant-badge-count {
    right: 7px;
    top: 3px;
  }
`

const FilterPanelWrapper = styled.div<{
  iscomparative: boolean
  dragged: boolean
}>`
  overflow-y: hidden;
  background: #fff;
  position: absolute;
  left: 10px;
  top: 60px;
  z-index: 10;
  box-shadow: var(--header-box-shadow);

  width: calc(100vw - 240px);
  max-width: 800px;

  transform: ${(props) => (!props.dragged ? 'none !important' : 'inherit')};
`
const SearchPanelWrapper = styled.div`
  min-width: 420px;
  max-width: 500px;
  background: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  box-shadow: var(--header-box-shadow);
`
const DateSelectorWrapper = styled.div`
  margin-left: 4px;
`
const FilterPresetManagerWrapper = styled.div`
  margin-left: auto;
  margin-right: 0px;
`
const Header = styled.div`
  padding: var(--default-padding-half) 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`
const StyledInput = styled(Input)`
  width: 200px;
  margin-right: var(--default-padding-half);
`
