import React, { FC, memo, useEffect, useRef, useState } from 'react'

import styled from 'styled-components'
import { Layout, Responsive, WidthProvider } from 'react-grid-layout'
import { DraggableChartCards } from './components/DraggableChartCards'
import { Chart, ChartHandle } from './Charts/chart'
import { secondaryNavy } from '../../../../../../assets/styles/variables'
import { Close } from '@mui/icons-material'
import { YogiButton } from '../../../../../../components/UI/YogiButton'
import {
  initialState,
  useProjectStore,
} from '../../../../projectStore/projectStore'
import { Filters, ProjectState } from '../../../../types'
import useResizeObserver from 'use-resize-observer'
import { Header } from './header'
import { Tabs } from 'antd'
import { DashboardFilterHeader } from './components/DashboardFilterHeader'
import { useDashboardStore } from '../../dashboardStore'
import _ from 'lodash'
import { Widgets } from './components/Widgets'
import { saveDashboard, updateDashboard } from '../../model'
import { TemplateSelector } from './components/TemplateSelector'
import { exportAllImages, getInitialFilters } from '../../utils'
import { useQuery, useQueryClient } from 'react-query'
import { DisplayWidget } from './components/DisplayWidget'
import { getSummary } from '../../../charts/model'
import { DashboardResponse, DashboardSettings } from '../../types'
import { useHistory } from 'react-router'
import mixpanel from '../../../../../trackers/mixpanel'
import { TextWidget } from './components/TextWidget'
import { HardcodedFilterOptionsEnum } from '../../../filters/helpers'
import { toast } from 'react-toastify'

interface CardProps {
  selected?: boolean
  localSelected?: boolean
}
type Props = {
  filterValues: Filters
  setFilters: (filters: Filters) => void
  setView: (view: 'list' | 'view' | 'edit') => void
  uneditedDashboard: DashboardResponse | undefined
  selectedDashboard: DashboardResponse | undefined
  setSelectedDashboard: (
    selectedDashboard: DashboardResponse | undefined,
  ) => void
  setShowFetcher: (show: boolean) => void
  refetch: () => void
  dashboardControls?: any
}
const ResponsiveGridLayout = WidthProvider(Responsive)
export const DashboardEditor: FC<Props> = memo(function ContainerFn({
  filterValues,
  setFilters,
  uneditedDashboard,
  selectedDashboard,
  setView,
  setSelectedDashboard,
  setShowFetcher,
  refetch,
  dashboardControls: dashboardControlsProp,
}) {
  const dateWindows = useProjectStore((state) => state.dateWindows)
  const [items, setItems] = useState<any[]>([])
  const [itemMap, setItemMap] = useState<{ [key: string]: any }>({})
  const [shadowMap, setShadowMap] = useState<{ [key: string]: any }>({})
  const [counter, setCounter] = useState<number>(0)
  const [layoutObj, setLayoutObj] = useState<Record<string, Layout[]>>({
    layout: [],
  })
  const [curLayout, setCurLayout] = useState<Layout[]>([])
  // by putting layout in an obj we can mutate it without the grid re-rendering
  const { layout } = layoutObj

  const comparativeIndex = 0

  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList,
  )
  const draggedItem = useDashboardStore((state) => state.draggedItem)
  const setDraggedItem = useDashboardStore((state) => state.setDraggedItem)
  const setIsSmartDashboard = useDashboardStore(
    (state) => state.setIsSmartDashboard,
  )

  const history = useHistory()
  const dashBottomRef = useRef<HTMLDivElement>(null)
  const queryClient = useQueryClient()

  const rowHeight = 50

  const [dashboard, setDashboard] = useState<DashboardSettings | null>(null)
  const [dashboardName, setDashboardName] = useState<string>(
    selectedDashboard?.dashboard?.name ?? 'New Dashboard',
  )

  const [isCurrent, setIsCurrent] = useState<boolean>(
    selectedDashboard?.dashboard?.dashboard_settings.isCurrent ?? true,
  )
  useEffect(() => {
    setIsCurrent(
      selectedDashboard?.dashboard?.dashboard_settings.isCurrent ?? true,
    )
  }, [selectedDashboard?.dashboard?.dashboard_settings.isCurrent])
  const [timeResolution, setTimeResolution] = useState<string>(
    selectedDashboard?.dashboard?.dashboard_settings.timeResolution ??
      'Trailing',
  )
  const [trailingDaysCount, setTrailingDaysCount] = useState<
    number | undefined
  >(selectedDashboard?.dashboard?.dashboard_settings.trailingDaysCount)
  useEffect(() => {
    setTimeResolution(
      selectedDashboard?.dashboard?.dashboard_settings.timeResolution ??
        'Trailing',
    )
  }, [selectedDashboard?.dashboard?.dashboard_settings.timeResolution])
  useEffect(() => {
    setTrailingDaysCount(
      selectedDashboard?.dashboard?.dashboard_settings.trailingDaysCount,
    )
  }, [selectedDashboard?.dashboard?.dashboard_settings.trailingDaysCount])

  const [showResetDashboard, setShowResetDashboard] = useState<boolean>(false)
  const [showReset, setShowReset] = useState<boolean>(true)
  const [step, setStep] = useState<number>(1)
  const chartRefs = useRef<ChartHandle[]>([])
  const [chartRefsMap, setChartRefsMap] = useState<{ [key: string]: any }>({})
  const [hasLoadedFromLS, setHasLoadedFromLS] = useState<boolean>(false)

  const { ref, height } = useResizeObserver<HTMLDivElement>()

  useEffect(() => {
    // TODO we can extend this to save with a key of the dashboard id
    if (!selectedDashboard && hasLoadedFromLS) {
      const dashboardSettings = {
        layout: layoutObj.layout,
        items: itemMap,
        filters: filterValues,
      }
      saveToLS('new-dashboard-' + projectId, dashboardSettings)
    }
  }, [filterValues, hasLoadedFromLS])

  useEffect(() => {
    if (selectedDashboard?.dashboard) {
      layoutObj.layout = selectedDashboard.dashboard.dashboard_settings.layout
      setLayoutObj(JSON.parse(JSON.stringify(layoutObj)))
      setItems(selectedDashboard.dashboard.dashboard_settings.items)
      const tempMap =
        selectedDashboard.dashboard.dashboard_settings.items.reduce(function (
          map: any,
          obj: any,
          index: number,
        ) {
          map[index.toString()] = obj
          return map
        }, {})
      setItemMap(tempMap)
      setShadowMap(tempMap)
      setFilters(selectedDashboard.dashboard.dashboard_settings.filters)
      setCounter(selectedDashboard.dashboard.dashboard_settings.items.length)
      setStep(1)
    }
  }, [selectedDashboard?.dashboard.dashboard_settings])

  useEffect(() => {
    if (!uneditedDashboard || !curLayout.length) return
    const trimmedCurLayout: Layout[] = JSON.parse(JSON.stringify(curLayout))
    trimmedCurLayout.forEach((l: Layout) => {
      for (const property in l) {
        // @ts-ignore
        if (!trimmedCurLayout[property]) {
          // @ts-ignore
          delete trimmedCurLayout[property]
        }
      }
    })

    if (
      !_.isEqual(
        uneditedDashboard.dashboard.dashboard_settings.layout,
        trimmedCurLayout,
      )
    ) {
      setShowResetDashboard(true)
    } else {
      setShowResetDashboard(false)
    }
  }, [selectedDashboard, curLayout])

  useEffect(() => {
    if (dashboard && !_.isEqual(dashboard.filters, filterValues)) {
      setShowResetDashboard(true)
    }
  }, [filterValues])

  useEffect(() => {
    if (dashboard && !_.isEqual(dashboard.isCurrent, isCurrent)) {
      setShowResetDashboard(true)
    }
  }, [isCurrent])

  useEffect(() => {
    if (selectedDashboard) {
      setDashboard(selectedDashboard.dashboard.dashboard_settings)
    } else {
      setDashboard(null)
    }
  }, [selectedDashboard?.dashboard])

  // Preload summary/widgets
  const summaryRequest = {
    headers: {
      proj_uuid: projectId,
    },
    body: {
      chart_id: '5_5_7_rating_sentiment',
      tier1: 'Brand',
      tier1_value: '',
      tier2_value: null,
      tier3_value: null,
      search_terms: filterValues.searchQuery,
      search_criteria: filterValues.searchCondition,
      criteria: filterValues.values,
    },
  }

  const addToRefs = (el: ChartHandle) => {
    if (el && !chartRefs.current.includes(el)) {
      chartRefs.current.push(el)
      chartRefsMap[el.index] = el
      setChartRefsMap(chartRefsMap)
    }
  }

  useEffect(() => {
    setItemMap(shadowMap)
  }, [layout])

  const addChart = (item: any) => {
    mixpanel.track('custom dashboard', {
      action: 'add component',
      type: 'chart',
      value: item,
      proj_uuid: projectId,
    })
    const newItem = JSON.parse(JSON.stringify(item))
    newItem.dashboardControls = dashboardControls
    newItem.dashboardControls.chartId = item.content.chart_ids[0].chart_id
    newItem.dashboardControls.tier1 = item.content.tier1
    newItem.filterValues = filterValues
    itemMap[`${counter}`] = newItem
    setItemMap(itemMap)
    shadowMap[`${counter}`] = newItem
    setShadowMap(shadowMap)
    setItems(Array.from(Object.values(itemMap)))
  }

  const updateItem = (
    index: string,
    filterValues: Filters | null,
    dashboardControls: any,
    title?: string,
  ) => {
    // mixpanel.track('custom dashboard', {
    //   action: 'update item',
    //   value: selectedDashboard?.dashboard?.id,
    //   type: 'edit',
    //   proj_uuid: projectId,
    // })
    if (!itemMap[`${index}`]) return
    const item = JSON.parse(JSON.stringify(itemMap[`${index}`]))
    if (filterValues) {
      item.filterValues = filterValues
    }
    if (dashboardControls) {
      item.dashboardControls = dashboardControls
    }
    if (title !== undefined) {
      item.title = title
    }
    itemMap[`${index}`] = item
    shadowMap[`${index}`] = item
    setItemMap(itemMap)
    setShadowMap(shadowMap)
  }

  const addWidget = (item: any) => {
    mixpanel.track('custom dashboard', {
      action: 'add component',
      type: 'widget',
      value: item,
      proj_uuid: projectId,
    })
    delete item.content
    const newItem = JSON.parse(JSON.stringify(item))
    newItem.filterValues = filterValues
    itemMap[`${counter}`] = newItem
    setItemMap(itemMap)
    setItems(Array.from(Object.values(itemMap)))
  }

  const removeItem = (item: any, index: string) => {
    mixpanel.track('custom dashboard', {
      action: 'remove component',
      value: item,
      proj_uuid: projectId,
    })
    const updatedLayout = layout.filter((l) => l.i !== index)
    layoutObj.layout = updatedLayout
    delete itemMap[index]
    delete chartRefsMap[index]
    setChartRefsMap(chartRefsMap)
    setItemMap(itemMap)
    setItems(Array.from(Object.values(itemMap)))
    setLayoutObj(layoutObj)
  }

  const dashboardControls = JSON.parse(
    JSON.stringify(initialState.dashboardControls[0]),
  )

  const handleDrop = (e: any, layoutItem: Layout) => {
    // unshift allows the new item to be on top
    layoutObj.layout.unshift(layoutItem)
    setLayoutObj(layoutObj)
    setCounter(counter + 1)
    if (!draggedItem) return
    if (draggedItem.type === 'chart') {
      addChart(draggedItem)
    } else {
      addWidget(draggedItem)
    }
    setDraggedItem(null)
  }

  useEffect(() => {
    if (!selectedDashboard) {
      const newDashboard = getFromLS('new-dashboard-' + projectId)
      setHasLoadedFromLS(true)
      if (!newDashboard) return
      setCurLayout(newDashboard.layout)
      setLayoutObj({ layout: newDashboard.layout })
      setItemMap(newDashboard.items)
      setItems(Object.values(newDashboard.items))
      setShadowMap(newDashboard.items)
      setFilters(newDashboard.filters)
      setShowResetDashboard(true)
    }
  }, [selectedDashboard])

  //@ts-ignore
  function getFromLS(key) {
    let ls = {}
    if (global.localStorage) {
      try {
        //@ts-ignore
        ls = JSON.parse(global.localStorage.getItem('rgl-8')) || {}
      } catch (e) {
        /*Ignore*/
      }
    }
    //@ts-ignore
    return ls[key]
  }

  //@ts-ignore
  function saveToLS(key, value) {
    if (global.localStorage) {
      global.localStorage.setItem(
        'rgl-8',
        JSON.stringify({
          [key]: value,
        }),
      )
    }
  }

  function onLayoutChange(newLayout: any) {
    // TODO we can extend this to save with a key of the dashboard id for editing dashes, too
    if (!selectedDashboard && hasLoadedFromLS) {
      const dashboardSettings = {
        layout: newLayout,
        items: itemMap,
        filters: filterValues,
      }
      saveToLS('new-dashboard-' + projectId, dashboardSettings)
    }

    setCurLayout(newLayout)
    if (newLayout.length === layout.length && !_.isEqual(newLayout, layout)) {
      layoutObj.layout = newLayout
      setLayoutObj(layoutObj)
    }
  }

  // theres gotta be a better way to do this.
  const duplicateItemUpdateLayout = (
    item: any,
    oldLayout: Layout,
    filters: Filters,
    dashboardControls?: any,
  ) => {
    mixpanel.track('custom dashboard', {
      action: 'button resize component',
      value: oldLayout.w > 6 ? 'half' : 'full',
      proj_uuid: projectId,
    })
    const newLayout = JSON.parse(
      JSON.stringify(layoutObj.layout.find((l) => l.i === oldLayout.i)),
    )
    const layout = layoutObj.layout.find((l) => l.i === oldLayout.i)
    if (!layout) return
    // const isNewRow = layout.x + layout.w + layout.w > 12
    // newLayout.y = isNewRow ? layout.y + layout.h : layout.y
    // newLayout.x = isNewRow ? 0 : layout.x + layout.w
    newLayout.i = counter.toString()
    newLayout.w = oldLayout.w
    // TODO this adds in an item at the right dimensions, now try to add in place
    const index = layoutObj.layout.findIndex((l) => l.i === oldLayout.i)
    layoutObj.layout.splice(index, 1, newLayout)
    setLayoutObj(layoutObj)
    setCounter(counter + 1)
    setDraggedItem(null)

    const newItem = JSON.parse(JSON.stringify(item))
    newItem.filterValues = filters
    if (dashboardControls) {
      newItem.dashboardControls = dashboardControls
      newItem.dashboardControls.chartId = item.content.chart_ids[0].chart_id
      newItem.dashboardControls.tier1 = item.content.tier1
    }
    itemMap[`${counter}`] = newItem
    delete itemMap[`${oldLayout.i}`]
    setItemMap(itemMap)
    shadowMap[`${counter}`] = newItem
    delete shadowMap[`${oldLayout.i}`]
    setShadowMap(shadowMap)
    delete chartRefsMap[`${oldLayout.i}`]
    setChartRefsMap(chartRefsMap)
    setItems(Array.from(Object.values(itemMap)))
  }

  const handleSaveDashboard = async () => {
    mixpanel.track('custom dashboard', {
      action: 'save',
      value: selectedDashboard?.dashboard.id,
    })
    setIsSmartDashboard(false)
    setShowFetcher(true)

    const orderedLayout = _.cloneDeep(curLayout).sort((a, b) => {
      if (a.y !== b.y) {
        return a.y - b.y
      } else {
        return a.x - b.x
      }
    })

    const orderedItems = orderedLayout.map((l) => itemMap[l.i])
    orderedLayout.forEach((l, index) => (l.i = index.toString()))

    const dashboardSettings = {
      layout: orderedLayout,
      items: orderedItems,
      filters: filterValues,
      isCurrent: isCurrent,
      timeResolution: timeResolution,
      trailingDaysCount: trailingDaysCount,
    }
    // if the dash is to be kept current, we don't want to commit the time filter to the dash
    if (isCurrent) {
      dashboardSettings.filters.values =
        dashboardSettings.filters.values.filter(
          (filter) => filter.field !== HardcodedFilterOptionsEnum.CREATE_TIME,
        )
    }

    const dashboardCopy = _.cloneDeep(selectedDashboard)

    // this prevents the layout from restructuring while the async call is out. why does it restructure after updating the fetch state? i dont know.
    const updatedDashboard: DashboardResponse =
      dashboardCopy ??
      ({
        dashboard: {},
        owners: [],
        viewers: [],
      } as unknown as DashboardResponse)
    updatedDashboard.dashboard.name = dashboardName
    updatedDashboard.dashboard.dashboard_settings = dashboardSettings
    // setSelectedDashboard(updatedDashboard)

    let upsertedDashboard: DashboardResponse

    if (selectedDashboard?.dashboard.id) {
      upsertedDashboard = await updateDashboard(projectId, {
        dashboard_id: selectedDashboard.dashboard.id,
        name: dashboardName,
        dashboard_settings: dashboardSettings,
      })
      if (upsertedDashboard) {
        queryClient.setQueryData(
          ['custom-dashboards', projectId],
          (oldData: any) => {
            oldData = oldData.filter((dashboard: DashboardResponse) => {
              return dashboard.dashboard.id !== upsertedDashboard.dashboard.id
            })
            return [...oldData, upsertedDashboard]
          }
        )
        setSelectedDashboard(upsertedDashboard)
        setView('view')
      } else {
        toast.error('Error updating dashboard. Please try again.')
      }
      setShowFetcher(false)
    } else {
      upsertedDashboard = await saveDashboard(projectId, {
        name: dashboardName,
        dashboard_settings: dashboardSettings,
        is_shared: false,
      })
      saveToLS('new-dashboard-' + projectId, undefined)
      // await refetch()

      if (upsertedDashboard) {
        queryClient.setQueryData(
          ['custom-dashboards', projectId],
          (oldData: any) => {
            oldData = oldData.filter((dashboard: DashboardResponse) => {
              return dashboard.dashboard.id !== upsertedDashboard.dashboard.id
            })
            return [...oldData, upsertedDashboard]
          }
        )
        history.replace(
          `${window.location.pathname}?dashboard_id=${upsertedDashboard.dashboard.id}`,
        )
        setView('view')
      } else {
        toast.error('Error saving dashboard. Please try again.')
      }
      setShowFetcher(false)
    }
  }

  const handleViewDashboard = async () => {
    // TODO something bout this feels stupid
    const orderedLayout = curLayout.sort((a, b) => {
      if (a.y !== b.y) {
        return a.y - b.y
      } else {
        return a.x - b.x
      }
    })
    const orderedItems = orderedLayout.map((l) => itemMap[l.i])
    orderedLayout.forEach((l, index) => (l.i = index.toString()))
    const dashboardSettings = {
      layout: orderedLayout,
      items: orderedItems,
      filters: filterValues,
    }
    const updatedDashboard: DashboardResponse = selectedDashboard
      ? { ...selectedDashboard }
      : ({
          dashboard: {},
          owners: [],
          viewers: [],
        } as unknown as DashboardResponse)
    updatedDashboard.dashboard.name = dashboardName
    updatedDashboard.dashboard.dashboard_settings = dashboardSettings
    setSelectedDashboard(updatedDashboard)
    setView('view')
  }

  const duplicateItem = (
    item: any,
    oldLayout: Layout,
    filters: Filters,
    dashboardControls?: any,
  ) => {
    mixpanel.track('custom dashboard', {
      action: 'duplicate component',
      value: item,
      proj_uuid: projectId,
    })
    const newLayout = JSON.parse(
      JSON.stringify(layoutObj.layout.find((l) => l.i === oldLayout.i)),
    )
    const layout = layoutObj.layout.find((l) => l.i === oldLayout.i)
    if (!layout) return
    const isNewRow = layout.x + layout.w + layout.w > 12
    newLayout.y = isNewRow ? layout.y + layout.h : layout.y
    newLayout.x = isNewRow ? 0 : layout.x + layout.w
    newLayout.i = counter.toString()
    layoutObj.layout.unshift(newLayout)
    setLayoutObj(layoutObj)
    setCounter(counter + 1)
    setDraggedItem(null)

    const newItem = JSON.parse(JSON.stringify(item))
    newItem.filterValues = filters
    if (dashboardControls) {
      newItem.dashboardControls = dashboardControls
      newItem.dashboardControls.chartId = item.content.chart_ids[0].chart_id
      newItem.dashboardControls.tier1 = item.content.tier1
    }
    itemMap[`${counter}`] = newItem
    setItemMap(itemMap)
    shadowMap[`${counter}`] = newItem
    setShadowMap(shadowMap)
    setItems(Array.from(Object.values(itemMap)))
  }

  const selectTemplate = (template: any) => {
    if (!template) {
      setStep(1)
      return
    }
    layoutObj.layout = template.layout
    setLayoutObj({ layout: template.layout })
    setItems(template.items)
    setItemMap(template.itemMap)
    setShadowMap(template.itemMap)
    setFilters(template.filters)
    setCounter(template.items.length)
    setStep(1)
  }
  const [isExporting, setIsExporting] = useState<boolean>(false)

  useEffect(() => {
    if (isExporting) {
      exportAllImages(
        // @ts-ignore
        layout,
        chartRefsMap,
        dashboard,
        projectId,
        filterValues,
        defaultFilterList,
        dashboardName,
        new Date().toLocaleDateString(),
        setIsExporting,
      )
    }
  }, [isExporting])

  const onAddItem = (item: any, layoutItem: Layout) => {
    // unshift allows the new item to be on top
    const newLayoutItem = {
      ...layoutItem,
      i: counter.toString(),
      x: 0,
      y: !layout.length ? 0 : Infinity,
    }
    if (layoutItem.w === 6 && !!layoutObj.layout.length) {
      const lastItem = layoutObj.layout.sort((a, b) => b.y - a.y)[0]
      if (lastItem.x === 0 && lastItem.w === 6) {
        newLayoutItem.y = lastItem.y
        newLayoutItem.x = 6
      }
    }
    layoutObj.layout.unshift(newLayoutItem)
    setLayoutObj(layoutObj)
    setCounter(counter + 1)
    // setDraggedItem(null)

    if (item.type === 'chart') {
      addChart(item)
    } else {
      addWidget(item)
    }
    setTimeout(() => {
      if (layoutObj.layout.length > 1) {
        scrollToBottom()
      }
    }, 100)
  }

  const scrollToBottom = () => {
    if (dashBottomRef?.current) {
      dashBottomRef.current.scrollTop = dashBottomRef.current?.scrollHeight
    }
  }

  const tabItems = [
    {
      key: '1',
      label: <div style={{ padding: '0px 10px' }}>Templates</div>,
      children: (
        <TemplateSelector selectTemplate={selectTemplate} items={items} />
      ),
    },
    {
      key: '2',
      label: <div style={{ padding: '0px 10px' }}>Saved Views</div>,
      children: (
        <TemplateSelector
          selectTemplate={selectTemplate}
          isSavedViews
          items={items}
        />
      ),
    },
  ]

  const tabItems2 = [
    {
      key: '1',
      label: <div style={{ padding: '0px 10px' }}>Charts</div>,
      children: (
        <DraggableChartCards
          items={items}
          addItem={addChart}
          setShowReset={setShowReset}
          onAddItem={onAddItem}
        />
      ),
    },
    {
      key: '2',
      label: <div style={{ padding: '0px 10px' }}>Widgets</div>,
      children: (
        <Widgets
          filters={filterValues}
          filterListData={defaultFilterList}
          onAddItem={onAddItem}
        />
      ),
    },
  ]

  return (
    <Container>
      <Header
        viewDashboard={handleViewDashboard}
        saveDashboard={handleSaveDashboard}
        exportDashboard={() => {
          mixpanel.track('custom dashboard', {
            action: 'export',
            value: selectedDashboard?.dashboard.id,
          })
          setIsExporting(true)
        }}
        showResetDashboard={showResetDashboard}
        setDashboardName={setDashboardName}
        dashboardName={dashboardName}
        resetDashboard={() => {
          if (!uneditedDashboard) {
            setLayoutObj({ layout: [] })
            setCurLayout([])
            setItems([])
            setChartRefsMap({})
            setItemMap({})
            setShadowMap({})
            setFilters(getInitialFilters(dateWindows))
            setIsCurrent(true)
            setTimeResolution('Trailing')
            setTrailingDaysCount(30)
          } else {
            setSelectedDashboard(JSON.parse(JSON.stringify(uneditedDashboard)))
            setFilters(
              JSON.parse(
                JSON.stringify(
                  uneditedDashboard.dashboard.dashboard_settings.filters,
                ),
              ),
            )
            setIsCurrent(
              uneditedDashboard.dashboard.dashboard_settings.isCurrent ?? true,
            )
            setTimeResolution(
              uneditedDashboard.dashboard.dashboard_settings.timeResolution ??
                'Trailing',
            )
            setTrailingDaysCount(
              uneditedDashboard.dashboard.dashboard_settings
                .trailingDaysCount ?? 30,
            )
          }
        }}
        setView={setView}
        isEdit
        dashboard={selectedDashboard}
        isExporting={isExporting}
      />
      <Wrapper headerHeight={height}>
        <DragContainer style={{ width: 240 }}>
          {step === 0 && (
            <StyledTabs style={{ width: '100%' }} items={tabItems} />
          )}
          {step === 1 && (
            <TabContainer>
              <StyledTabs style={{ width: '100%' }} items={tabItems2} />
            </TabContainer>
          )}
        </DragContainer>
        <LayoutWrapper
          style={{
            height: 'calc(100% - 65px)',
            position: 'relative',
            overflowX: 'hidden',
            clear: 'both',
          }}
          ref={dashBottomRef}
        >
          {step > 0 && (
            <DashboardFilterHeader
              filterValues={filterValues}
              setFilters={setFilters}
              comparativeIndex={0}
              isNew={!selectedDashboard}
              isEdit
              isCurrent={isCurrent}
              setIsCurrent={setIsCurrent}
              timeResolution={timeResolution}
              setTimeResolution={setTimeResolution}
              trailingDaysCount={trailingDaysCount}
              setTrailingDaysCount={setTrailingDaysCount}
            />
          )}
          <div style={{ width: '100%', minHeight: '100%' }}>
            {!layout.length && (
              <Dropzone>
                <DropzoneText>
                  Drag & drop components here to get started
                </DropzoneText>
              </Dropzone>
            )}
            <StyledResponsiveGridLayout
              style={{
                width: '100%',
                minHeight: '100%',
              }}
              className="layout"
              breakpoints={{ lg: 1200 }} // md: 996, sm: 768, xs: 480, xxs: 0 }}
              cols={{ lg: 12 }} // md: 10, sm: 6, xs: 4, xxs: 2 }}
              rowHeight={rowHeight}
              draggableHandle={'.drag-handle'}
              draggableCancel={'.drag-cancel'}
              onLayoutChange={onLayoutChange}
              droppingItem={
                draggedItem?.type === 'widget'
                  ? {
                      i: counter.toString(),
                      ...draggedItem.layout,
                    }
                  : {
                      i: counter.toString(),
                      w: 12,
                      h: 9,
                      // @ts-ignore
                      minW: 4,
                      // @ts-ignore
                      minH: 4,
                    }
              }
              useCSSTransforms={!!draggedItem}
              isDroppable={true}
              onDrop={handleDrop}
              preventCollision={false}
              compactType={'vertical'}
            >
              {layout
                .filter((el) => !!el)
                .map((layoutItem, index) => {
                  if (!layoutItem) return <div />
                  const item = itemMap[layoutItem.i]
                  const mapIndex = layoutItem.i
                  if (!item) return <div />
                  if (item.type === 'chart') {
                    const chart = item.content
                    return (
                      <ChartCard key={layoutItem.i} data-grid={layoutItem}>
                        <div
                          style={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          {item.dashboardControls && (
                            <Chart
                              ref={addToRefs}
                              filters={filterValues}
                              chartId={
                                item.dashboardControls.chartId ??
                                chart.chart_ids[0].chart_id
                              }
                              comparativeIndex={comparativeIndex}
                              isComparative={false}
                              dashboardControls={item.dashboardControls}
                              tier1={
                                item.dashboardControls.tier1 ?? chart.tier1
                              }
                              isEditing={true}
                              item={item}
                              index={mapIndex}
                              updateItem={updateItem}
                              removeItem={() => removeItem(chart, layoutItem.i)}
                              duplicateItem={duplicateItem}
                              layout={layoutItem}
                              updateItemLayout={duplicateItemUpdateLayout}
                            />
                          )}
                        </div>
                      </ChartCard>
                    )
                  } else if (item.type === 'widget') {
                    return (
                      <div
                        key={layoutItem.i}
                        data-grid={layoutItem}
                        className={'widget'}
                        style={{ background: 'white', position: 'relative' }}
                      >
                        <div
                          style={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            cursor: 'move',
                            overflow: 'auto',
                          }}
                          className={'drag-handle'}
                        >
                          <DisplayWidget
                            ref={addToRefs}
                            name={item.name}
                            filters={filterValues}
                            item={item}
                            index={mapIndex}
                            updateItem={updateItem}
                            isEditing={true}
                            removeItem={() => removeItem(item, layoutItem.i)}
                            duplicateItem={duplicateItem}
                            layout={layoutItem}
                            dashboardControls={item.dashboardControls}
                            updateItemLayout={duplicateItemUpdateLayout}
                          />
                        </div>
                      </div>
                    )
                  } else {
                    return (
                      <div key={layoutItem.i} data-grid={layoutItem}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            position: 'absolute',
                            zIndex: 10,
                            right: 5,
                            top: 5,
                          }}
                        >
                          <YogiButton
                            type="dashed"
                            onClick={() => removeItem(item, layoutItem.i)}
                          >
                            <Close />
                          </YogiButton>
                        </div>
                        <div
                          style={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            cursor: 'move',
                          }}
                          className={'drag-handle'}
                        >
                          <TextWidget item={item} index={mapIndex} />
                        </div>
                      </div>
                    )
                  }
                })}
              <div ref={dashBottomRef}></div>
            </StyledResponsiveGridLayout>
          </div>
        </LayoutWrapper>
      </Wrapper>
    </Container>
  )
})

const Container = styled.div`
  position: relative;
  justify-content: flex-start;
  flex-direction: row;
  max-height: calc(100vh - 40px);
  height: 100%;
  width: 100%;
`
const Wrapper = styled.div<{ headerHeight?: number }>`
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  height: 100%;
  width: 100%;
  max-height: ${(props) =>
    props.headerHeight
      ? `calc(100vh - 40px - ${props.headerHeight}px)`
      : '100%'};
`

const DragContainer = styled.div`
  background: #fff;
  flex-grow: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  gap: 20px;
  overflow: auto;
  border-right: 2px solid rgba(159, 183, 195, 0.15);
  box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.1);
`

const LayoutWrapper = styled.div`
  flex-grow: 3;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`

const ChartCard = styled.div<{ selected?: boolean }>`
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: white;
  border: none;
  cursor: pointer;
  z-index: 5;

  ${({ selected }: CardProps) =>
    selected && 'background-color: ' + secondaryNavy + ' !important;'}
`

const TabContainer = styled.div`
  display: flex;
  height: 95%;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`

const StyledTabs = styled(Tabs)`
  .ant-tabs-content-holder {
    overflow: auto;
  }
`

const StyledResponsiveGridLayout = styled(ResponsiveGridLayout)`
  .react-grid-item:hover {
    z-index: 10;
  }
`

const Dropzone = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 530px;
  flex-direction: column;
  gap: 20px;
  width: calc(100% - 20px);
  position: absolute;
  border: 8px dashed #9fb7c3;
  border-radius: 20px;
  margin: 10px;
`
const DropzoneText = styled.h2`
  color: #92b1be;
  text-align: center;
  margin: 0 10px;
`
