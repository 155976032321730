import React, { useEffect, useState } from 'react'
import { AlertCardProps } from '../model'
import styled from 'styled-components'
import { Switch, Tooltip } from 'antd'
import {
  secondaryGreen,
  secondaryRed,
  starYellow,
} from '../../../../../assets/styles/variables'
import { HeartOutlined } from '@ant-design/icons'
import {
  ChatOutlined,
  GroupsOutlined,
  StarOutlineOutlined,
} from '@mui/icons-material'
import { Grid } from '@mui/material'
import { YogiButton } from '../../../../../components/UI/YogiButton'
import { useAuthContext } from '../../../../auth'
import { Link, useHistory } from 'react-router-dom'
import { CHARTS_URL, DASHBOARD_URL } from '../../../utils'
import { useProjectStore } from '../../../projectStore/projectStore'
import { ProjectState } from '../../../types'
import mixpanel from '../../../../trackers/mixpanel'
import _ from 'lodash'
// const pluralize = require('pluralize')

const iconMap: any = {
  'Star Rating': <StarOutlineOutlined style={{ color: starYellow }} />,
  Sentiment: <HeartOutlined style={{ color: secondaryGreen, fontSize: 18 }} />,
  Volume: <ChatOutlined fontSize={'medium'} style={{ padding: '1px' }} />,
}

const AlertCard: React.FC<AlertCardProps> = ({
  alertData,
  toggleActive,
  onSelect,
  actions,
  listType,
  notifications,
  markAsViewed,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  const authContext = useAuthContext()
  const history = useHistory()

  const { alert, is_enabled, owners, viewers } = alertData
  const { id, title, status, created_dt, alert_type, url } = alert
  const [active, setActive] = useState(is_enabled)

  useEffect(() => {
    setActive(is_enabled)
  }, [alertData])

  // todo maybe pull these out into the list
  const notification = _.orderBy(notifications, ['created_at'], ['desc'])?.find(
    (el) => !el.acknowledged_at && el.model_id === alert.id
  )

  const isActive = !!notification

  return (
    <AlertContainer
      isActive={isActive}
      onClick={() => {
        mixpanel.track('alerts', {
          action: 'open',
          value: alert?.id,
        })
        onSelect(alert.id)
        notification &&
          notification.notification_type === 'ALERT_INVITED' &&
          markAsViewed(notification.id)
      }}
    >
      {listType === 'list' ? (
        <Grid container spacing={1}>
          <GridItem
            item
            xs={4}
            style={{ justifyContent: 'flex-start', textAlign: 'left', gap: 20 }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 20,
                width: 20,
              }}
            >
              {iconMap[alert_type]}
            </div>
            <h3 style={{ margin: 0 }}>{title}</h3>
          </GridItem>
          <GridItem item xs={1} style={{ alignItems: 'center' }}>
            {status}
          </GridItem>
          <GridItem item xs={1} style={{ alignItems: 'center' }}>
            N/A
          </GridItem>
          <GridItem item xs={1}>
            {viewers.map((el, index) => (
              <>
                {index < 2 ? (
                  <Tooltip title={el.email}>
                    <AvatarWrapper>
                      {el.full_name.split(' ')[0][0]}
                      {el.full_name.split(' ')[1][0]}
                    </AvatarWrapper>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={viewers.slice(2).map((user) => (
                      <div>{user.email}</div>
                    ))}
                  >
                    <AvatarWrapper>{viewers.length - 2}+</AvatarWrapper>
                  </Tooltip>
                )}
              </>
            ))}
          </GridItem>
          <GridItem item xs={1}>
            {alert.last_sent_dt
              ? new Date(alert.last_sent_dt).toLocaleDateString()
              : 'N/A'}
          </GridItem>
          <GridItem item xs={1}>
            {alert.created_by.full_name}
          </GridItem>
          <GridItem item xs={2}>
            {alert.url && (
              <StyledLink
                to={`/project/${projectId}/${CHARTS_URL}${alert.url}`}
                onClick={(e) => {
                  e.preventDefault()
                  mixpanel.track('alerts', {
                    action: 'view in charts',
                    value: alert.id,
                    proj_uuid: projectId,
                  })
                  if (notification && !notification.acknowledged_at) {
                    markAsViewed(notification.id)
                  } else {
                    // markUnread(projectId, notification.id)
                    //   .then(() => refetch())
                    //   .catch((err) => {
                    //     console.log(err)
                    //     toast.error('Error marking notification as read')
                    //   })
                  }
                  history.push(
                    `/project/${projectId}/${CHARTS_URL}${alert.url || ''}`
                  )
                }}
              >
                <YogiButton type={!!notification ? 'primary' : 'default'}>
                  View in Charts
                </YogiButton>
              </StyledLink>
            )}
          </GridItem>
          <GridItem item xs={1} onClick={(e) => e.stopPropagation()}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: 10,
              }}
            >
              {status !== 'Draft' && (
                <StyledSwitch
                  checkedChildren=""
                  unCheckedChildren=""
                  checked={active}
                  onChange={() => {
                    setActive(!active)
                    toggleActive(id, active)
                  }}
                />
              )}
              {actions}
            </div>
          </GridItem>
        </Grid>
      ) : (
        <Card>
          <CardControls>
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              {status !== 'Draft' && (
                <StyledSwitch
                  checkedChildren=""
                  unCheckedChildren=""
                  checked={active}
                  onChange={() => {
                    setActive(!active)
                    toggleActive(id, active)
                  }}
                />
              )}
              {actions}
            </div>
          </CardControls>
          <CardContent>
            <CardInfo style={{ flexDirection: 'row', alignItems: 'center' }}>
              <h3 style={{ margin: 0 }}>{title}</h3>

              {alert.url && (
                <StyledLink
                  to={`/project/${projectId}/${DASHBOARD_URL}${alert.url}`}
                  onClick={(e) => {
                    e.preventDefault()
                    mixpanel.track('alerts', {
                      action: 'view in charts',
                      value: alert,
                      proj_uuid: projectId,
                    })
                    if (notification && !notification.acknowledged_at) {
                      markAsViewed(notification.id)
                    }
                    history.push(
                      `/project/${projectId}/${DASHBOARD_URL}${alert.url}`
                    )
                  }}
                >
                  <YogiButton type={!!notification ? 'primary' : 'default'}>
                    View in Charts
                  </YogiButton>
                </StyledLink>
              )}
            </CardInfo>

            <CardInfo>
              <CardTime>
                Created on {new Date(created_dt).toLocaleDateString()}
              </CardTime>
              <CardSharingInfo container spacing={2}>
                <Grid item xs={6}>
                  <Tooltip
                    title={viewers?.map((user) => (
                      <div>{user.email}</div>
                    ))}
                  >
                    <SharingInfoBox style={{ cursor: 'pointer' }}>
                      <GroupsOutlined fontSize={'small'} />
                      <div>
                        {viewers?.length ?? 0}{' '}
                        {pluralize('Recipients', viewers?.length ?? 0)}
                      </div>
                    </SharingInfoBox>
                  </Tooltip>
                </Grid>
                <Grid item xs={6}>
                  <SharingInfoBox>
                    {viewers
                      .map((el) => el.email)
                      ?.includes(authContext.user?.attributes?.email) ? (
                      <div>Shared with me</div>
                    ) : (
                      <div>Created by me</div>
                    )}
                  </SharingInfoBox>
                </Grid>
              </CardSharingInfo>
            </CardInfo>
          </CardContent>
        </Card>
      )}
    </AlertContainer>
  )
}

const AlertContainer = styled.div<{ isActive?: boolean }>`
  padding: var(--default-padding-half);
  background: ${(props) => (props.isActive ? '#FFF2F2' : '#fff')};
  box-shadow: var(--default-box-shadow);
  transition: transform ease 0.1s;
  transform: scale(1);
  position: relative;
  border: 1px solid
    ${(props) => (props.isActive ? secondaryRed : 'transparent')};
  border-radius: var(--border-radius);
  display: flex;
  justify-content: space-between;
  height: 100%;
  cursor: pointer;
`

const StyledSwitch = styled(Switch)`
  background-color: ${(props) =>
    props.checked ? secondaryGreen : 'rgba(0, 0, 0, 0.25)'};
`

const GridItem = styled(Grid)`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Card = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const CardControls = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
`

const CardContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
`
const CardInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
`
const CardTime = styled.div`
  font-size: 12px;
  color: #8a8c8f;
`
const CardSharingInfo = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const SharingInfoBox = styled.div`
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: rgba(159, 183, 195, 0.15);
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  //width: 100%;
  justify-content: space-between;
  &:hover {
    //text-decoration: none;
  }
`
export const AvatarWrapper = styled.div`
  min-width: 30px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  cursor: pointer;
  border: 2px solid white;
  box-sizing: content-box;
  background: #f5f8f9;
  color: #9fb7c3;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  transition: border 0.3s;
  line-height: 20px !important;
  margin-left: -15px;
  &:first-child {
    margin-left: 0;
  }
  &:hover {
    z-index: 10;
  }
`

export default AlertCard
