import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Chart as ChartJS, ChartData, ChartOptions } from 'chart.js'
import { numberFormatter } from 'utils/numberFormat'
import type { KeywordsBarChart } from '../types'
import { PostsEmpty } from 'components/PostsEmpty'

type Props = {
  data: KeywordsBarChart
  onPhraseSelect: (c: string) => void
  animationDisabled?: boolean
  sortBySentiment?: boolean
  chartRef: React.RefObject<HTMLCanvasElement>
}

export const Chart: React.FC<Props> = ({
  data,
  onPhraseSelect,
  animationDisabled,
  sortBySentiment,
  chartRef,
}) => {
  // const chartRef = useRef<HTMLCanvasElement>(null)
  const chartInstanceRef = useRef<ChartJS | null>(null)

  const prepareChartData = () => {
    const sortedValues = [...data.values]
    if (sortBySentiment) {
      sortedValues.sort(
        (a, b) =>
          (b.hover['Avg Sentiment'] as number) -
          (a.hover['Avg Sentiment'] as number),
      )
    }

    const labels = sortedValues.map((value) =>
      value.category.length > 30
        ? value.category.substr(0, 30) + '...'
        : value.category,
    )
    const colors = sortedValues.map((value) => value.color)
    const values = sortedValues.map((value) => value.volume)

    return { labels, colors, values, sortedValues }
  }

  const createOrUpdateChart = () => {
    if (!chartRef.current) return

    const { labels, colors, values, sortedValues } = prepareChartData()

    const chartData: ChartData = {
      labels: labels,
      datasets: [
        {
          label: data.x_key,
          data: values,
          backgroundColor: colors,
          borderRadius: 10,
          maxBarThickness: 40,
        },
      ],
    }

    const chartOptions: ChartOptions = {
      indexAxis: 'y',
      scales: {
        x: {
          position: 'top',
          title: {
            display: true,
            text: 'Frequency',
          },
        },
      },
      plugins: {
        datalabels: {
          formatter: (value: number) => {
            return numberFormatter(value)
          },
          color: 'black',
          anchor: 'end',
          align: 'right',
        },
        tooltip: {
          callbacks: {
            label: () => '',
            afterBody: function (t: any) {
              const index = t[0].dataIndex
              if (index === undefined) return ''
              const currentItem = sortedValues[index]

              return Object.keys(currentItem.hover)
                .map(
                  (key) => `${key}: ${numberFormatter(currentItem.hover[key])}`,
                )
                .join('\n')
            },
          },
        },
      },
      animation: {
        duration: animationDisabled ? 0 : 1000,
      },
      layout: {
        padding: {
          right: 60,
        },
      },
      maintainAspectRatio: false,
      responsive: true,
      onClick: (event, elements) => {
        if (elements && elements[0]) {
          const index = elements[0].index
          const currentElement = labels[index]
          onPhraseSelect(currentElement || '')
        }
      },
      onHover: (event, elements) => {
        const target = event.native?.target as HTMLElement
        target.style.cursor =
          elements && elements.length > 0 ? 'pointer' : 'default'
      },
    }

    if (chartInstanceRef.current) {
      chartInstanceRef.current.data = chartData
      chartInstanceRef.current.options = chartOptions
      chartInstanceRef.current.update()
    } else {
      chartInstanceRef.current = new ChartJS(chartRef.current, {
        type: 'bar',
        options: chartOptions,
        data: chartData,
      })
    }
  }

  useEffect(() => {
    createOrUpdateChart()

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy()
        chartInstanceRef.current = null
      }
    }
  }, [sortBySentiment, data])

  if (!data.values.length) {
    return <PostsEmpty>No matches found.</PostsEmpty>
  }

  return (
    <Container>
      <Wrapper>
        <canvas
          id="keywords-chart"
          ref={chartRef}
          style={{
            paddingLeft: 10,
            width: '100%',
            minHeight: data.values.length * 50 + 50,
          }}
        />
      </Wrapper>
    </Container>
  )
}

const Container = styled.div`
  background: white;
  box-shadow: var(--default-box-shadow);
  border-radius: var(--border-radius);
  padding: 15px 0;
`

const Wrapper = styled.div``
