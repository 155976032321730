import React from 'react'
import styled from 'styled-components'
import { Popover } from 'antd'
import { Tag, Tooltip } from 'antd'
import {
  AppliedFilterOption,
  AppliedFilterOptionSelect,
  FilterOptionSelect,
} from '../../types'
import { getBadgeLabels, HardcodedFilterOptionsEnum } from '../../helpers'
import './../../styles.css'
import { useProjectStore } from '../../../../projectStore/projectStore'
import { Filters, ProjectState } from '../../../../types'
import filterIcon from 'assets/svg/FilterIcon.svg'
import _ from 'lodash'

// const pluralize = require('pluralize')

const colors = [
  'magenta',
  'red',
  'volcano',
  'orange',
  'gold',
  'lime',
  'green',
  'cyan',
  'blue',
  'geekblue',
  'purple',
]

const blockedValues = ['Fans / Attractors', 'Critics / Detractors']

type Props = {
  filterValues: Filters
  comparativeIndex: number
  countThreshold?: number
  infoOnly?: boolean
  savedFilter?: boolean
  openFilterPanel?: (field: string) => void
  small?: boolean
  updateLocalFilterValues?: (filterValues: Filters) => void
  isSummary?: boolean
}

export const PanelBadges: React.FC<Props> = ({
  filterValues,
  comparativeIndex,
  countThreshold,
  infoOnly,
  savedFilter,
  openFilterPanel,
  small,
  updateLocalFilterValues,
  isSummary,
}) => {
  const countries = useProjectStore((state: ProjectState) => state.countries)
  const filterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList
  )
  const clearFilter = useProjectStore(
    (state: ProjectState) => state.clearFilter
  )
  const isComparative = useProjectStore(
    (state: ProjectState) => state.isComparative
  )
  const updateRelativeDateFilterIdx = useProjectStore(
    (state: ProjectState) => state.updateRelativeDateFilterIdx
  )
  const updateSelectedOptionField = useProjectStore(
    (state: ProjectState) => state.updateSelectedOptionField
  )
  const clearOpenedOptions = useProjectStore(
    (state: ProjectState) => state.clearOpenedOptions
  )

  if (!filterList?.length) return null

  let FILTERS_COUNT_THRESHOLD = isComparative || small ? 0 : 2
  FILTERS_COUNT_THRESHOLD = countThreshold
    ? countThreshold
    : FILTERS_COUNT_THRESHOLD
  const filtersUnderThreshold = filterValues.values
    .filter(
      (el) =>
        !el.field.startsWith('search_query') &&
        !el.field.startsWith('search_condition') &&
        (!countries?.enabled || !el.field.startsWith('country')) // &&
      // el.field !== 'create_time'
    )
    .filter((el) => {
      if (!isSummary) {
        return el.field !== 'create_time'
      } else {
        return true
      }
    })
    .slice(0, FILTERS_COUNT_THRESHOLD)

  function getTag(value: AppliedFilterOption, index: number) {
    if (!isSummary && value.field === 'create_time') return
    const color = colors[index]

    let valuesCount =
      'values' in value
        ? value.field.includes('sentiment')
          ? value.values.filter((item) => item !== '1' && item !== '0').length
          : value.values.length
        : 1

    const filterOption = filterList?.find((o) => o.field === value.field)

    // catch dupes if theme & grouped dash because grouped dash can have
    // multiple entries of the same theme name, most typically
    // Critics / Detractors or Fans / Attractors
    // this is probably inefficient
    if (filterOption?.field === 'theme') {
      const dupeObj: any = {}
      'values' in value &&
        value.values.forEach((item, index) => {
          const option = (filterOption as FilterOptionSelect).values?.find(
            (el) => el.cluster_uuid === item
          )
          if (option) {
            if (!dupeObj[option.value]) {
              dupeObj[option.value] = []
            }
            dupeObj[option.value].push(item)
          } else {
            if (!dupeObj['deleted_value_' + index]) {
              dupeObj['deleted_value_' + index] = []
            }
            dupeObj['deleted_value_' + index].push(item)
          }
        })
      valuesCount = Object.keys(dupeObj).length
    }

    if (
      (countries?.enabled && value.field.includes('country')) ||
      valuesCount === 0
    )
      return

    // @ts-ignore
    valuesCount = [...new Set(getBadgeLabels(value, filterList))].length

    return (
      <Tooltip
        key={value.field}
        placement="bottom"
        title={
          // @ts-ignore
          [...new Set(getBadgeLabels(value, filterList))]
            ?.sort((a, b) => (a?.toLowerCase() > b?.toLowerCase() ? 1 : -1))
            ?.map((option, index) => {
              return (
                <span key={index}>
                  {filterOption?.field === 'source_type' && option === 'Null'
                    ? 'Syndicated'
                    : option}
                  <br />
                </span>
              )
            })
          // .concat(
          //   value.hasOwnProperty('values')
          //     ? _.times(
          //         valuesCount -
          //           // @ts-ignore
          //           new Set(getBadgeLabels(value, filterList)).size
          //       )?.map((option, index) => {
          //         return (
          //           <span key={index}>
          //             [Deleted Value]
          //             <br />
          //           </span>
          //         )
          //       })
          //     : []
          // )
        }
      >
        <StyledTag
          key={value.field}
          id={'filter-badge-' + value.field}
          color={color}
          closable={!infoOnly}
          onClose={() => {
            if (updateLocalFilterValues) {
              updateLocalFilterValues({
                ...filterValues,
                values: filterValues.values.filter(
                  (el) => el.field !== value.field
                ),
              })
            } else {
              clearFilter(comparativeIndex, value.field)
              updateSelectedOptionField(comparativeIndex, '')
              clearOpenedOptions(comparativeIndex)
              if (value.field === HardcodedFilterOptionsEnum.CREATE_TIME) {
                updateRelativeDateFilterIdx(comparativeIndex, -1)
              }
            }
          }}
          style={{
            marginRight: filterValues.values.length === 1 ? '0px' : '8px',
            paddingRight: infoOnly ? '10px' : '6px',
            cursor: !!openFilterPanel ? 'pointer' : 'default',
          }}
          onClick={(e) => {
            if (openFilterPanel && filterList) {
              openFilterPanel(value.field)
            }
            e.stopPropagation()
          }}
        >
          <b>{valuesCount}</b>{' '}
          {filterOption?.alias
            ? pluralize(filterOption?.alias, valuesCount)
            : value.field}
        </StyledTag>
      </Tooltip>
    )
  }

  if (savedFilter)
    return (
      <Wrapper>
        {!!filterValues.values.length && (
          <Popover content={<>{filterValues.values.map(getTag)}</>}>
            <SavedFilterInfo>
              {/*<Icon>{filterIcon}</Icon>*/}
              <div
                style={{
                  padding: 0,
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: 5,
                }}
              >
                <img src={filterIcon} style={{ width: 12 }} />
              </div>
              {filterValues.values.length}
            </SavedFilterInfo>
          </Popover>
          //   TODO search terms
        )}
      </Wrapper>
    )

  return (
    <Wrapper>
      {filtersUnderThreshold.map(getTag)}
      {filterValues.values.filter((el) =>
        countries?.enabled
          ? !el.field.startsWith('country')
          : el.field !== 'create_time'
      ).length > FILTERS_COUNT_THRESHOLD && (
        <Popover content={<>{filterValues.values.map(getTag)}</>}>
          <StyledMoreTag
            style={{
              display: 'flex',
              padding: small ? '6px 10px' : '4px 10px',
            }}
          >
            {/*<SavedFilterInfo style={{ marginTop: small ? 2 : 0 }}>*/}
            {/*<Icon>{filterIcon}</Icon>*/}
            <div
              style={{
                padding: 0,
                display: 'flex',
                alignItems: 'center',
                marginRight: small ? 0 : 5,
              }}
            >
              <img src={filterIcon} style={{ width: small ? 16 : 12 }} />
            </div>
            {small
              ? ''
              : FILTERS_COUNT_THRESHOLD === 0
              ? 'View Filters'
              : 'Show All'}
            {/*</SavedFilterInfo>*/}
          </StyledMoreTag>
        </Popover>
        //   TODO search terms
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 !important;
  flex-wrap: wrap;
  row-gap: 8px;
`
const MoreButton = styled.a`
  font-size: 10px;
  color: var(--color-text-black);
  cursor: pointer;
  line-height: 12px;
  text-align: center;
`
const StyledTag = styled(Tag)`
  padding: 4px 6px 4px 10px;
  font-weight: 300;

  .ant-tag-close-icon {
    margin-left: 5px;
  }
`
const StyledMoreTag = styled(Tag)`
  padding: 4px 10px;
  font-weight: 300;
  cursor: pointer;

  background: rgba(64, 112, 192, 0.25);
  /* Button Blue */
  border: 1px solid #4070c0;

  .ant-tag-close-icon {
    margin-left: 5px;
  }
`
const SavedFilterInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  margin-top: -2px;
  cursor: pointer;

  /* Small Semibold */
  font-family: 'Poppins';
  font-style: normal;
  font-size: 12px;
  line-height: 130%;
  /* identical to box height, or 16px */

  text-align: center;

  /* Button Blue */

  color: #4070c0;

  background: rgba(64, 112, 192, 0.25);
  /* Button Blue */

  border: 1px solid #4070c0;
  border-radius: 3px;
`
