import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { CommentOutlined, SyncOutlined } from '@ant-design/icons'
import { InnerTitle } from '../../../../../components/InnerTitle'
import { Tooltip } from 'antd'
import { YogiButton } from '../../../../../components/UI/YogiButton'
import { PostDetailModal, PostsGrid } from '../../feedback'
import { Fetcher } from '../../../../../shared/components/fetcher'
import { useQuery } from 'react-query'
import { getSummaryPosts } from '../../charts/model'

type Props = {
  summaryRequest: any
  isThemeTier: boolean
}
export const Verbatims: React.FC<Props> = ({ summaryRequest, isThemeTier }) => {
  const [postDetailUuid, setPostDetailUuid] = useState<string>()
  const onClosePostDetail = useCallback(() => setPostDetailUuid(undefined), [])

  const {
    isFetching: postsFetching,
    data: postData,
    refetch: refetchPosts,
    isLoading: postsLoading,
  } = useQuery(
    ['summary-posts', summaryRequest],
    () => getSummaryPosts(summaryRequest.headers, summaryRequest.body),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!summaryRequest,
    }
  )

  const posts = useMemo(() => {
    if (!postData?.data) return []
    // there is a more efficient way to do this, but for some reason the type
    // for this post response is not the same as in other locations
    return postData.data.filter(
      (value, index, self) =>
        index ===
        self.findIndex((t) => t.title === value.title && t.body === value.body)
    )
  }, [postData])

  return (
    <>
      <InnerTitle>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CommentOutlined style={{ marginRight: '10px' }} />{' '}
            {isThemeTier ? 'Sentences' : 'Reviews'}
          </div>
          <Tooltip title={'Load a different set of Reviews'}>
            <YogiButton
              type="primary"
              onClick={() => refetchPosts()}
              icon={<SyncOutlined />}
              data-html2canvas-ignore={'true'}
            />
          </Tooltip>
        </div>
      </InnerTitle>
      {posts && (
        <>
          <PostsGrid
            // @ts-ignore
            posts={posts}
            onPostClick={(postId: string) => setPostDetailUuid(postId)}
            isThemeTier={isThemeTier}
            isLoading={postsLoading}
            isFetching={postsFetching}
            hasBorder={true}
          />
          {postsFetching && (
            <Overlay>
              <Fetcher />
            </Overlay>
          )}
        </>
      )}
      <PostDetailModal
        postUuid={postDetailUuid}
        onClose={onClosePostDetail}
        fragment={false}
      />
    </>
  )
}

const Overlay = styled.div`
  background: transparent;
  position: absolute;
  top: 30%;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`
