import { useProjectStore } from '../projectStore/projectStore'
import { Filters, ProjectState } from '../types'
import {
  AppliedFilterOption,
  AppliedFilterOptionRange,
  AppliedFilterOptionSelect,
  FilterOptionSelect,
} from '../features/filters/types'
import React from 'react'
import {
  LineChart,
  StackBarChart,
  TimeInterval,
  UnionChart,
} from '../features/charts/types'
import _ from 'lodash'
import {
  buildSummaryRequest,
  ChartTypes,
  getComparedTimePeriod,
  matrixChartIds,
} from '../features/charts/utils'
import moment from 'moment'
import useThemes from './useThemes'

export const processIntervalDate = (interval: string, date: string) => {
  let max, min
  moment.locale('en-US')
  switch (interval) {
    case 'year':
      min = moment.utc(date, 'YYYY').startOf('year').unix()
      max = moment.utc(date, 'YYYY').endOf('year').unix()
      break

    case 'quarter':
      min = moment.utc(date, 'YYYY Q').startOf('quarter').unix()
      max = moment.utc(date, 'YYYY Q').endOf('quarter').unix()
      break

    case 'month':
      min = moment.utc(date, "MMM 'YY").startOf('month').unix()
      max = moment.utc(date, "MMM 'YY").endOf('month').unix()
      break
    case 'week':
      min = moment.utc(date, 'MM/DD/YYYY').startOf('week').unix()
      max = moment.utc(date, 'MM/DD/YYYY').endOf('week').unix()
      break
    default:
      min = moment.utc(date, "MMM 'YY").startOf('month').unix()
      max = moment.utc(date, "MMM 'YY").endOf('month').unix()
      break
  }
  return [max, min]
}

const useChart = () => {
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList
  )
  const { getClusterUUIDsFromThemeName } = useThemes()

  const clickHandler = (
    contextMenuRef: React.RefObject<HTMLDivElement> | null,
    clickedChart: React.MutableRefObject<any>,
    currentChart: UnionChart,
    chartAPI: any,
    treeView: boolean,
    projectId: string,
    filters: Filters,
    tier1: string,
    matrixFlipAxis: boolean,
    comparePeriod: string,
    timeInterval: TimeInterval
  ) => {
    if (contextMenuRef?.current) {
      contextMenuRef.current.style.display = 'none'
    }

    const selectedChart = { ...clickedChart?.current[0] }
    clickedChart.current = []

    if (!selectedChart) return

    const chartType = currentChart.chart_type
    const isMatrixChart =
      chartType === ChartTypes.MATRIX ||
      matrixChartIds.includes(currentChart.chart_id)

    let tier1Value = ''
    let tier1Label: string | undefined
    let tier2Value: string | null = null
    let tier3Value: string | null = null
    const additionalFilters: AppliedFilterOption[] = []
    const tier1Identifier = (
      defaultFilterList?.find((el) => el.alias === tier1) as FilterOptionSelect
    )?.field

    let filtersCopy = _.cloneDeep(filters)
    // clear the existing selections for tier1 filter - will be replaced with the chart selection
    // because themes have nested values, we handle this case separately
    if (tier1Identifier !== 'theme') {
      filtersCopy.values = filtersCopy.values.filter(
        (el) => el.field !== tier1Identifier
      )
    }

    const extractChartData = () => {
      if (isMatrixChart) {
        handleMatrixChart()
      } else {
        switch (chartType) {
          case ChartTypes.PIE:
            handlePieChart()
            break
          case ChartTypes.STACK_BAR:
            handleStackBarChart()
            break
          case ChartTypes.BAR:
            handleBarChart()
            break
          case ChartTypes.LINE:
            handleLineChart()
            break
          case ChartTypes.TABLE:
            handleTableChart()
            break
          default:
            handleDefaultChart()
        }
      }
    }

    const handleMatrixChart = () => {
      const chartData =
        chartAPI.data.datasets[selectedChart.datasetIndex]?.data[
          selectedChart.index
        ]
      if (!chartData) return
      tier1 = 'Theme'
      tier1Label = tier3Value = `${chartData?.[matrixFlipAxis ? 'y' : 'x']} - ${
        chartData?.[matrixFlipAxis ? 'x' : 'y']
      }`
      tier1Value = chartData?.[matrixFlipAxis ? 'y' : 'x']
      let lensName = (currentChart as StackBarChart).x_title
        .split('-')[1]
        .trim()
        .toLowerCase()
      if (lensName === 'product') lensName = 'product_hierarchy'
      additionalFilters.push({
        field: lensName,
        values: [chartData?.[matrixFlipAxis ? 'x' : 'y']],
      } as AppliedFilterOptionSelect)
    }

    const handlePieChart = () => {
      if (!treeView) {
        tier1Value = chartAPI.data.labels[selectedChart.index]
      } else {
        tier1Value = chartAPI.data.datasets[0].data[selectedChart.index]?.g
      }
    }

    const handleStackBarChart = () => {
      if (Number(currentChart.chart_id.split('_')[1]) !== 4) {
        tier3Value = chartAPI.data.datasets[selectedChart.datasetIndex]?.label
        tier1Value = chartAPI.data.labels[selectedChart.index]
      } else {
        tier3Value = chartAPI.data.labels[selectedChart.index]
        tier1Value = chartAPI.data.datasets[selectedChart.datasetIndex]?.label
        const label = chartAPI.data.labels[selectedChart.index]
        const [max, min] = processIntervalDate(
          (currentChart as LineChart).interval,
          label
        )
        additionalFilters.push({
          field: 'create_time',
          max,
          min,
        } as AppliedFilterOptionRange)
      }
    }

    const handleBarChart = () => {
      if (Number(currentChart.chart_id.split('_')[1]) === 5) {
        tier3Value = null
      } else {
        tier3Value = chartAPI.data.labels[selectedChart.index]
      }
      tier1Value = chartAPI.data.labels[selectedChart.index]

      if (
        chartAPI.data.datasets[selectedChart.datasetIndex]?.custom?.min &&
        chartAPI.data.datasets[selectedChart.datasetIndex]?.custom?.max
      ) {
        additionalFilters.push({
          field: 'create_time',
          min: chartAPI.data.datasets[selectedChart.datasetIndex]?.custom?.min,
          max: chartAPI.data.datasets[selectedChart.datasetIndex]?.custom?.max,
        } as AppliedFilterOptionRange)
      }
    }

    const handleLineChart = () => {
      let label = chartAPI.data.labels[selectedChart.index]

      if (
        chartAPI.data.datasets[selectedChart.datasetIndex]?.borderDash &&
        comparePeriod
      ) {
        label = getComparedTimePeriod(label, comparePeriod, timeInterval)
      }

      const [max, min] = processIntervalDate(
        (currentChart as LineChart).interval,
        label
      )

      additionalFilters.push({
        field: 'create_time',
        max,
        min,
      } as AppliedFilterOptionRange)

      if (chartAPI.data.datasets[selectedChart.datasetIndex]) {
        tier1Value = chartAPI.data.datasets[selectedChart.datasetIndex]?.label

        if (
          tier1Value.slice(tier1Value.length - 6, tier1Value.length) ===
          'Volume'
        ) {
          tier1Value = tier1Value.slice(0, tier1Value.length - 6).trim()
        }
      }
      tier3Value = label
    }

    const handleTableChart = () => {
      tier1Value = selectedChart?.category
    }

    const handleDefaultChart = () => {
      tier1Value = (chartAPI as any).data.datasets[selectedChart.datasetIndex]
        ?.label
    }

    extractChartData()

    if (!tier1Value) return

    tier1Label = tier1Label ?? tier1Value

    const handleSpecialCases = () => {
      if (tier1 === 'Theme') {
        handleThemeCase()
      } else if (tier1 === 'Brand') {
        handleBrandCase()
      } else if (tier1 === 'Product') {
        handleProductCase()
      }

      // Handle other cases
      if (tier1 !== tier1Value && tier1 !== 'Theme') {
        handleOtherCases()
      }

      if (tier3Value) {
        handleTier3Case()
      }
    }

    const handleThemeCase = () => {
      // colon implies a subtheme, no colon implies a group theme
      // we'll have to extract the subthemes from the grouped theme
      if (!tier1Value.includes(': ')) {
        handleGroupThemeCase()
      } else {
        handleSubthemeCase()
      }
    }

    const handleGroupThemeCase = () => {
      if (filtersCopy.values.find((el) => el.field === 'theme')) {
        const cluster_uuids =
          (
            filtersCopy.values.find(
              (el) => el.field === 'theme'
            ) as AppliedFilterOptionSelect
          )?.values ?? []
        const idsToKeep = (
          defaultFilterList.find(
            (el) => el.field === 'theme'
          ) as FilterOptionSelect
        )?.values.filter(
          (el) =>
            el.value.includes(tier1Value + ': ') &&
            el.cluster_uuid &&
            // some filters have the value names (which is deprecated), but we still check for them
            (cluster_uuids.includes(el.cluster_uuid) ||
              cluster_uuids.includes(el.value))
        )
        filtersCopy.values = filtersCopy.values.filter(
          (el) => el.field !== 'theme'
        )
        filtersCopy.values.push({
          field: 'theme',
          values: idsToKeep.map((el) => el.cluster_uuid ?? el.value),
        })
      } else {
        additionalFilters.push({
          field: 'theme',
          values:
            (
              defaultFilterList.find(
                (el) => el.field === 'theme'
              ) as FilterOptionSelect
            )?.values
              // ?.map((el) => el.value)
              ?.filter((el) => el.value.includes(tier1Value + ': '))
              .map((el) => el.cluster_uuid ?? el.value) ?? [],
        })
      }
      tier1Value = ''
    }

    const handleSubthemeCase = () => {
      const clusterIds = (
        defaultFilterList.find(
          (item) => item.field === 'theme'
        ) as FilterOptionSelect
      )?.values
        .filter((el) => el.value === tier1Value)
        .map((el) => el.cluster_uuid ?? el.value)
      filtersCopy.values = filtersCopy.values.filter(
        (el) => el.field !== 'theme'
      )
      additionalFilters.push({
        field: 'theme',
        values: clusterIds,
      })
    }

    const handleBrandCase = () => {
      if (tier1Value === 'My Brands' || tier1Value === 'Competitor Brands') {
        if (filtersCopy.values.find((el) => el.field === 'brand')) {
          let brandValues = (
            filtersCopy.values.find(
              (el) => el.field === 'brand'
            ) as AppliedFilterOptionSelect
          )?.values
          brandValues = brandValues.filter((el) =>
            currentChart.category_mapping[tier1Value].includes(el)
          )
          filtersCopy.values = filtersCopy.values.filter(
            (el) => el.field !== 'brand'
          )
          filtersCopy.values.push({
            field: 'brand',
            values: brandValues,
          })
        } else {
          filtersCopy.values = filtersCopy.values.filter(
            (el) => el.field !== 'brand'
          )
          filtersCopy.values.push({
            field: 'brand',
            values: currentChart.category_mapping[tier1Value],
          })
        }
        tier1Value = ''
      }
    }

    const handleProductCase = () => {
      if (
        tier1Value === 'My Products' ||
        tier1Value === 'Competitor Products'
      ) {
        if (filtersCopy.values.find((el) => el.field === 'product_hierarchy')) {
          let brandValues = (
            filtersCopy.values.find(
              (el) => el.field === 'product_hierarchy'
            ) as AppliedFilterOptionSelect
          )?.values
          brandValues = brandValues.filter((el) =>
            currentChart.category_mapping[tier1Value].includes(el)
          )
          filtersCopy.values = filtersCopy.values.filter(
            (el) => el.field !== 'product_hierarchy'
          )
          filtersCopy.values.push({
            field: 'product_hierarchy',
            values: brandValues,
          })
        } else {
          filtersCopy.values = filtersCopy.values.filter(
            (el) => el.field !== 'product_hierarchy'
          )
          filtersCopy.values.push({
            field: 'product_hierarchy',
            values: currentChart.category_mapping[tier1Value],
          })
        }
        tier1Value = ''
      }
    }

    const handleOtherCases = () => {
      const tier1Identifier = (
        defaultFilterList?.find(
          (el) => el.alias === tier1
        ) as FilterOptionSelect
      )?.field
      if (tier1 === 'Rating') {
        if (tier1Identifier) {
          filtersCopy.values.push({
            field: tier1Identifier,
            min: Number(tier1Value),
            max: Number(tier1Value),
            value_type: 0,
          })
        }
      } else {
        if (tier1Identifier && tier1Value) {
          filtersCopy.values.push({
            field: tier1Identifier,
            values: [tier1Value],
          })
        }
      }
    }

    const handleTier3Case = () => {
      if (Number(currentChart.chart_id.split('_')[1]) !== 4 && !isMatrixChart) {
        if (currentChart.chart_id.split('_')[3] === 'rating') {
          handleRatingTier3Case()
        } else {
          handleNonRatingTier3Case()
        }
      }
    }

    const handleRatingTier3Case = () => {
      const tier3Identifier = (
        defaultFilterList?.find(
          (el) => el.alias.toLowerCase() === currentChart.chart_id.split('_')[3]
        ) as FilterOptionSelect
      )?.field

      if (tier3Identifier) {
        filtersCopy.values.push({
          field: tier3Identifier,
          min: Number(tier3Value),
          max: Number(tier3Value),
          value_type: 0,
        })
      }
    }

    const handleNonRatingTier3Case = () => {
      const tier3Field =
        currentChart.chart_id.split('_')[3] === 'themes'
          ? 'theme'
          : currentChart.chart_id.split('_')[3]
      const tier3Identifier = (
        defaultFilterList?.find(
          (el) => el.alias.toLowerCase() === tier3Field
        ) as FilterOptionSelect
      )?.field

      if (tier3Identifier) {
        filtersCopy.values = filtersCopy.values.filter(
          (el) => el.field !== tier3Identifier
        )
        if (tier3Field === 'theme') {
          filtersCopy.values.push({
            field: 'theme',
            values: getClusterUUIDsFromThemeName(
              tier3Value!.split(': ')[0],
              tier3Value!.split(': ')[1]
            ),
          })
        } else {
          filtersCopy.values.push({
            field: tier3Identifier,
            values: [tier3Value!],
          })
        }
      }
    }

    handleSpecialCases()

    return buildSummaryRequest(
      currentChart,
      projectId,
      filtersCopy,
      tier1,
      tier1Value,
      tier2Value,
      tier3Value,
      additionalFilters,
      tier1Label
    )
  }

  return { clickHandler }
}

export default useChart
