import React, { useEffect, useState } from 'react'
import DashboardList from './components/DashboardList/DashboardList'
import { DashboardResponse } from './types'
import { Dashboard } from './components/Dashboard/Dashboard'
import { useQuery } from 'react-query'
import { getPublicDashboard } from './model'
import { useProjectStore } from '../../projectStore/projectStore'
import { ProjectState } from '../../types'
import { Drawer } from './components/Dashboard/Drawer'
import { Fetcher } from '../../../../shared/components/fetcher'
import styled from 'styled-components'
import mixpanel from '../../../trackers/mixpanel'
import _ from 'lodash'
import { HardcodedFilterOptionsEnum } from '../filters/helpers'
import useCustomDashboards from '../../hooks/useCustomDashboards'

type Props = {
  useNotifications: any
  isPublic?: boolean
}

export const Dashboards: React.FC<Props> = ({ useNotifications, isPublic }) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const dateWindows = useProjectStore((state) => state.dateWindows)

  const [view, setView] = useState<'list' | 'view' | 'edit'>('list')
  const [selectedDashboard, setSelectedDashboard] = useState<
    DashboardResponse | undefined
  >()

  const [sharingDashboard, setSharingDashboard] = useState<
    DashboardResponse | undefined
  >()

  const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(false)
  const [showFetcher, setShowFetcher] = useState<boolean>(false)

  useEffect(() => {
    if (drawerIsOpen) return
    setTimeout(() => {
      setSharingDashboard(undefined)
    }, 300)
  }, [drawerIsOpen])

  useEffect(() => {
    setDrawerIsOpen(!!sharingDashboard)
  }, [sharingDashboard])

  const { data, refetch } = useCustomDashboards()

  const urlParams = new URLSearchParams(window.location.search)
  const publicId = urlParams.get('dashboard_id')

  const { data: publicDashboard } = useQuery(
    ['shared-custom-dashboard', projectId, publicId],
    () => {
      mixpanel.track('custom dashboard', {
        action: 'public',
        value: publicId,
        proj_uuid: projectId,
      })
      return getPublicDashboard(projectId, publicId!)
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId && !!isPublic && !!publicId,
    },
  )

  useEffect(() => {
    if (publicDashboard) {
      setSelectedDashboard(publicDashboard)
      setView('view')
    }
  }, [publicDashboard])

  const dashboardId = new URLSearchParams(window.location.search).get(
    'dashboard_id',
  )

  useEffect(() => {
    if (data) {
      if (dashboardId) {
        const dashboardResponse = data.find(
          ({ dashboard }) => dashboard.id === Number(dashboardId),
        )
        if (!dashboardResponse) return

        const isCurrent =
          dashboardResponse?.dashboard?.dashboard_settings?.isCurrent
        const timeResolution =
          dashboardResponse?.dashboard?.dashboard_settings?.timeResolution
        const trailingDaysCount =
          dashboardResponse?.dashboard?.dashboard_settings?.trailingDaysCount ??
          30
        // if its a current-flagged dash, insert the proper date filter
        if (dashboardResponse && isCurrent) {
          const filterCopy = _.cloneDeep(
            dashboardResponse.dashboard.dashboard_settings.filters,
          )

          // not my fave --minor
          let dateFilter =
            dateWindows[timeResolution ?? 'Month']?.length > 1 &&
            dateWindows[timeResolution ?? 'Month'][1]?.value
              ? JSON.parse(dateWindows[timeResolution ?? 'Month'][1]?.value)
              : null
          if (timeResolution === 'Trailing') {
            const dateWindow = dateWindows[timeResolution]?.find(
              (el) => el.daysCount === (trailingDaysCount ?? 30),
            )
            dateFilter = dateWindow ? JSON.parse(dateWindow.value) : dateFilter
          }
          if (dateFilter) {
            filterCopy.values = filterCopy.values.filter(
              ({ field }) => field !== HardcodedFilterOptionsEnum.CREATE_TIME,
            )
            filterCopy.values.push({
              field: HardcodedFilterOptionsEnum.CREATE_TIME,
              min: dateFilter.min,
              max: dateFilter.max,
              value_type: 0,
            })
          }
          dashboardResponse.dashboard.dashboard_settings.filters = filterCopy
        }

        setSelectedDashboard(dashboardResponse)
        setView('view')
      } else {
        setSelectedDashboard(undefined)
        setView('list')
      }
    }
    // This updates the UI when removing access to a dashboard
    if (data && sharingDashboard) {
      setSharingDashboard(
        data.find(
          ({ dashboard }) =>
            dashboard.id === Number(sharingDashboard.dashboard.id),
        ),
      )
    }
  }, [data, dashboardId])

  return (
    <>
      {view === 'list' ? (
        <>
          {isPublic ? (
            <div></div>
          ) : (
            <DashboardList
              setView={setView}
              setSelectedDashboard={setSelectedDashboard}
              setSharingDashboard={setSharingDashboard}
              setDrawerIsOpen={setDrawerIsOpen}
              setShowFetcher={setShowFetcher}
            />
          )}
        </>
      ) : (
        <Dashboard
          view={view}
          selectedDashboard={selectedDashboard}
          setView={setView}
          setSharingDashboard={setSharingDashboard}
          refetch={refetch}
          isPublic={!!isPublic}
          setShowFetcher={setShowFetcher}
        />
      )}
      <Drawer
        isOpen={drawerIsOpen}
        setIsOpen={setDrawerIsOpen}
        refetch={refetch}
        dashboardData={sharingDashboard}
      />

      {showFetcher && (
        <LoadingLayer>
          <Fetcher />
        </LoadingLayer>
      )}
    </>
  )
}

const LoadingLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.4);
  cursor: inherit;
  z-index: 100;
`
