import {
  AppliedFilterOptionRange,
  AppliedFilterOptionSelect,
} from '../../../../types'
import { isAppliedFilterOptionSelect } from '../../../../helpers'

export const checkIfFilterSelected = {
  checkbox: (
    filter: AppliedFilterOptionSelect | AppliedFilterOptionRange | undefined,
    value: string
  ) => {
    if (!filter) return false

    return isAppliedFilterOptionSelect(filter)
      ? filter.values.includes(value)
      : false
  },
  slider: (
    filter: AppliedFilterOptionRange | undefined
  ): [number, number] | undefined => {
    if (filter?.max && filter?.min) return [filter.min, filter.max]
  },
}

export const checkIfGroupFilterSelected = {
  checkbox: (
    filter: AppliedFilterOptionSelect | AppliedFilterOptionRange | undefined,
    value: string[]
  ) => {
    if (!filter) return false

    return isAppliedFilterOptionSelect(filter)
      ? value.every((v) => filter.values.includes(v))
      : false
  },
  slider: (
    filter: AppliedFilterOptionRange | undefined
  ): [number, number] | undefined => {
    if (filter?.max && filter?.min) return [filter.min, filter.max]
  },
}
