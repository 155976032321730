import React, { useEffect, useState } from 'react'
import { useProjectStore } from '../../../projectStore/projectStore'
import { ProjectState } from '../../../types'
import useThemes from '../../../hooks/useThemes'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import { CATALOG_URL, PDP_URL } from '../../../utils'
import { Rate, Switch, Tooltip } from 'antd'
import { FavoriteBorderOutlined, FormatQuote } from '@mui/icons-material'
import {
  buttonBlue,
  lightText,
  secondaryGreen,
  secondaryNavy,
  secondaryRed,
  secondaryYellow,
} from '../../../../../assets/styles/variables'
import { getThemeLink } from './utils'
import { rgbToRgba } from '../utils'
import { YogiButton } from '../../../../../components/UI/YogiButton'
import { Fetcher } from '../../../../../shared/components/fetcher'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import useReviewCard from '../../../hooks/useReviewCard'
import { Keyword, Product, Sentence, Source, Theme } from '../model'

type ReviewCardProps = {
  uuid: string
  create_time: string
  username: string
  title: string
  score: number
  sentences: Sentence[]
  keywords: Keyword[]
  product: Product
  source: Source
  themes: Theme[]
}
const ReviewCard: React.FC<ReviewCardProps> = ({
  title,
  create_time,
  score,
  sentences,
  username,
  keywords,
  product,
  source,
  themes,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const details = useProjectStore((state: ProjectState) => state.details)

  const isLLMDash = !!details.clientSettings['llm-dash']

  const {
    highlightKeywords,
    handleKeywordClick,
    handleSectionClick,
    handleSentenceClick,
  } = useReviewCard()
  const { getNormalizedThemeName } = useThemes()

  const [hoveredSentenceIndex, setHoveredSentenceIndex] = useState<
    number | undefined
  >()
  const [hoveredTheme, setHoveredTheme] = useState<string | undefined>()
  const [showFetcher, setShowFetcher] = useState<boolean>(false)
  const [productImageValid, setProductImageValid] = useState<boolean>(false)
  const [productImage, setProductImage] = useState<string | undefined>()
  const [sourceImage, setSourceImage] = useState<string | undefined>(undefined)
  const [isSentimentHighlight, setIsSentimentHighlight] =
    React.useState<boolean>(false)

  const toggleSwitch = (checked: boolean) => {
    setIsSentimentHighlight(checked)
  }

  const { data: feature_flags } = useFeatureFlags()

  const checkImage = (url: string) => {
    if (!url) {
      setProductImageValid(false)
      return
    }
    const img = new Image()
    img.onload = () => {
      setProductImage(url)
      setProductImageValid(true)
    }
    img.onerror = () => {
      // TODO check the hierarchy image
      // if(url !== product.hierarchy_url){
      //   checkImage(product.hierarchy_url)
      // }
      setProductImageValid(false)
    }
    img.src = url
  }

  useEffect(() => {
    setProductImage(product.image_url)
    checkImage(product.image_url)
  }, [product.image_url])

  useEffect(() => {
    ;(async () => {
      try {
        let a = await import(`assets/images/${source.type}-Logo.svg`)
        setSourceImage(a.default)
      } catch (e) {
        setSourceImage(undefined)
      }
    })()
  }, [source.type])

  const averageSentiment =
    sentences.reduce((acc, sentence) => {
      return acc + Number(sentence.sentiment)
    }, 0) / sentences.length

  return (
    <CardsWrapper>
      <CardContainer
        style={{
          position: 'relative',
          zIndex: 100,
          marginTop: 10,
          marginBottom: 20,
        }}
      >
        <Left>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Title style={{ fontSize: 20, marginTop: 3 }}>
              {/*{title.length > 50 ? `${title.slice(0, 50)}...` : title}*/}
              {title}
            </Title>
            <ReviewInfo>
              <DateText>{new Date(create_time).toLocaleDateString()}</DateText>
              <DateText
                style={{
                  height: 37,
                  display: 'flex',
                  gap: 5,
                  // cursor: 'pointer',
                }}
                // onMouseEnter={() => setIsSentimentHighlight(true)}
                // onMouseLeave={() => setIsSentimentHighlight(false)}
              >
                <FavoriteBorderOutlined
                  style={{
                    padding: '1px',
                    marginTop: -2,
                    color:
                      averageSentiment >= 0.3
                        ? secondaryGreen
                        : averageSentiment <= -0.3
                          ? secondaryRed
                          : secondaryYellow,
                  }}
                />
                <div>{averageSentiment.toFixed(2)}</div>
              </DateText>
              <Rating>
                <Tooltip title={`Rating: ${score}`}>
                  <Rate
                    value={score}
                    allowHalf
                    disabled
                    style={{ marginBottom: 5 }}
                  />
                </Tooltip>
              </Rating>

              {/*<StyledSwitch*/}
              {/*  defaultChecked*/}
              {/*  checked={isSentimentHighlight}*/}
              {/*  onChange={toggleSwitch}*/}
              {/*  checkedChildren={<HighlightOutlined />}*/}
              {/*  unCheckedChildren={<HighlightOutlined />}*/}
              {/*  style={{ marginTop: -3 }}*/}
              {/*/>*/}
            </ReviewInfo>
          </div>
          <ReviewContainer>
            <FormatQuote
              style={{
                color: lightText,
                transform: 'rotate(180deg)',
                marginLeft: -24,
              }}
            />
            {sentences.map((sentence, index) => {
              const theme = themes[sentence.theme_idx]
              const sentiment = Number(sentence.sentiment)
              // const sentimentColor = interpolateSentimentColor(
              //   Number(sentiment),
              //   false
              // )
              const sentimentColor =
                sentiment >= 0.3
                  ? 'rgb(0,158,115)'
                  : sentiment <= -0.3
                    ? 'rgb(213,94,0)'
                    : 'rgb(240,228,66)'
              if (!theme) {
                return (
                  <SentenceText
                    dangerouslySetInnerHTML={{
                      __html: highlightKeywords(sentence.text, keywords),
                    }}
                    style={{ cursor: 'default' }}
                  />
                )
              }
              const isFirstAppearance =
                sentences.findIndex(
                  (el) => el.theme_idx === sentence.theme_idx,
                ) === index
              return (
                <StyledLink
                  key={index}
                  to={getThemeLink(theme.name, projectId, isLLMDash)}
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  <Tooltip
                    key={index}
                    title={`Theme: ${theme.name}, Sentiment: ${sentence.sentiment}`}
                    visible={
                      (hoveredTheme === theme.name && isFirstAppearance) ||
                      index === hoveredSentenceIndex
                    }
                  >
                    <SentenceText
                      onMouseEnter={() => setHoveredSentenceIndex(index)}
                      onMouseLeave={() => setHoveredSentenceIndex(undefined)}
                      onClick={() => handleSentenceClick(theme, sentence.text)}
                      // themeColor={hexToRgbA(sentence.theme.color, 0.5)}
                      themeColor={
                        hoveredTheme === theme.name ||
                        index === hoveredSentenceIndex
                          ? rgbToRgba(theme.color, 0.25)
                          : rgbToRgba(theme.color, 0.1)
                      }
                      sentimentColor={
                        hoveredTheme === theme.name ||
                        index === hoveredSentenceIndex
                          ? rgbToRgba(sentimentColor, 0.4)
                          : rgbToRgba(sentimentColor, 0.25)
                      }
                      dangerouslySetInnerHTML={{
                        __html: highlightKeywords(sentence.text, keywords),
                      }}
                      hovered={
                        index === hoveredSentenceIndex ||
                        hoveredSentenceIndex === undefined
                      }
                      isSentimentHighlight={isSentimentHighlight}
                    />
                  </Tooltip>
                </StyledLink>
              )
            })}
            <FormatQuote style={{ color: lightText }} />
            <Reviewer>- {username}</Reviewer>
          </ReviewContainer>

          <Label style={{ margin: '10px auto 0', width: '90%' }}>
            <span>Keywords</span>
          </Label>
          <Keywords>
            {keywords.slice(0, 3).map((keyword, index) => (
              <>
                <KeywordItem
                  key={index}
                  onClick={() =>
                    handleKeywordClick(keyword.word, setShowFetcher)
                  }
                  style={{
                    borderRight:
                      index < keywords.length - 1
                        ? '1px solid #e8e8e8'
                        : 'none',
                  }}
                >
                  <p style={{ color: buttonBlue }}>
                    <i>"{keyword.word}"</i>
                  </p>
                  {/*<span>*/}
                  {/*  {numberFormatter(keyword.frequency * 100) || 0}% of Reviews*/}
                  {/*</span>{' '}*/}
                  {/* Update this with actual percentage values */}
                </KeywordItem>
                {/*{index < keywords.length - 1 && (*/}
                {/*  <div style={{ borderRight: '1px solid #e8e8e8' }} />*/}
                {/*)}*/}
              </>
            ))}
          </Keywords>
          <Label style={{ margin: '10px auto 0', width: '90%' }}>
            <span>Themes</span>
          </Label>
          <Themes>
            {themes.slice(0, 3).map((theme, index) => (
              <StyledLink
                key={index}
                to={getThemeLink(theme.name, projectId, isLLMDash)}
                target={'_blank'}
                rel="noopener noreferrer"
              >
                <ThemeItem
                  onClick={() => handleSectionClick('theme', theme.name)}
                  onMouseEnter={() => setHoveredTheme(theme.name)}
                  onMouseLeave={() => setHoveredTheme(undefined)}
                  hovered={
                    hoveredSentenceIndex !== undefined &&
                    themes[sentences[hoveredSentenceIndex].theme_idx].name ===
                      theme.name
                  }
                >
                  <ThemeDot color={theme.color}></ThemeDot>
                  <ThemeText>{getNormalizedThemeName(theme.name)}</ThemeText>
                </ThemeItem>
              </StyledLink>
            ))}
          </Themes>
        </Left>
        <Right>
          <StyledLink
            to={`/project/${projectId}/${CATALOG_URL}?category=product_hierarchy&item=${encodeURIComponent(
              product.hierarchy,
            )}`}
            target={'_blank'}
            rel="noopener noreferrer"
            style={{ flexBasis: '40%' }}
          >
            <ImageSection
              onClick={() => handleSectionClick('product', product.hierarchy)}
            >
              <Label>
                <span>Product</span>
              </Label>
              {productImageValid ? (
                <>
                  <div>
                    {product.hierarchy.length > 50
                      ? `${product.hierarchy.slice(0, 50)}...`
                      : product.hierarchy}
                  </div>
                  <Img src={productImage} alt={product.hierarchy} />
                </>
              ) : (
                <BrandText>{product.hierarchy}</BrandText>
              )}
            </ImageSection>
          </StyledLink>
          <StyledLink
            to={`/project/${projectId}/${CATALOG_URL}?category=brand&item=${encodeURIComponent(
              product.brand.name,
            )}`}
            target={'_blank'}
            rel="noopener noreferrer"
          >
            <ImageSection
              onClick={() => handleSectionClick('brand', product.brand.name)}
            >
              <Label style={{ marginBottom: 10 }}>
                <span>Brand</span>
              </Label>

              {product.brand.image_url ? (
                <>
                  <div>{product.brand.name}</div>
                  <Img src={product.brand.image_url} alt={product.brand.name} />
                </>
              ) : (
                <BrandText>{product.brand.name}</BrandText>
              )}
              {/*<Image src={product.brand.image_url} alt={product.brand.name} />*/}
            </ImageSection>
          </StyledLink>
          <StyledLink
            to={`/project/${projectId}/${CATALOG_URL}?category=source_type&item=${encodeURIComponent(
              source.type,
            )}`}
            target={'_blank'}
            rel="noopener noreferrer"
          >
            <ImageSection
              onClick={() => handleSectionClick('source', source.type)}
            >
              <Label>
                <span>Source</span>
              </Label>
              {sourceImage ? (
                <>
                  <div>{source.type}</div>
                  <Img
                    src={sourceImage}
                    alt={source.type}
                    style={{ maxHeight: 75 }}
                  />
                </>
              ) : (
                <BrandText>{source.type ?? source.name}</BrandText>
              )}
            </ImageSection>
          </StyledLink>
          {feature_flags?.pdp_tool && source.type === 'Amazon' && (
            <StyledLink
              to={`/project/${projectId}/${PDP_URL}?product=${encodeURIComponent(
                product.hierarchy,
              )}&item=${encodeURIComponent(product.uuid)}`}
              target={'_blank'}
              rel="noopener noreferrer"
              onClick={() => handleSectionClick('PDP', product.hierarchy)}
            >
              <ImageSection>
                <Label>
                  <span>PDP Insights</span>
                </Label>
                <YogiButton type={'primary'}>PDP Insights</YogiButton>
              </ImageSection>
            </StyledLink>
          )}
        </Right>
      </CardContainer>

      <div
        style={{
          textAlign: 'center',
          width: '100%',
          marginTop: -10,
          marginBottom: 10,
        }}
      >
        Click on Themes, Keywords, Products, Brands, or Sources to explore more.
      </div>
      {showFetcher && (
        <LoadingLayer>
          <Fetcher />
        </LoadingLayer>
      )}
    </CardsWrapper>
  )
}
export default ReviewCard

const CardsWrapper = styled.div`
  position: relative;
  max-width: 90%;
`

const CardContainer = styled.div`
  background-color: white;
  padding: 12px 16px;
  width: 1000px;
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  margin: 15px auto 0;
`

const ReviewInfo = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
`

const Title = styled.div`
  font-size: 24px;
  margin-bottom: 0px;
  font-weight: 600;
  flex-grow: 1;
`

const ReviewContainer = styled.div`
  margin: 10px 0;
  padding-left: 20px;
`

const SentenceText = styled.span<{
  themeColor?: string
  sentimentColor?: string
  hovered?: boolean
  isSentimentHighlight?: boolean
}>`
  background-color: ${(props) =>
    props.isSentimentHighlight
      ? (props.sentimentColor ?? 'transparent')
      : (props.themeColor ?? 'transparent')};
  color: var(--main-text);
  cursor: pointer;
  margin-right: 5px;
  font-size: 20px;
  opacity: 1;
  .keyword {
    font-weight: 500;
  }
`

const Keywords = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
`

const KeywordItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s;
  border-radius: 8px;
  padding: 5px 0px;

  &:hover {
    transform: scale(1.1);
    background: var(--card-background-white);
  }

  p {
    font-size: 1.4rem;
    margin-bottom: 5px;
  }

  span {
    font-size: 1rem;
    color: gray;
  }
`

const Themes = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 12px;
`

const ThemeText = styled.div``

const ThemeItem = styled.div<{ hovered: boolean }>`
  width: 100%;
  color: var(--main-text);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;
  padding: 5px 10px;
  margin: 5px 0;
  border: 1px solid #e8e8e8;
  background: var(--card-background-white);
  transform: ${(props) => (props.hovered ? 'scale(1.1)' : 'scale(1)')};

  &:hover {
    transform: scale(1.1);
  }
`

const ThemeDot = styled.div`
  height: 8px;
  width: 8px;
  margin-right: 8px;
  background-color: ${({ color }) => color || '#fff'};
  border-radius: 50%;
  flex-shrink: 0;
`

const Img = styled.img`
  width: auto;
  height: auto;
  max-height: 100px;
  max-width: 150px;
  margin-top: 10px;
`

const Left = styled.div`
  flex: 1;
  border-right: 1px solid #e8e8e8;
  padding-right: 20px;
`

const Right = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  width: 25%;
`

const Reviewer = styled.span`
  width: 100%;
  text-align: center;
  font-style: italic;
`

const Label = styled.div`
  color: var(--light-text);
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  line-height: 0.1em;
  margin: 10px 0 20px;
  span {
    background: #fff;
    padding: 0 10px;
  }
`

const ImageSection = styled.div`
  cursor: pointer;
  text-align: center;
  transition: all 0.2s;

  &:not(:first-child) {
    margin-top: 10px;
  }

  &:hover {
    transform: scale(1.05);
  }
`

const DateText = styled.div`
  padding: 8px 12px;
  background: var(--card-background-white);
`
const Rating = styled.div`
  padding: 0px 10px;
  background: var(--card-background-white);
  white-space: nowrap;
`
const StyledLink = styled(Link)`
  //  display: flex;
  //  align-items: center;
  //  width: 100%;
  //  justify-content: space-between;
  color: var(--main-text);
  &:hover {
    text-decoration: none;
    color: var(--main-text);
  }
`

const LoadingLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.4);
  cursor: inherit;
  z-index: 100;
`
const BrandText = styled.div`
  font-size: 20px;
  font-weight: 500;
  //margin-top: 10px;
  font-family: Arial, Helvetica, sans-serif;
  overflow: hidden;
`
const StyledSwitch = styled(Switch)`
  background-color: ${(props) =>
    props.checked ? secondaryGreen : secondaryNavy};

  .ant-switch-inner {
    font-size: 12px;
  }
`
