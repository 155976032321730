import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Tooltip } from 'antd'
import { numberFormatter } from 'utils/numberFormat'
import {
  PostType,
  TableChart as TableChartType,
  TableChart as TableChartNew,
  TimeInterval,
} from '../types'
import { useProjectStore } from '../../../projectStore/projectStore'
import { Filters, ProjectState, TableChartData } from '../../../types'
import _ from 'lodash'
import { useChartData } from '../../../hooks/useChartData'
import { AppliedFilterOptionRange } from '../../filters/types'
import {
  mainText,
  secondaryRed,
  tertiaryGreen,
} from '../../../../../assets/styles/variables'
import { FallOutlined, LoadingOutlined, RiseOutlined } from '@ant-design/icons'
import { alignRows, getAdjustedDateRangeString } from '../utils'
import { findDateWindow } from '../../../utils'
import {
  MaterialReactTable,
  MRT_ColumnDef,
  useMaterialReactTable,
} from 'material-react-table'
import { usePrevFilters } from '../../../hooks/usePrevFilters'

type Props = {
  currentChart: TableChartType | TableChartNew
  orderedTableData: TableChartData[]
  isAlignOrder?: boolean
  comparativeIndex?: number
  rowContextCb?: (e: React.MouseEvent, row: any) => void
  filterValues: Filters
  tier1?: string
  timeInterval?: TimeInterval
  postType: PostType
  disabled?: boolean
  isDelta?: boolean
  showPercentChange?: boolean
  pageSize: number
  tableSort: { id: string; desc: boolean }
  prevRange: AppliedFilterOptionRange | undefined
  setPrevRange: (hasPrev: AppliedFilterOptionRange) => void
  expandedCategories: string[]
  columnsVisibility: any
  updateDashboardControls: (field: string, value: any) => void
  tableState: any
  hideParentCategories: boolean
  disableGrouping?: boolean
  showCategories: boolean | undefined
}

export const NewTableChart: React.FC<Props> = React.memo(function ({
  currentChart,
  orderedTableData,
  isAlignOrder,
  comparativeIndex,
  rowContextCb,
  filterValues,
  tier1,
  timeInterval,
  postType,
  disabled,
  isDelta,
  showPercentChange,
  pageSize,
  tableSort,
  prevRange,
  setPrevRange,
  expandedCategories,
  columnsVisibility,
  updateDashboardControls,
  tableState,
  hideParentCategories,
  disableGrouping,
  showCategories,
}) {
  // TODO save and load the page size and sort order stuff
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList
  )

  const setOrderedTableData = useProjectStore(
    (state: ProjectState) => state.setOrderedTableData
  )
  const dateWindows = useProjectStore((state) => state.dateWindows)

  const { newDelta, dateFilter, prevFilters } = usePrevFilters({
    filters: filterValues,
    dateWindows,
    defaultFilterList,
    prevIsDelta: !!isDelta,
  })

  const isFirstRender = useRef(true)

  useEffect(() => {
    if (!_.isEqual(prevRange, dateFilter)) {
      setPrevRange(dateFilter as AppliedFilterOptionRange)
    }
  }, [dateFilter, prevRange])

  useEffect(() => {
    updateDashboardControls('isDelta', newDelta)
  }, [newDelta])

  const {
    data: prevChartData,
    isLoading,
    isError,
  } = useChartData(
    0,
    prevFilters,
    tier1,
    currentChart.chart_id,
    postType,
    timeInterval,
    0,
    disabled || !filterValues || !dateFilter
  )

  const prevData = prevChartData?.charts[0] as TableChartType
  let prevValues = prevData?.values ?? []
  let values = currentChart.values

  const reactiveShowCategories =
    currentChart.category_mapping &&
    (showCategories === undefined ? tier1 === 'Theme' : showCategories)

  if (
    currentChart.category_mapping &&
    currentChart.parent_values &&
    // !hideParentCategories &&
    !disableGrouping &&
    reactiveShowCategories
  ) {
    const parentValues = _.cloneDeep(currentChart.parent_values)
    parentValues.forEach((value: any, index: number) => {
      if (
        (tier1 === 'Theme' &&
          currentChart.category_mapping[value.category]?.length > 1) ||
        (tier1 !== 'Theme' &&
          currentChart.category_mapping[value.category]?.length > 0)
      ) {
        value.subRows = values.filter((el: any) =>
          currentChart.category_mapping[value.category].includes(el.category)
        )
        if (tier1 === 'Theme') {
          value.subRows = value.subRows.map((el: any) => ({
            ...el,
            category: el.category.split(': ')[1],
          }))
        }
      } else {
        value.subRows = []
      }
      value.key = index
    })
    values = parentValues

    if (prevData) {
      const prevParentValues = _.cloneDeep(prevData.parent_values)
      prevParentValues?.forEach((value: any, index: number) => {
        if (
          (tier1 === 'Theme' &&
            prevData.category_mapping[value.category]?.length > 1) ||
          (tier1 !== 'Theme' &&
            prevData.category_mapping[value.category]?.length > 0)
        ) {
          value.subRows = prevValues?.filter((el: any) =>
            prevData.category_mapping[value.category].includes(el.category)
          )
        }
        value.key = index
      })
      prevValues = prevParentValues ?? []
    }
  }

  const prevPeriodText = useMemo(() => {
    const label = findDateWindow(
      dateWindows,
      prevRange?.min,
      prevRange?.max
    )?.label

    return label
      ? `in ${label}`
      : `from ${getAdjustedDateRangeString(prevRange)}`
  }, [prevRange])

  const [activeValues, setActiveValues] = useState(values)
  const [diffValues, setDiffValues] = useState<any[]>([])

  const [orderedData, setOrderedData] = useState<any[]>(values)

  useEffect(() => {
    if (comparativeIndex === 0) {
      setOrderedData(values)
    } else {
      setActiveValues(values)
    }
  }, [currentChart, showCategories])

  useEffect(() => {
    if (comparativeIndex === 0) {
      setOrderedTableData(orderedData)
    }
  }, [orderedData])

  useEffect(() => {
    if (isAlignOrder && comparativeIndex !== 0) {
      table.resetSorting(true)
      const orderedTableMap = new Map()
      orderedTableData.forEach((row) => orderedTableMap.set(row.category, row))

      const newOrderedData = _.cloneDeep(values)
      const aligned = alignRows(orderedTableData, newOrderedData)
      // newOrderedData.sort((a, b) => {
      //   const aRow = orderedTableMap.get(a.category)
      //   const bRow = orderedTableMap.get(b.category)
      //
      //   if (!aRow && !bRow) return 0 // Both categories not found in the first list
      //   if (!aRow) return 1 // Category 'a' not found in the first list
      //   if (!bRow) return -1 // Category 'b' not found in the first list
      //
      //   // TODO sort items within subRows
      //
      //   // Compare the indices of the categories in the first list
      //   return (
      //     Array.from(orderedTableMap.values()).indexOf(aRow) -
      //     Array.from(orderedTableMap.values()).indexOf(bRow)
      //   )
      // })
      setOrderedData(aligned)
    } else if (comparativeIndex !== 0) {
      setOrderedData(values)
    }
  }, [orderedTableData, isAlignOrder, currentChart])

  useEffect(() => {
    const valuesCopy = JSON.parse(JSON.stringify(orderedData))
    const diffCopy: any[] = []
    const prevValuesObj: any = {}
    prevValues?.forEach((el: any) =>
      Object.assign(prevValuesObj, { [el.category]: el })
    )

    if (valuesCopy.length && valuesCopy.length && !isError && !isLoading) {
      valuesCopy.forEach((value: any, index: number) => {
        if (prevValuesObj[value.category]) {
          const prev = prevValuesObj[value.category]
          const diff: any = {
            category: value.category,
            npsf: (value.npsf - prev?.npsf).toFixed(2),
            rating: (value.rating - prev?.rating).toFixed(2),
            sentiment: (value.sentiment - prev?.sentiment).toFixed(2),
            volume: value.volume - prev?.volume,
            percent: (value.percent - prev?.percent).toFixed(2),
            color: value.color,
            type_: value.type_,
            subRows: [],
          }
          diff.prev_npsf = value.prev_npsf = prev?.npsf
          diff.prev_rating = value.prev_rating = prev?.rating
          diff.prev_sentiment = value.prev_sentiment = prev?.sentiment
          diff.prev_volume = value.prev_volume = prev?.volume
          diff.prev_percent = value.prev_percent = prev?.percent
          value.subRows?.forEach((nestedValue: any, index: number) => {
            const nestedPrev = prevValuesObj[value.category]?.subRows?.find(
              (el: any) => el.category.includes(nestedValue.category)
            )

            const nestedDiff: any = {
              category: nestedValue.category,
              npsf: (nestedValue.npsf - nestedPrev?.npsf).toFixed(2),
              rating: (nestedValue.rating - nestedPrev?.rating).toFixed(2),
              sentiment: (
                nestedValue.sentiment - nestedPrev?.sentiment
              ).toFixed(2),
              volume: nestedValue.volume - nestedPrev?.volume,
              percent: (nestedValue.percent - nestedPrev?.percent).toFixed(2),
              color: nestedValue.color,
              type_: nestedValue.type_,
            }
            nestedDiff.prev_npsf = nestedValue.prev_npsf = nestedPrev?.npsf
            nestedDiff.prev_rating = nestedValue.prev_rating =
              nestedPrev?.rating
            nestedDiff.prev_sentiment = nestedValue.prev_sentiment =
              nestedPrev?.sentiment
            nestedDiff.prev_volume = nestedValue.prev_volume =
              nestedPrev?.volume
            nestedDiff.prev_percent = nestedValue.prev_percent =
              nestedPrev?.percent
            diff.subRows.push(nestedDiff)
          })
          diffCopy.push(diff)
        } else {
          // todo prev should be 0, not sure if we care about these, maybe?
          diffCopy.push(value)
        }
        delete prevValuesObj[value.category]
      })
    }
    // todo not sure if we need this either - values that exist in previous but not current. ig current should be 0? show when doing delta? probably
    Object.keys(prevValuesObj).forEach((key) => {
      const prev = prevValuesObj[key]
      const diff: any = {
        category: prev.category,
        npsf: 'No Value',
        rating: 'No Value',
        sentiment: 'No Value',
        volume: 0 - prev?.volume,
        percent: (0 - prev?.percent).toFixed(2),
        color: prev.color,
        type_: prev.type_,
      }
      diff.prev_npsf = prev?.npsf
      diff.prev_rating = prev?.rating
      diff.prev_sentiment = prev?.sentiment
      diff.prev_volume = prev?.volume
      diff.prev_percent = prev?.percent
      diffCopy.push(diff)
    })

    diffCopy.sort((a: any, b: any) => b.volume - a.volume)
    setDiffValues(diffCopy)
    setActiveValues(valuesCopy)
  }, [orderedData, prevChartData])

  useEffect(() => {
    if (comparativeIndex === 0) {
      setOrderedTableData(isDelta ? diffValues : activeValues)
    }
  }, [isDelta])

  const generateValueCell = (key: string, row: any) => {
    // const key: string = 'rating'
    const value = row.original[key]
    const prev = row.original['prev_' + key]
    let percentChange = prev && (Math.round(((value - prev) / prev) * 100) || 0)
    if (isDelta) {
      percentChange = prev && (Math.round((value / prev) * 100) || 0)
    }
    if (key === 'sentiment') {
      percentChange = value - prev
    }
    if (key === 'sentiment' && isDelta) {
      percentChange = value
    }
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 20,
        }}
      >
        {!(key === 'sentiment' && isDelta) && (
          <div style={{ minWidth: 38 }}>
            {isDelta && value >= 0 && '+'}
            {numberFormatter(value)}
          </div>
        )}

        {isLoading ? (
          <LoadingOutlined />
        ) : (
          <>
            {showPercentChange &&
              !!prev &&
              dateFilter &&
              percentChange !== undefined &&
              percentChange !== Infinity && (
                <Tooltip
                  title={
                    <div>
                      {`Compared to ${numberFormatter(prev)} ${prevPeriodText}`}
                    </div>
                  }
                >
                  {value !== 'No Value' && value !== 'NaN' && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 10,
                      }}
                    >
                      <div
                        style={{
                          fontSize: '14px',
                          // lineHeight: '1.1',
                          fontWeight: '400',
                          color:
                            percentChange === 0
                              ? mainText
                              : percentChange > 0
                              ? tertiaryGreen
                              : secondaryRed,
                        }}
                      >
                        <>
                          {percentChange >= 0 && '+'}
                          {numberFormatter(percentChange)}
                          {key !== 'sentiment' && '%'}
                        </>
                      </div>
                      <div style={{ marginLeft: -5 }}>
                        {percentChange === 0 ? (
                          '-'
                        ) : percentChange > 0 ? (
                          <RiseOutlined style={{ color: tertiaryGreen }} />
                        ) : (
                          <FallOutlined style={{ color: secondaryRed }} />
                        )}
                      </div>
                    </div>
                  )}
                </Tooltip>
              )}
          </>
        )}
      </div>
    )
  }

  const columns: Array<MRT_ColumnDef<any>> = useMemo<
    MRT_ColumnDef<any>[]
  >(() => {
    const cols: any = currentChart.col_keys.map((key, index) => {
      if (key === 'category') {
        return {
          header: currentChart.col_titles[index],
          accessorKey: key,
          size: 300,
          Cell: ({ renderedCellValue, row }: any) => {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {row.original.color && (
                  <div
                    style={{
                      minWidth: '30px',
                      height: '30px',
                      backgroundColor: row.original.color,
                      marginRight: '10px',
                      borderRadius: 10,
                      marginLeft:
                        row.original.subRows ||
                        hideParentCategories ||
                        !reactiveShowCategories
                          ? 0
                          : 15,
                    }}
                  ></div>
                )}
                {renderedCellValue}
              </div>
            )
          },
        }
      } else {
        return {
          header: currentChart.col_titles[index],
          accessorKey: key,
          size: 200,
          Cell: ({ renderedCellValue, row }: any) => {
            return generateValueCell(key, row)
          },
        }
      }
    })
    return cols
  }, [isDelta ? diffValues : activeValues, showPercentChange, pageSize])

  const defaultColumnsVisibility = useMemo(() => {
    let defaultColumns: any = {}
    columns.forEach(
      (el) =>
        // @ts-ignore
        (defaultColumns[el.accessorKey] = true)
    )
    return defaultColumns
  }, [columns])

  const [columnsVisibilityState, setColumnsVisibility] = useState(
    columnsVisibility ?? defaultColumnsVisibility
  )

  const initializeExpandedCategories = () => {
    const expanded: any = {}
    expandedCategories?.forEach((cat) => {
      const rowIndex = activeValues.findIndex((el) => el.category === cat)
      expanded[rowIndex] = true
    })
    return expanded
  }

  useEffect(() => {
    if (!_.isEqual(initializeExpandedCategories(), expandedRowIds)) {
      setExpandedRowIds(initializeExpandedCategories())
    }
  }, [expandedCategories])

  const [expandedRowIds, setExpandedRowIds] = useState(
    initializeExpandedCategories()
  )

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: pageSize ?? 10,
  })

  useEffect(() => {
    setPagination({
      ...pagination,
      pageSize: pageSize ?? 10,
    })
  }, [pageSize])

  // TODO might need initialize sorting to have old configs work correctly
  const initializeTableSort = () => {
    if (!tableSort) {
      return []
    }
    // old tablesort format was { columnKey: 'category', order: 'descend'}, need to convert
    if ((tableSort as any)?.columnKey) {
      return [
        {
          id: (tableSort as any).columnKey,
          desc: (tableSort as any)?.order === 'descend',
        },
      ]
    }
    return tableSort
  }
  const [sorting, setSorting] = useState(initializeTableSort())

  useEffect(() => {
    if (!_.isEqual(tableSort ?? [], sorting)) {
      //@ts-ignore
      setSorting(initializeTableSort())
    }
  }, [tableSort])

  const table = useMaterialReactTable({
    columns: columns as any,
    data: (isDelta ? diffValues : activeValues) as any,
    enableExpandAll: true, //hide expand all double arrow in column header
    enableExpanding: !!currentChart.category_mapping,
    enableColumnResizing: true,
    filterFromLeafRows: true, //apply filtering to all rows instead of just parent rows
    getSubRows: (row) => row.subRows, //default
    initialState: tableState ? JSON.parse(tableState) : undefined,
    paginateExpandedRows: true, //When rows are expanded, do not count sub-rows as number of rows on the page towards pagination
    enableColumnOrdering: true,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        return rowContextCb ? rowContextCb(event, row) : () => {}
      },
      sx: {
        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
        backgroundColor:
          currentChart.category_mapping &&
          currentChart.parent_values &&
          !row.original.subRows &&
          !hideParentCategories &&
          reactiveShowCategories
            ? '#f7f7f7'
            : '',
      },
    }),
    // state: state,
    // manualExpanding: true,
    // getRowCanExpand: () => true, // Allow all rows to be expandable
    // onExpandedChange: setExpandedRowIds,
    // onPaginationChange: setPagination,
    // //@ts-ignore
    // onSortingChange: setSorting,
    // onColumnVisibilityChange: setColumnsVisibility,
    // @ts-ignore
    // onStateChange: setState,
  })

  // const [state, setState] = React.useState({
  //   ...table.initialState, //populate the initial state with all of the default state values from the table instance
  //   // pagination: {
  //   //   pageIndex: 0,
  //   //   pageSize: 15, //optionally customize the initial pagination state.
  //   // },
  // })
  //
  // //Use the table.setOptions API to merge our fully controlled state onto the table instance
  // table.setOptions((prev) => ({
  //   ...prev, //preserve any other options that we have set up above
  //   initialState: tableState ?? state,
  //   // state, //our fully controlled state overrides the internal state
  //   // onStateChange: setState, //any state changes will be pushed up to our own state management
  // }))
  //
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    if (!_.isEqual(tableState, JSON.stringify(table.getState()))) {
      updateDashboardControls('tableState', JSON.stringify(table.getState()))
    }
  }, [table.getState()])

  // useEffect(() => {
  //   updateDashboardControls('tableSort', table.getState().sorting)
  //   if (table.getState().sorting.length > 0) {
  //     setOrderedData(table.getSortedRowModel().rows.map((el) => el.original))
  //   }
  // }, [table.getState().sorting])
  //
  // useEffect(() => {
  //   updateDashboardControls('columnsVisibility', columnsVisibilityState)
  // }, [columnsVisibilityState])
  //
  // useEffect(() => {
  //   updateDashboardControls('pageSize', pagination.pageSize)
  // }, [pagination])
  //
  // // TODO nice to have - when aligned table is expanded, expand the same rows in the comparing table
  // // when a row is expanded, update the expanded categories dash setting
  useEffect(() => {
    const expandedCategories: string[] = []
    activeValues.map((el, index) => {
      if (
        (table.getState().expanded as Record<string, boolean>)?.[
          index.toString()
        ]
      ) {
        expandedCategories.push(el.category)
      }
    })
    updateDashboardControls('expandedCategories', expandedCategories)
  }, [table.getState().expanded])

  useEffect(() => {
    const sortedData = table
      .getRowModel()
      .rows.map((el) => el.original) as TableChartData[]
    if (comparativeIndex === 0 && !_.isEqual(sortedData, orderedData)) {
      setOrderedTableData(sortedData)
    }
  }, [table.getState().sorting])

  return (
    <div style={{ width: '100%' }}>
      <MaterialReactTable table={table} />
    </div>
  )
})
