import { Filters } from '../../types'
import { axiosInstance } from 'utils/axios'
import {
  OUTLIER_ANALYSIS_URL,
  OUTLIERS_URL,
  TIMESERIES_OUTLIERS_URL,
} from 'utils/apiLinks'
import {
  AppliedFilterOption,
  AppliedFilterOptionRange,
} from 'features/project/features/filters/types'

export type OutlierResponse = {
  uuid: string
  metric: string
  create_time: number
  metric_score: number
  volume: number
  weighted_zscore: number
  weighted_metric_score: number
  mean_metric_score: number
  mean_weighted_metric_score: number
  date_range: { start_date: number; end_date: number }
  filter: {
    criteria: AppliedFilterOption[]
    search_criteria: string[]
    search_terms: string[]
  }
  // matrix fields
  theme?: string
  context_with_value?: Context
  // timeseries fields
  // TODO : 'half' is an option, but moment doesn't accept it
  aggregation?: 'day' | 'week' | 'month' | 'quarter' | 'half' | 'year'
  date?: string
  window_start_date?: string
  // added field for grouping
  dateRanges?: string[]
}

export type OutlierPostTextBody = {
  post_text: string
  sentiment: number
}

export type OutlierPostBody = {
  body: string
  brand: string
  product: string
  date: number
  score: number
  texts: OutlierPostTextBody[]
}

export type Context = {
  context: string
  value: any
}

export type DateRange = {
  start_date: number
  end_date: number
}

export type OutlierType = {
  type: string
  analysis?: OutlierAnalysisType
} & OutlierResponse

export type AnalysisSection = {
  title: string
  explanation: string
  evidence: string
  implication: string
}

export type OutlierAnalysisType = {
  stinger: string
  header: string
  subheader: string
  key_phrases: string[]
  body: AnalysisSection[]
}
// Function to process the list of OutlierResponse records
function processOutliers(outliers: OutlierResponse[]) {
  // Map to hold grouped outliers
  const groupedOutliers = new Map<string, any>()

  outliers.forEach((outlier) => {
    const theme = outlier.theme || ''
    const context = outlier.context_with_value?.context || ''
    const value = outlier.context_with_value?.value || ''

    // Unique key for grouping
    const key = `${theme}||${context}||${value}`

    if (!groupedOutliers.has(key)) {
      groupedOutliers.set(key, {
        ...outlier,
        dateRanges: new Set<string>(),
      })
    } else {
      // If the current outlier has an earlier start date, update the group
      if (
        outlier.date_range.start_date <
        groupedOutliers.get(key).date_range.start_date
      ) {
        const currentOutlier = groupedOutliers.get(key)
        groupedOutliers.set(key, {
          ...currentOutlier,
          ...outlier,
        })
      }
    }

    // Extract date ranges from filter criteria
    const dateRanges = getDateRangesFromOutlier(outlier)

    // Add date ranges to the group
    const group = groupedOutliers.get(key)
    dateRanges.forEach((dr) => group?.dateRanges.add(dr))
  })

  console.log(groupedOutliers)

  // Convert the Map to an array of results
  const result = Array.from(groupedOutliers.values()).map((group) => {
    return {
      ...group,
      dateRanges: Array.from(group.dateRanges),
    }
  })

  return result
}

// Function to extract date ranges from an outlier
function getDateRangesFromOutlier(outlier: OutlierResponse): string[] {
  const dateRanges: string[] = []

  // @ts-ignore active ranges was added previously to the obj
  // for (const range of outlier.active_ranges) {
  const dateRangeLabel = getDateRangeLabel(
    outlier.date_range.start_date,
    outlier.date_range.end_date,
  )
  dateRanges.push(dateRangeLabel)
  // }

  return dateRanges
}

// Function to convert min and max timestamps to human-readable labels
function getDateRangeLabel(minUnix: number, maxUnix: number): string {
  const diffSeconds = maxUnix - minUnix
  const diffDays = diffSeconds / (60 * 60 * 24)

  // Determine the label based on the duration
  if (diffDays <= 7) {
    return 'week'
  } else if (diffDays <= 31) {
    return 'month'
  } else if (diffDays <= 92) {
    return 'quarter'
  } else if (diffDays <= 183) {
    return 'half'
  } else {
    return 'year'
  }
}

export const getOutliers = async (
  proj_uuid: string,
  filterValues: Filters,
): Promise<OutlierResponse[]> => {
  const { data } = await axiosInstance.post(
    OUTLIERS_URL,
    {
      criteria: filterValues.values,
      search_terms: filterValues.searchQuery,
      search_criteria: filterValues.searchCondition,
      // context: 'brand',
    },
    { params: { proj_uuid } },
  )

  const processedOutliers = processOutliers(data)
  // console.log(processedOutliers)
  return processedOutliers
}

export const getTimeSeriesOutliers = async (
  proj_uuid: string,
  filterValues: Filters,
): Promise<OutlierResponse[]> => {
  const { data } = await axiosInstance.post(
    TIMESERIES_OUTLIERS_URL,
    {
      criteria: filterValues.values,
      search_terms: filterValues.searchQuery,
      search_criteria: filterValues.searchCondition,
    },
    { params: { proj_uuid } },
  )
  return data
}

export const getOutlierAnalysis = async (
  proj_uuid: string,
  outlier: OutlierType,
  filter: {
    criteria: AppliedFilterOption[]
    search_criteria: string[]
    search_terms: string[]
  },
  model: string,
  user_prompt?: string,
): Promise<OutlierType> => {
  if (!!outlier.analysis) return outlier
  const { data } = await axiosInstance.post(
    OUTLIER_ANALYSIS_URL,
    {
      outlier,
      filter,
      model,
      prompt_override: user_prompt,
    },
    { params: { proj_uuid } },
  )
  // try to parse, throw error if not parseable?
  try {
    return { ...outlier, analysis: JSON.parse(data) }
  } catch (e) {
    // console.log('Error parsing analysis data', e)
    // console.log(data)
    throw e
  }
}
