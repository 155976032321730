import mixpanel from 'features/trackers/mixpanel'
import { env, isProd } from 'utils/axios'
import * as Sentry from '@sentry/react'

export function runTrackers() {
  if (isProd) {
    mixpanel.init()
    window.$crisp = []
    window.CRISP_WEBSITE_ID = 'bc7ae60d-7ac3-46be-b38e-dca892f66fcd'
    ;(function () {
      var d = document
      var s = d.createElement('script')
      s.src = 'https://client.crisp.chat/l.js'
      s.async = 1
      d.getElementsByTagName('head')[0].appendChild(s)
    })()
  }
  if (env !== 'local') {
    Sentry.init({
      dsn: 'https://4f92db334277daaa319ed99cece7da16@o4505937448468480.ingest.sentry.io/4505937451548672',
      integrations: [
        // new Sentry.BrowserTracing({
        //   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        //
        // }),
        // const pluralize = require('pluralize')layIntegration()
      ],
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/api\.meetyogi\.com/,
        /^https:\/\/api-dev\.meetyogi\.com/,
        /^https:\/\/api-staging\.meetyogi\.com/,
      ],
      environment: env,
      // Performance Monitoring
      tracesSampleRate: env === 'prod' ? 0.1 : env === 'local' ? 0.0 : 1.0, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate:
        env === 'prod' ? 0.1 : env === 'local' ? 0.0 : 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
  }
}
