import { FilterOption, FilterTypes } from '../../../../../../types'
import Divider from '../../../../../../../../../../components/UI/YogiDivider'
import { Input, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import React from 'react'
import styled from 'styled-components'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { ProjectState } from 'features/project/types'
import { useFeatureFlags } from 'features/project/hooks/useFeatureFlags'

type Props = {
  option: FilterOption
  search: string
  setSearch: (value: string) => void
}

export const SelectorHeader: React.FC<Props> = ({
  option,
  search,
  setSearch,
}) => {
  const { data: feature_flags } = useFeatureFlags()
  return (
    <>
      {option?.description && (
        <FilterOptionDescription>{option?.description}</FilterOptionDescription>
      )}
      {option.field !== 'score' && option.field !== 'sentiment' ? (
        <>
          <Description>
            {/*{option.filter_type === FilterTypes.SELECT &&*/}
            {/*option.values.length === 0*/}
            {/*  ? 'Too many values to display.'*/}
            {/*  : 'Select one or more options to filter.'}*/}

            {option.field === 'proj_uuid' && feature_flags?.superset ? (
              <div>Select up to a maximum of 5 Datasets.</div>
            ) : (
              <>
                {option.filter_type === FilterTypes.SELECT &&
                option.values.length === 0
                  ? 'Too many values to display.'
                  : 'Select one or more options to filter.'}
              </>
            )}

            {option.filter_type === FilterTypes.SELECT &&
              option.values.length > 10 && (
                <StyledInput
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search for option..."
                />
              )}
          </Description>
          <Divider />
        </>
      ) : (
        <>
          <Description>
            Select an option
            {option.field === 'score' ? (
              <Tooltip
                title={
                  'Choose Reviews Only for reviews with text, Ratings Only for ratings without text, or Reviews & Ratings for all reviews and ratings.'
                }
              >
                <InfoCircleOutlined style={{ marginLeft: '10px' }} />
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  'Choose Review Sentiment to filter for reviews with a particular overall sentiment value, or Sentence Sentiment to filter for sentences with a particular sentiment value. A review can have a mix of positive, negative, and neutral sentiment sentences. Note that the numbers next to the options are the counts of reviews not sentences.'
                }
              >
                <InfoCircleOutlined style={{ marginLeft: '10px' }} />
              </Tooltip>
            )}
          </Description>
          <Divider />
        </>
      )}
    </>
  )
}
export default SelectorHeader

const FilterOptionDescription = styled.div`
  font-weight: 300;
  color: #0443ae;
  font-style: italic;
  word-wrap: normal;
  max-width: 600px;
`
const Description = styled.div`
  font-weight: 300;
  margin: 20px 20px 10px;
`
const StyledInput = styled(Input)`
  border-radius: 6px;

  &:placeholder-shown {
    font-style: italic;
  }
`
