import React, { useEffect, useState } from 'react'
// import { ReactComponent as BackupLogo } from 'assets/images/File-Logo.svg?react'

type Props = { source: string; style?: Object; className?: string }

export const SourceIcon: React.FC<Props> = ({ source, ...cssProps }: Props) => {
  // syndication
  source = source === 'syndicated' ? 'Shop' : source

  //foreigner source groups
  for (let fsg of [
    'HarveyNorman',
    'MercadoLibre',
    'ElGiganten',
    'MediaMarkt',
    'OfficeDepot',
    'Komplett',
    'Petsmart',
    'Euronics',
    'Sephora',
    'Walmart',
    'Watsons',
    'BestBuy',
    'Staples',
    'Staples',
    'Keurig',
    'Amazon',
    'Lazada',
    'Costco',
    'Shopee',
    'PBTech',
    'LLBean',
    'Naver',
    'Power',
    'Kmart',
    'Alza',
    'FNAC',
    'JD',
  ]) {
    if (source?.includes(fsg) && source !== 'PowerReviews') {
      source = fsg
      break
    }
  }

  let ref = React.useRef<React.FC<React.SVGProps<SVGSVGElement>>>()
  let [ready, setReady] = useState(false)
  useEffect(() => {
    setReady(false)
    ;(async () => {
      try {
        let a = await import(
          /* @vite-ignore */
          `!!@svgr/webpack?-svgo,+titleProp,+ref!assets/images/${source}-Logo.svg`
        )
        Object.defineProperty(
          a,
          'ReactComponent',
          Object.getOwnPropertyDescriptor(a, 'default')!
        )
        ref.current = a.ReactComponent
        setReady(true)
      } catch (e) {
        ref.current = undefined
      }
    })()
  }, [source])

  if (ready && ref.current) {
    const Icon: React.FC<React.SVGProps<SVGSVGElement>> = ref.current
    return <Icon {...cssProps} />
  }
  // return <BackupLogo {...cssProps} />
}
