import React, { useCallback } from 'react'
import { Control, Controller, FieldErrors } from 'react-hook-form'
import { ErrorMessage, Section, StyledInput } from '../CreateAlert.styles'
import { AlertType } from '../../../model'
import mixpanel from '../../../../../../trackers/mixpanel'
import { debounce } from 'lodash'
import { useProjectStore } from '../../../../../projectStore/projectStore'
import { ProjectState } from '../../../../../types'

interface Props {
  control: Control<AlertType>
  errors: FieldErrors<AlertType>
}

const AlertName: React.FC<Props> = ({ control, errors }) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  const debouncedMixpanelTrack = useCallback(
    debounce((action, value) => {
      mixpanel.track('alerts', {
        action: action,
        value: value,
        proj_uuid: projectId,
      })
    }, 1000),
    [projectId]
  )

  return (
    <Section>
      <h3>Name Your Alert</h3>
      <Controller
        name="title"
        control={control}
        rules={{ required: 'Title is required' }}
        render={({ field }) => (
          <StyledInput
            {...field}
            onChange={(e) => {
              field.onChange(e.target.value)
              debouncedMixpanelTrack('title', e.target.value)
            }}
            placeholder="Alert Title"
            aria-label="Alert Title"
            isError={!!errors.title}
          />
        )}
      />
      {!!errors.title && (
        <ErrorMessage style={{ paddingLeft: 8, marginTop: 3 }}>
          Please name your alert!
        </ErrorMessage>
      )}
    </Section>
  )
}

export default AlertName
