import React from 'react'
import { Button, ButtonProps } from 'antd'
import styled from 'styled-components'
import {
  buttonBlue,
  buttonHover,
  secondaryNavy,
  secondaryRed,
} from 'assets/styles/variables'

export const YogiButton: React.FC<ButtonProps> = ({
  children,
  onClick,
  type,
  icon,
  id,
  disabled,
  style,
  htmlType,
  shape,
  danger,
  loading,
}) => {
  return (
    <StyledButton
      id={id}
      type={type}
      icon={icon}
      onClick={onClick}
      disabled={disabled}
      style={style}
      htmlType={htmlType}
      shape={shape}
      loading={loading}
    >
      {children}
    </StyledButton>
  )
}

interface AdditionalStyles {
  background: string
  hover: string
  color: string
  border: string
}

interface StyledButtonProps extends ButtonProps {
  additionalstyles?: AdditionalStyles
}

const getAdditionalStyles = (props: StyledButtonProps): AdditionalStyles => {
  if (props.danger) {
    return {
      background: secondaryRed,
      hover: secondaryRed,
      color: 'white',
      border: secondaryRed,
    }
  }
  if (props.type === 'primary') {
    return {
      background: buttonBlue,
      hover: buttonHover,
      color: 'white',
      border: buttonBlue,
    }
  }
  if (props.type === 'dashed') {
    return {
      background: 'white',
      hover: 'white',
      color: buttonBlue,
      border: '#D9D9D9',
    }
  }
  return {
    background: 'white',
    hover: buttonBlue,
    color: buttonBlue,
    border: secondaryNavy,
  }
}

const StyledButton = styled(Button).attrs<StyledButtonProps>((props) => ({
  additionalstyles: getAdditionalStyles(props),
}))<StyledButtonProps>`
  border-radius: 8px;
  border-width: 2px;
  background: ${(props) => props.additionalstyles?.background};
  color: ${(props) => props.additionalstyles?.color};
  border-color: ${(props) => props.additionalstyles?.border};
  border-style: solid;

  &:hover {
    border-color: ${(props) =>
      props.type === 'dashed'
        ? buttonBlue
        : props.additionalstyles?.hover}!important;
    color: ${(props) =>
      props.type === 'dashed' ? buttonBlue : 'white'}!important;
    background: ${(props) => props.additionalstyles?.hover}!important;
    box-shadow: 0px 12px 24px 0px #1d4ed852 !important;
    box-shadow: 0px 2px 4px 0px #3b82f61f !important;
  }

  &:focus {
    border-color: ${buttonBlue};
    color: ${(props) => (props.type === 'dashed' ? buttonBlue : 'white')};
    background: ${(props) => props.additionalstyles?.hover};
  }
`
