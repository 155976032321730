import React, { useContext } from 'react'
import styled from 'styled-components'
import { Loader } from 'shared/components'
import { AuthContext } from 'features/auth'
import { ProjectCard, ProjectCardNew } from './'
import { Project } from '../../project/types'

export type Props = {
  handleClickNew: () => void
  handleRemoveProject: (a: string) => void
  loading: boolean
  projects: Project[] | null
}

export const ProjectList = ({
  projects,
  loading,
  handleRemoveProject,
  handleClickNew,
}: Props) => {
  const authContext = useContext(AuthContext)

  if (loading || !projects) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    )
  }

  //https://codesandbox.io/s/intelligent-wildflower-fbo1c?file=/index.js
  return (
    <ProjectsWrapper>
      <List>
        {authContext.userDetails?.role === 'admin' && (
          <ProjectWrapper onClick={handleClickNew}>
            <ProjectCardNew />
          </ProjectWrapper>
        )}
        {projects.map((project: Project) => (
          <ProjectWrapper key={project.uuid}>
            <ProjectCard
              project={project}
              handleRemoveProject={handleRemoveProject}
            />
          </ProjectWrapper>
        ))}
      </List>
    </ProjectsWrapper>
  )
}

const LoaderWrapper = styled.div`
  padding-top: 50px;
  display: flex;
  justify-content: center;
`
const ProjectsWrapper = styled.div``

const List = styled.div`
  display: flex;
  gap: var(--default-padding);
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`
const ProjectWrapper = styled.div`
  flex-basis: calc(33.333% - var(--default-padding));
  max-width: calc(33.333% - var(--default-padding));
  //padding: 0 var(--default-padding-half) var(--default-padding);
  display: flex;
  flex-grow: 0;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`
