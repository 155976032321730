import React from 'react'
import { FormikProps } from 'formik'
import styled from 'styled-components'
import { Form, Input, Button, Select, Checkbox } from 'antd'
import { FormTitle } from 'shared/components'
import { CardNew } from './card-new'
import { FormValues } from '../project-new-form'
import { SourceCard } from '../features/source/components'
import { ProjectSourceFile, ProjectSourceURL } from '../features/source/types'
import { Product } from '../features/product/types'

type Props = {
  handleOpenSource: () => void
  handleOpenProduct: () => void
  formik: FormikProps<FormValues>
  isSubmitDisabled: boolean
  sources: Array<ProjectSourceFile | ProjectSourceURL>
  products: Array<Product>
  stopWords: string[]
  setStopWords: (s: string[]) => void
  setSourceToEditIndex: (n: number) => void
  setProductToEditIndex: (n: number) => void
}

export const NewProject = ({
  formik,
  sources,
  products,
  handleOpenSource,
  handleOpenProduct,
  isSubmitDisabled,
  stopWords,
  setStopWords,
  setSourceToEditIndex,
  setProductToEditIndex,
}: Props) => {
  return (
    <Wrapper>
      <FormTitle>Add New Project</FormTitle>
      <Form.Item
        label="Title"
        validateStatus={!!formik.errors.title ? 'error' : ''}
        help={formik.errors.title}
        wrapperCol={{ span: 10 }}
      >
        <Input
          name="title"
          onChange={formik.handleChange}
          value={formik.values.title}
        />
      </Form.Item>
      <Form.Item
        label="Description"
        validateStatus={!!formik.errors.description ? 'error' : ''}
        help={formik.errors.description}
        wrapperCol={{ span: 16 }}
      >
        <Input
          name="description"
          onChange={formik.handleChange}
          value={formik.values.description}
        />
      </Form.Item>
      <Form.Item label="Stop Words" wrapperCol={{ span: 16 }}>
        <Select
          mode="tags"
          style={{ width: '100%' }}
          value={stopWords}
          onChange={(s: string[]) =>
            setStopWords(s.filter((word) => word.trim().length > 0))
          }
          tokenSeparators={[',']}
        />
      </Form.Item>
      <Form.Item label="Products" wrapperCol={{ span: 16 }}>
        <Sources>
          {formik.values.title.length === 0 && <Layer />}
          <SourceCardWrapper onClick={handleOpenProduct}>
            <CardNew text={'Add Product'} />
          </SourceCardWrapper>
          {products.map((product, index) => (
            <SourceCardWrapper key={product.id}>
              <SourceCard
                icon={product.name[0]}
                title={product.name}
                onClick={() => {
                  handleOpenProduct()
                  setProductToEditIndex(index)
                }}
              />
            </SourceCardWrapper>
          ))}
        </Sources>
      </Form.Item>
      <Form.Item
        label="Sources"
        wrapperCol={{ span: 16 }}
        validateStatus={!!formik.errors.sources ? 'error' : ''}
        // help={formik.errors.sources}
      >
        <Sources>
          {(formik.values.title.length === 0 || products.length === 0) && (
            <Layer />
          )}
          <SourceCardWrapper onClick={handleOpenSource}>
            <CardNew />
          </SourceCardWrapper>
          {sources.map((source, index) => (
            <SourceCardWrapper key={index}>
              <SourceCard
                error={!!formik.errors.sources && !source.product_id}
                icon={source.source === 'File' ? 'CSV' : 'URL'}
                title={source.title}
                onClick={() => {
                  handleOpenSource()
                  setSourceToEditIndex(index)
                }}
              />
            </SourceCardWrapper>
          ))}
        </Sources>
      </Form.Item>

      <Form.Item style={{ marginTop: 'auto', marginBottom: 0 }}>
        <SubmitButton
          disabled={isSubmitDisabled}
          type="primary"
          htmlType="submit"
        >
          Submit
        </SubmitButton>
      </Form.Item>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 800px;
  height: 600px;
  overflow: auto;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1024px) {
    width: auto;
    min-width: 300px;
  }
`
const SubmitButton = styled(Button)`
  display: block;
  margin: var(--default-padding-half) auto 0;
`
const Sources = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  position: relative;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`
const SourceCardWrapper = styled.div`
  width: 50%;
  padding: 10px;
  display: flex;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`
const Layer = styled.div`
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
`
