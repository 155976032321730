export const sourceTypes: { [index: string]: string } = {
  '11st': 'http://www.11st.co.kr/',
  '91Wheels': 'https://www.91wheels.com/',
  'Apple Appstore': 'https://apps.apple.com/',
  'BedBath&Beyond': 'https://www.bedbathandbeyond.com/',
  'E-HimartKorea': 'https://www.e-himart.co.kr/',
  'Google Playstore': 'https://play.google.com/',
  'JB Hi-FiAustralia': 'https://www.jbhifi.com.au/',
  'Re:amaze': 'https://www.reamaze.com/',
  'T-Mobile': 'https://www.t-mobile.com/',
  'Vatan Bilgisayar': 'https://www.vatanbilgisayar.com/',
  'Yamada-Denkiweb': 'https://www.yamada-denkiweb.com/',
  Academy: 'https://www.academy.com/',
  AceHardware: 'https://www.acehardware.com/',
  AE: 'https://www.ae.com/',
  Albertsons: 'https://www.albertsons.com/',
  Alkosto: 'https://www.alkosto.com/',
  AlternateGermany: 'https://www.alternate.de/',
  AlzaCzechRepublic: 'https://www.alza.cz/',
  AlzaSlovakia: 'https://www.alza.sk/',
  Amazon: 'https://www.amazon.com/', // Needs the www
  AmazonUS: 'https://www.amazon.com/',
  AmericanasBrazil: 'https://www.americanas.com.br/',
  Amway: 'https://www.amway.com/',
  Anthropologie: 'https://www.anthropologie.com/',
  AO: 'https://ao.com/',
  ApoDiscounter: 'https://www.apodiscounter.de/',
  Argos: 'https://www.argos.co.uk/',
  Asda: 'https://www.asda.com/',
  AuchanFrance: 'https://www.auchan.fr/',
  AutoZone: 'https://www.autozone.com/',
  BassPro: 'https://www.basspro.com/',
  BathandBodyWorks: 'https://www.bathandbodyworks.com/',
  Bazaarvoice: 'https://www.bazaarvoice.com/',
  Beautylish: 'https://www.beautylish.com/',
  BellItalia: 'https://www.bell-italia.com/',
  BestBuy: 'https://www.bestbuy.com/',
  BestBuyCanada: 'https://www.bestbuy.ca/',
  Bhinneka: 'https://www.bhinneka.com/',
  Biccamera: 'https://www.biccamera.com/',
  BikeWale: 'https://www.bikewale.com/',
  BizrateSurveys: 'https://bizratesurveys.com/',
  BJS: 'https://www.bjs.com/',
  BliBli: 'https://www.blibli.com/',
  Bloomingdales: 'https://www.bloomingdales.com/',
  Bluemercury: 'https://bluemercury.com/',
  BOL: 'https://www.bol.com/',
  Boots: 'https://www.boots.com/',
  Boulanger: 'https://www.boulanger.com/',
  Brack: 'https://www.brack.ch/',
  BranchBasics: 'https://www.branchbasics.com/',
  Breeo: 'https://breeo.co/',
  Build: 'https://www.build.com/',
  BuyBuyBaby: 'https://www.buybuybaby.com/',
  CanadianTire: 'https://www.canadiantire.ca/',
  Carethy: 'https://au.carethy.net/',
  Carrefour: 'https://www.carrefour.fr/',
  CasasBahiaBrazil: 'https://www.casasbahia.com.br/',
  Cdiscount: 'https://www.cdiscount.com/',
  Cefarm24: 'https://www.cefarm24.pl/',
  Ceneo: 'https://www.ceneo.pl/',
  CharlotteTilbury: 'https://www.charlottetilbury.com/',
  ChemistWarehouse: 'https://www.chemistwarehouse.com.au/',
  Chewy: 'https://www.chewy.com/',
  Coach: 'https://www.coach.com/',
  CoachOutlet: 'https://www.coachoutlet.com/',
  Coles: 'https://shop.coles.com.au/',
  ConsumerAffairs: 'https://www.consumeraffairs.com/',
  CoolBlueGermany: 'https://www.coolblue.de/',
  CoolBlueNetherlands: 'https://www.coolblue.nl/',
  Costco: 'https://www.costco.com/',
  CostcoCanada: 'https://www.costco.ca/',
  CostcoJapan: 'https://www.costco.co.jp/',
  CostcoMexico: 'https://www.costco.com.mx/',
  Coupang: 'https://www.coupang.com/',
  CreditKarma: 'https://www.creditkarma.com/',
  Croma: 'https://www.croma.com/',
  CruiseCritic: 'https://www.cruisecritic.com/',
  Currys: 'https://www.currys.co.uk/',
  CVS: 'https://www.cvs.com/',
  CyberportGermany: 'https://www.cyberport.de/',
  CZC: 'https://www.czc.cz/',
  Darty: 'https://www.darty.com/',
  DatArt: 'https://www.datart.cz/',
  Dermstore: 'https://www.dermstore.com/',
  DicksSportingGoods: 'https://www.dickssportinggoods.com/',
  DigitecSwitzerland: 'https://www.digitec.ch/',
  Dillards: 'https://www.dillards.com/',
  DM: 'https://www.dm.de/',
  DocMorris: 'https://www.docmorris.de/',
  DollarGeneral: 'https://www.dollargeneral.com/',
  Drizly: 'https://www.drizly.com/',
  DrogaRaia: 'https://www.drogaraia.com.br/',
  Drogasil: 'https://www.drogasil.com.br/',
  Dyson: 'https://www.dyson.co.kr/',
  Ebay: 'https://www.ebay.com/',
  EC30clean: 'https://www.ec30clean.com/',
  EddieBauer: 'https://www.eddiebauer.com/',
  Edion: 'https://www.edion.com/',
  ElCorteInglesSpain: 'https://www.elcorteingles.es/',
  ElGigantenDenmark: 'https://www.elgiganten.dk/',
  ElGigantenSweden: 'https://www.elgiganten.se/',
  ElkjopNorway: 'https://www.elkjop.no/',
  Emplifi: 'https://api.turnto.com/',
  eSalon: 'https://www.esalon.com/',
  Etsy: 'https://www.etsy.com/',
  Euronics: 'https://www.euronics.it/',
  EuronicsCzechRepublic: 'https://www.euronics.cz/',
  EuronicsUK: 'https://www.euronics.co.uk/',
  EuroPoland: 'https://www.euro.com.pl/',
  Everist: 'https://www.helloeverist.com/',
  Everlane: 'https://www.everlane.com/',
  EveryManJack: 'https://www.everymanjack.com/',
  Exito: 'https://www.exito.com/',
  Expedia: 'https://www.expedia.com/',
  eZikoapteka: 'https://www.e-zikoapteka.pl/',
  Facebook: 'https://www.facebook.com/',
  Falabella: 'https://www.falabella.com/',
  FastShopBrazil: 'https://www.fastshop.com.br/',
  FeelUnique: 'https://www.feelunique.com/',
  FirstChoiceLiquor: 'https://www.firstchoiceliquor.com.au/',
  Flipkart: 'https://www.flipkart.com/',
  FNAC: 'https://www.fnac.com/',
  FNACBelgium: 'https://www.fr.fnac.be',
  FNACPortugal: 'https://www.fnac.pt/',
  FNACSpain: 'https://www.fnac.es/',
  Fragrantica: 'https://www.fragrantica.com/',
  FrogCoffee: 'https://frogcoffee.de/',
  GamePlanet: 'https://gameplanet.com/',
  GameSpain: 'https://www.game.es/',
  GameStop: 'https://www.gamestop.com/',
  GiganttiFinland: 'https://www.gigantti.fi/',
  GlooMalaysia: 'https://www.gloo.com.my/',
  GmarketKorea: 'http://item.gmarket.co.kr/',
  GNC: 'https://www.gnc.com/',
  GolfGalaxy: 'https://www.golfgalaxy.com/',
  Goodreads: 'https://www.goodreads.com/',
  GoogleCustomerReviews: 'https://customerreviews.google.com/',
  GoogleMaps: 'https://www.google.com/maps/',
  GoogleShopping: 'https://www.google.com/shopping/',
  Greenist: 'https://www.greenist.de/',
  Grove: 'https://www.grove.co/',
  GuitarCenter: 'https://www.guitarcenter.com/',
  Halfords: 'https://www.halfords.com/',
  HappiestBaby: 'https://www.happiestbaby.com/',
  HappyPops: 'https://happypops.ca/',
  HarveyNormanAustralia: 'https://www.harveynorman.com.au/',
  HarveyNormanIreland: 'https://www.harveynorman.ie/',
  HarveyNormanMalaysia: 'https://www.harveynorman.com.my/',
  HarveyNormanNewZealand: 'https://www.harveynorman.co.nz/',
  HarveyNormanSingapore: 'https://www.harveynorman.com.sg/',
  HelloProducts: 'https://www.hello-products.com.au/',
  Hepsiburada: 'https://www.hepsiburada.com/',
  Hills: 'https://www.hillspet.co.uk/',
  HomeDepot: 'https://www.homedepot.com/',
  HomeHardware: 'https://www.homehardware.ca/',
  Houzz: 'https://www.houzz.com/',
  HP: 'https://www.hp.com/us-en/',
  HPFrance: 'https://www.hp.com/fr-fr/',
  HPGermany: 'https://www.hp.com/de-de/',
  HPIndia: 'https://www.hp.com/in-en/',
  HPKorea: 'https://www.hp.com/kr-ko/',
  HPMexico: 'https://www.hp.com/mx-es/',
  HPUK: 'https://www.hp.com/gb-en/',
  HumanAndKind: 'https://www.humanandkind.com/',
  iHerb: 'https://www.iherb.com/',
  Incredible: 'https://www.incredible.co.za/',
  Influenster: 'https://www.influenster.com/',
  InsectShield: 'https://www.insectshield.com/',
  Instagram: 'https://www.instagram.com/',
  InterdiscountSwitzerland: 'https://www.interdiscount.ch/',
  InternationalDelight: 'https://www.internationaldelight.com/',
  JacobGermany: 'https://www.jacob.de/',
  Jarir: 'https://www.jarir.com/',
  JCrew: 'https://www.jcrew.com/',
  JD: 'https://item.jd.com/',
  JDHongKong: 'https://npcitem.jd.hk/',
  JeanCoutu: 'https://www.jeancoutu.com/',
  Jiomart: 'https://www.jiomart.com/',
  JohnLewis: 'https://www.johnlewis.com/',
  JoshinWeb: 'https://joshinweb.jp/',
  KalungaBrazil: 'https://www.kalunga.com.br/',
  KateSpade: 'https://www.katespade.com/',
  Kaufland: 'https://www.kaufland.de/',
  Keurig: 'https://www.keurig.com/',
  KeurigCommercial: 'https://commercial.keurig.com/',
  Kiehls: 'https://www.kiehls.com/',
  KmartAustralia: 'https://www.kmart.com.au/',
  Kohler: 'https://www.kohler.com/',
  Kohls: 'https://www.kohls.com/',
  KomplettDenmark: 'https://www.komplett.dk/',
  KomplettNorway: 'https://www.komplett.no/',
  KomplettSweden: 'https://www.komplett.se/',
  Kroger: 'https://www.kroger.com/',
  KSdenki: 'https://www.ksdenki.com/',
  LazadaIndonesia: 'https://www.lazada.co.id/',
  LazadaMalaysia: 'https://www.lazada.com.my/',
  LazadaPhilipines: 'https://www.lazada.com.ph/',
  LazadaSingapore: 'https://www.lazada.sg/',
  LazadaThailand: 'https://www.lazada.co.th/',
  LazadaVietnam: 'https://www.lazada.vn/',
  Leclerc: 'https://www.e.leclerc/',
  LeCreuset: 'https://www.lecreuset.com/',
  Lelong: 'https://www.lelong.com.my/',
  Littlewoods: 'https://www.littlewoods.com/',
  LiverpoolMexico: 'https://www.liverpool.com.mx/',
  LLBean: 'https://global.llbean.com/',
  LLBeanUS: 'https://www.llbean.com/',
  Loccitane: 'https://www.loccitane.com/',
  Lohaco: 'https://lohaco.yahoo.co.jp/',
  LondonDrugs: 'https://www.londondrugs.com/',
  Lowes: 'https://www.lowes.com/',
  Macys: 'https://www.macys.com/',
  MagazineLuizaBrazil: 'https://www.magazineluiza.com.br/',
  MediaExpert: 'https://www.mediaexpert.pl/',
  MediaMarkt: 'https://www.mediamarkt.de/',
  MediaMarktAustria: 'https://www.mediamarkt.at/',
  MediaMarktBelgium: 'https://www.mediamarkt.be/',
  MediaMarktHungary: 'https://www.mediamarkt.hu',
  MediaMarktNetherlands: 'https://www.mediamarkt.nl/',
  MediaMarktPoland: 'https://mediamarkt.pl/',
  MediaMarktPortugal: 'https://www.mediamarkt.pt/',
  MediaMarktSpain: 'https://www.mediamarkt.es/',
  MediaMarktSweden: 'https://www.mediamarkt.se/',
  MediaMarktSwitzerland: 'https://www.mediamarkt.ch/',
  MediaMarktTurkey: 'https://www.mediamarkt.com.tr/',
  MediaWorldItaly: 'https://www.mediaworld.it/',
  MercadoLibre: 'https://www.mercadolibre.com.mx/',
  MercadoLibreBrazil: 'https://www.mercadolivre.com.br/',
  MichaelKors: 'https://www.michaelkors.com/',
  Microcenter: 'https://www.microcenter.com/',
  MicromaniaFrance: 'https://www.micromania.fr/',
  Morrisons: 'https://groceries.morrisons.com/',
  MuscleAndStrength: 'https://www.muscleandstrength.com/',
  MvideoRussia: 'https://www.mvideo.ru/',
  Myntra: 'https://www.myntra.com/',
  Naver: 'https://search.shopping.naver.com/',
  NaverSmartstore: 'https://smartstore.naver.com/',
  Nespresso: 'https://www.nespresso.com/',
  Newegg: 'https://www.newegg.com/',
  NewtonBaby: 'https://www.newtonbaby.com/',
  NoelLeemingNewZealand: 'https://www.noelleeming.co.nz/',
  Nordstrom: 'https://www.nordstrom.com/',
  NordstromRack: 'https://www.nordstromrack.com/',
  NotebooksbilligerGermany: 'https://www.notebooksbilliger.de/',
  NowaFarmacja: 'https://www.nowafarmacja.pl/',
  Nykaa: 'https://www.nykaa.com/',
  Ocado: 'https://www.ocado.com/',
  OfficeDepot: 'https://www.officedepot.com/',
  OfficeDepotMexico: 'https://www.officedepot.com.mx/',
  OfficeWorksAustralia: 'https://www.officeworks.com.au/',
  Okusurinavi: 'https://okusurinavi.shop/',
  Otto: 'https://www.otto.de/',
  Ozon: 'https://www.ozon.ru/',
  Paris: 'https://www.paris.cl/',
  PBTechGlobal: 'https://www.pbtech.com/',
  PBTechNewZealand: 'https://www.pbtech.co.nz/',
  Peloton: 'https://www.onepeloton.com/',
  Petco: 'https://www.petco.com/',
  Petflow: 'https://www.petflow.com/',
  PetPlanet: 'https://www.petplanet.co.uk/',
  Petsmart: 'https://www.petsmart.com/',
  PetsmartCanada: 'https://www.petsmart.ca/',
  PetSupermarket: 'https://www.pet-supermarket.co.uk/',
  PetSuppliesPlus: 'https://www.petsuppliesplus.com/',
  PGATOURSuperStore: 'https://www.pgatoursuperstore.com/',
  PontofrioBrazil: 'https://www.pontofrio.com.br/',
  PowerDenmark: 'https://www.power.dk/',
  PowerFinland: 'https://www.power.fi/',
  PowerNorway: 'https://www.power.no/',
  PowerReviews: 'https://www.powerreviews.com/',
  PowerSweden: 'https://www.power.se/',
  Priceline: 'https://www.priceline.com/',
  Purina: 'https://www.purina.co.uk/',
  QuarkExpeditions: 'https://www.quarkexpeditions.com/',
  QVC: 'https://www.qvc.com/',
  RaymourFlanigan: 'https://www.raymourflanigan.com/',
  REI: 'https://www.rei.com/',
  RelianceDigital: 'https://www.reliancedigital.in/',
  RepublicOfTea: 'https://www.republicoftea.com/',
  Revolve: 'https://www.revolve.com/',
  ReynSpooner: 'https://www.reynspooner.com/',
  RiteAid: 'https://www.riteaid.com/',
  Rona: 'https://www.rona.ca/',
  Rossmann: 'https://www.rossmann.de/',
  Sainsburys: 'https://www.sainsburys.co.uk/',
  SaksFifthAvenue: 'https://www.saksfifthavenue.com/',
  SaksOff5th: 'https://www.saksoff5th.com/',
  SamsClub: 'https://www.samsclub.com/',
  SanbornsMexico: 'https://www.sanborns.com.mx/',
  Saturn: 'https://www.saturn.de/',
  SearsMexico: 'https://www.sears.com.mx/',
  Sephora: 'https://www.sephora.com/',
  SephoraFrance: 'https://www.sephora.fr/',
  SephoraMexico: 'https://www.sephora.com.mx/',
  SephoraSpain: 'https://www.sephora.es/',
  SharafDG: 'https://uae.sharafdg.com/',
  ShopApotheke: 'https://www.shop-apotheke.com/',
  Shopee: 'https://shopee.co.th/',
  ShopeeIndonesia: 'https://shopee.co.id/',
  ShopeeMalaysia: 'https://shopee.com.my/',
  ShopeePhilipines: 'https://shopee.ph/',
  ShopeeSingapore: 'https://shopee.sg/',
  ShopeeTaiwan: 'https://shopee.tw/',
  ShopeeVietnam: 'https://shopee.vn/',
  ShoppersDrugMart: 'https://shop.shoppersdrugmart.ca/',
  Shoptime: 'https://www.shoptime.com.br/',
  Silk: 'https://silk.com/',
  SmythsToys: 'https://www.smythstoys.com/',
  SocialNature: 'https://www.socialnature.com/',
  SpaceNK: 'https://www.spacenk.com/',
  Specialized: 'https://www.specialized.com/',
  Splendid: 'https://www.splendid.com/',
  SportsmansWarehouse: 'https://www.sportsmans.com/',
  SSG: 'https://www.ssg.com/',
  Stamped: 'https://stamped.io/',
  Staples: 'https://www.staples.com/',
  StaplesCanada: 'https://www.staples.ca/',
  Submarino: 'https://www.submarino.com.br/',
  Suning: 'https://product.suning.com/',
  Superdrug: 'https://www.superdrug.com/',
  SweetWater: 'https://www.sweetwater.com/',
  T3Micro: 'https://www.t3micro.com/',
  Target: 'https://www.target.com/',
  TataCliq: 'https://www.tatacliq.com/',
  Teknosa: 'https://www.teknosa.com/',
  Tesco: 'https://www.tesco.com/',
  TMall: 'https://detail.tmall.com/',
  Tokopedia: 'https://www.tokopedia.com/',
  TommyBahama: 'https://www.tommybahama.com/',
  ToriRichards: 'https://toririchard.com/',
  ToryBurch: 'https://www.toryburch.com/',
  TotalWine: 'https://www.totalwine.com/',
  TravelStride: 'https://www.travelstride.com/',
  TravisMathew: 'https://www.travismathew.com/',
  Trendyol: 'https://www.trendyol.com/',
  TripAdvisor: 'https://www.tripadvisor.com/',
  Trustpilot: 'https://www.trustpilot.com/',
  UltaBeauty: 'https://www.ulta.com/',
  Unieuro: 'https://www.unieuro.it/',
  UpWest: 'https://www.upwest.com/',
  VandenBorre: 'https://www.vandenborre.be/',
  Venus: 'https://www.venus.com/',
  Very: 'https://www.very.co.uk/',
  VictoriasSecret: 'https://www.victoriassecret.com/',
  Walgreens: 'https://www.walgreens.com/',
  WalletHub: 'https://wallethub.com/',
  Walmart: 'https://www.walmart.com/',
  WalmartCanada: 'https://www.walmart.ca/',
  WalmartMexico: 'https://www.walmart.com.mx/',
  Wapteka: 'https://www.wapteka.pl/',
  WatsonsIndonesia: 'https://www.watsons.co.id/',
  WatsonsMalaysia: 'https://www.watsons.com.my/',
  WatsonsPhilipines: 'https://www.watsons.com.ph/',
  WatsonsSingapore: 'https://www.watsons.com.sg/',
  WatsonsThailand: 'https://www.watsons.co.th/',
  WatsonsVietnam: 'https://www.watsons.vn/',
  Wayfair: 'https://www.wayfair.com/',
  Webhallen: 'https://www.webhallen.com/',
  Well: 'https://well.ca/',
  WideOpenSocks: 'https://wideopensocks.com/',
  Wildberries: 'https://www.wildberries.ru/',
  WilliamsSonoma: 'https://www.williams-sonoma.com/',
  Woolworths: 'https://www.woolworths.com.au/',
  Worten: 'https://www.worten.pt/',
  Xbox: 'https://www.xbox.com/',
  XKomPoland: 'https://www.x-kom.pl/',
  Yelp: 'https://www.yelp.com/',
  Yodobashi: 'https://www.yodobashi.com/',
  Yotpo: 'https://www.yotpo.com/',
  Zappos: 'https://www.zappos.com/',
}
