import { Button } from 'antd'
import { updateSettings } from 'features/project/features/charts/model'
import React, { useState } from 'react'
import styled from 'styled-components'
import { ModalType } from '../hooks/use-clusters-operations'
import { SelectedClusterSentencesModal } from '../selected-cluster-sentences-modal'
import { Cluster } from '../types'
import { useProjectStore } from '../../../../../projectStore/projectStore'
import { ProjectState } from '../../../../../types'
import { YogiButton } from '../../../../../../../components/UI/YogiButton'

type ClusterOperations = {
  [key: string]: any
}

type ClusterRestrictedOperations = {
  [key: string]: boolean
}

type ActionsHiddenButtons = {
  [key: string]: boolean
}

type Props = {
  selectedClusters: string[]
  clusters: Cluster[]
  operations: ClusterOperations
  restrictedOperations: ClusterRestrictedOperations
  hiddenButtons?: ActionsHiddenButtons
  fetchClusters: () => void
  confirmationWrapper: (callback: () => void) => void
  needToUpdateSettings?: boolean
  setNeedToUpdateSettings?: (v: boolean) => void
}

export const ActionButtons: React.FC<Props> = ({
  selectedClusters,
  operations,
  restrictedOperations,
  hiddenButtons,
  clusters,
  fetchClusters,
  confirmationWrapper,
  needToUpdateSettings,
  setNeedToUpdateSettings,
}) => {
  const [isClusterSentencesModalOpened, setClusterSentencesModalOpened] =
    useState<boolean>(false)

  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  const resultCallbackWrapper = (callback: () => void) => {
    if (needToUpdateSettings && setNeedToUpdateSettings) {
      // updateSettings(projectId)
      callback()
      setNeedToUpdateSettings(false)
    } else {
      callback()
    }
  }

  return (
    <ButtonsWrapper>
      {isClusterSentencesModalOpened ? (
        <SelectedClusterSentencesModal
          handleOk={() => setClusterSentencesModalOpened(false)}
          handleCancel={() => setClusterSentencesModalOpened(false)}
          selectedClusters={selectedClusters}
          clusters={clusters}
          clusterOps={operations}
          fetchClusters={fetchClusters}
          confirmationWrapper={confirmationWrapper}
        />
      ) : null}
      <>
        {!(hiddenButtons && hiddenButtons?.hide) && (
          <YogiButton
            type="primary"
            disabled={restrictedOperations.hide}
            onClick={() =>
              operations.onChangeVisibility(false, selectedClusters)
            }
          >
            Hide
          </YogiButton>
        )}
        {!(hiddenButtons && hiddenButtons?.unhide) && (
          <YogiButton
            type="primary"
            disabled={restrictedOperations.unhide}
            onClick={() =>
              operations.onChangeVisibility(true, selectedClusters)
            }
          >
            Unhide
          </YogiButton>
        )}
        {!(hiddenButtons && hiddenButtons?.group) && (
          <YogiButton
            type="primary"
            disabled={restrictedOperations.group}
            onClick={() =>
              operations.setModal({ modal: ModalType.Group, selectedClusters })
            }
          >
            Group
          </YogiButton>
        )}
        {!(hiddenButtons && hiddenButtons?.ungroup) && (
          <YogiButton
            type="primary"
            disabled={restrictedOperations.ungroup}
            onClick={() =>
              confirmationWrapper(() => operations.onUngroup(selectedClusters))
            }
          >
            Ungroup
          </YogiButton>
        )}
        {!(hiddenButtons && hiddenButtons?.move) && (
          <YogiButton
            type="primary"
            disabled={restrictedOperations.move}
            onClick={() =>
              operations.setModal({
                modal: ModalType.AssignToGroup,
                selectedClusters,
              })
            }
          >
            Assign to group
          </YogiButton>
        )}
        {!(hiddenButtons && hiddenButtons?.merge) && (
          <YogiButton
            type="primary"
            disabled={restrictedOperations.merge}
            onClick={() =>
              operations.setModal({ modal: ModalType.Merge, selectedClusters })
            }
          >
            Merge
          </YogiButton>
        )}
        {!(hiddenButtons && hiddenButtons?.simpleFeedback) && (
          <YogiButton
            type="primary"
            disabled={restrictedOperations.simple_feedback}
            onClick={() =>
              confirmationWrapper(() =>
                operations.onApplySimpleFeedback(selectedClusters),
              )
            }
          >
            Simple Feedback
          </YogiButton>
        )}
        {!(hiddenButtons && hiddenButtons?.preview) && (
          <YogiButton
            type="dashed"
            disabled={restrictedOperations.preview}
            onClick={() =>
              setClusterSentencesModalOpened(!isClusterSentencesModalOpened)
            }
          >
            Preview
          </YogiButton>
        )}
        {!(hiddenButtons && hiddenButtons?.clear) && (
          <YogiButton
            type="dashed"
            onClick={() => {
              operations.clearClusterSelection()
            }}
          >
            Clear
          </YogiButton>
        )}
        {!(hiddenButtons && hiddenButtons?.done) && (
          <YogiButton
            type="dashed"
            onClick={() => resultCallbackWrapper(operations.onFinish)}
          >
            Done
          </YogiButton>
        )}
      </>
    </ButtonsWrapper>
  )
}

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  justify-content: space-around;
  margin-top: var(--default-padding-half);

  & button {
    width: 48%;
    margin-bottom: 5px;
    padding: 0;
  }
`
