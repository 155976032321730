import React, { useMemo, useState } from 'react'
import { useProjectStore } from '../../../../../../projectStore/projectStore'
import { ProjectState } from '../../../../../../types'
import {
  mainText,
  pageBackgroundGrey,
  secondaryNavy,
} from '../../../../../../../../assets/styles/variables'
import { CATALOG_URL } from '../../../../../../utils'
import mixpanel from '../../../../../../../trackers/mixpanel'
import { BarcodeOutlined } from '@ant-design/icons'
import { SourceIcon as Icon } from '../../../../../../../../shared/components'
import { YogiButton } from '../../../../../../../../components/UI/YogiButton'
import { Modal } from 'antd'
import { SourceDetail } from '../../../../../sourcepage/components'
import { FocusData, FocusListOption } from '../../FocusList'
import styled from 'styled-components'

interface FocusProps extends FocusData {
  item: FocusListOption
  category: string | null
  onSelect: (value: string) => void
  compareSet: string[]
  onCompare: (value: string[]) => void
}

const FocusListItem: React.FC<FocusProps> = ({
  item,
  category,
  title,
  reviewCount,
  imageUrl,
  productCount,
  identifiers,
  onSelect,
  compareSet,
  onCompare,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const details = useProjectStore((state: ProjectState) => state.details)

  const [sourceModalOpen, setSourceModalOpen] = useState<boolean>(false)

  const isCompare = useMemo(
    () => compareSet.includes(title),
    [compareSet, title]
  )

  return (
    <>
      <a
        style={{ textDecoration: 'none', color: mainText }}
        href={`${window.location.origin}/project/${projectId}/${CATALOG_URL}?category=${category}&item=${item.value}`}
        onClick={(e) => {
          mixpanel.track('catalog', {
            action: 'open item',
            value: item.value,
          })
          e.preventDefault()
        }}
      >
        <FocusItemContainer
          onClick={() => {
            onSelect(item.value)
          }}
        >
          <ColorBar color={secondaryNavy} />
          <FocusItemHeader>
            <h3>{title.length > 100 ? `${title.slice(0, 100)}...` : title}</h3>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 5,
              }}
            >
              <div>
                {productCount && productCount > 1 && (
                  <>
                    Items: {productCount.toLocaleString()}
                    <br />
                  </>
                )}
                Reviews & Ratings: {reviewCount.toLocaleString()}
              </div>
              {/*<div*/}
              {/*  style={{*/}
              {/*    display: 'flex',*/}
              {/*    flexDirection: 'column',*/}
              {/*    justifyContent: 'flex-end',*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <YogiButton*/}
              {/*    type={isCompare ? 'primary' : 'ghost'}*/}
              {/*    style={*/}
              {/*      {*/}
              {/*        // display: 'flex',*/}
              {/*        // gap: 10,*/}
              {/*        // alignItems: 'center',*/}
              {/*        // border: 0,*/}
              {/*        // background: 'transparent',*/}
              {/*        // boxShadow: 'none',*/}
              {/*        // transform: 'none',*/}
              {/*      }*/}
              {/*    }*/}
              {/*    onClick={(e: any) => {*/}
              {/*      e.preventDefault()*/}
              {/*      e.stopPropagation()*/}
              {/*      // @ts-ignore*/}
              {/*      onCompare((prev: string[]) => {*/}
              {/*        if (prev.includes(title)) {*/}
              {/*          return prev.filter((el) => el !== title)*/}
              {/*        } else {*/}
              {/*          return [...prev, title]*/}
              {/*        }*/}
              {/*      })*/}
              {/*    }}*/}
              {/*    disabled={compareSet.length === 3 && !isCompare}*/}
              {/*  >{`${isCompare ? 'Comparing' : 'Compare'}`}</YogiButton>*/}
              {/*</div>*/}
            </div>
          </FocusItemHeader>
          {/*<p>Last Updated: {new Date(date).toLocaleDateString()}</p>*/}

          {imageUrl && (
            <ImageWrapper>
              {imageUrl ? (
                <img
                  src={imageUrl}
                  alt=""
                  style={{ height: 'auto', width: 'auto', maxHeight: 80 }}
                />
              ) : (
                <BarcodeOutlined style={{ fontSize: '60px' }} />
              )}
            </ImageWrapper>
          )}
          {category === 'source_type' && (
            <>
              <ImageWrapper
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 10,
                }}
              >
                <Icon
                  source={item.image_url ?? 'syndicated'}
                  style={{
                    width: '50px',
                    height: '50px',
                  }}
                />
                <YogiButton
                  onClick={(e: any) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setSourceModalOpen(true)
                  }}
                >
                  View Sources
                </YogiButton>
              </ImageWrapper>
            </>
          )}
        </FocusItemContainer>
      </a>

      {sourceModalOpen && (
        <Modal
          className="source-modal"
          title={item.value}
          visible={true}
          footer={null}
          onCancel={() => setSourceModalOpen(false)}
          width={850}
        >
          <SourceDetail
            role={details.role}
            sourcename={item.value}
            projectId={projectId}
            syndicated={false}
          ></SourceDetail>
        </Modal>
      )}
    </>
  )
}
export default FocusListItem

const FocusItemContainer = styled.div`
  padding: var(--default-padding-quarter) var(--default-padding-half);
  padding-left: calc(var(--default-padding-quarter) + 12px);
  background: #fff;
  box-shadow: var(--default-box-shadow);
  transition: transform ease 0.1s;
  transform: scale(1);
  position: relative;
  cursor: pointer;
  border-radius: var(--border-radius);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  &:hover {
    background: ${pageBackgroundGrey};
  }
`

const FocusItemHeader = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  max-width: 50%;
`

const ColorBar = styled.div<{ color?: string }>`
  width: 8px;
  background: ${(props) => (props.color ? props.color : '#28364F')};
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
`
