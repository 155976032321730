import FocusListItem from '../FocusListItem/FocusListItem'
import { VirtuosoGrid } from 'react-virtuoso'
import React from 'react'
import { Grid } from '@mui/material'
import { FocusListOption } from '../../FocusList'
import styled from 'styled-components'

type Props = {
  focusListData: FocusListOption[]
  selectedLens: string | null
  setSelectedFocus: (value: string) => void
  compareSet: string[]
  setCompareSet: (value: string[]) => void
}

const VirtuosoGridComponent: React.FC<Props> = ({
  focusListData,
  selectedLens,
  setSelectedFocus,
  compareSet,
  setCompareSet,
}: any) => {
  const GridItem = ({ children, ...props }: any) => {
    const focus = children
    if (!focus) return <div>Loading</div>
    return (
      <ListItemWrapper {...props}>
        <FocusListItem
          item={focus}
          category={selectedLens}
          title={focus.value}
          reviewCount={focus.n_posts}
          identifiers={focus.identifiers}
          productCount={focus.product_count}
          imageUrl={focus.image_url}
          onSelect={setSelectedFocus}
          compareSet={compareSet}
          onCompare={setCompareSet}
        />
      </ListItemWrapper>
    )
  }

  if (focusListData.length < 5000) {
    return (
      <Grid container spacing={2} style={{ padding: 20, marginTop: -30 }}>
        {focusListData?.map((focus: FocusListOption, index: number) => (
          <Grid item xs={12} sm={6} md={4} key={index} spacing={2}>
            <FocusListItem
              item={focus}
              category={selectedLens}
              title={focus.value}
              reviewCount={focus.n_posts}
              identifiers={focus.identifiers}
              productCount={focus.product_count}
              imageUrl={focus.image_url}
              onSelect={setSelectedFocus}
              compareSet={compareSet}
              onCompare={setCompareSet}
            />
          </Grid>
        ))}
      </Grid>
    )
  }

  return (
    <VirtuosoGrid
      totalCount={focusListData.length}
      overscan={200}
      components={{
        Item: GridItem,
        List: React.forwardRef(({ style, children }, listRef) => (
          <div
            ref={listRef}
            style={{
              ...style,
              display: 'flex',
              flexWrap: 'wrap',
              gap: 16,
              // gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
              // gap: '16px',
              padding: 20,
              // marginTop: -30,
            }}
          >
            {children}
          </div>
        )),
      }}
      itemContent={(index) => focusListData[index]}
      style={{ height: '100%', width: '100%' }}
    />
  )
}

export default VirtuosoGridComponent

const ListItemWrapper = styled.div`
  flex-basis: 30%;

  height: 150px;
  flex-grow: 1;

  @media (max-width: 1200px) {
    flex-basis: 40%;
  }
  @media (max-width: 900px) {
    flex-basis: 100%;
  }
`
