import styled from 'styled-components'
import {
  buttonBlue,
  buttonHover,
  cardBorder,
  cardBorderGrey,
  cardBoxShadow,
  errorRed,
  greyBackgroundAlternate,
  newButtonBlue,
  pageBackgroundGrey,
  secondaryNavy,
  toggleBorderSelected,
  toggleBorderUnselected,
  lightBlueFontColor,
} from '../../../../../../assets/styles/variables'
import { Input, InputNumber, Select } from 'antd'

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: ${pageBackgroundGrey};
  position: relative;
`

export const Header = styled.div`
  padding: var(--default-padding-half) var(--default-padding) 5px;
  border-bottom: 1px solid #f2f2f2;
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  padding: 10px 27px;
`

export const Section = styled.div`
  margin-top: 18px;
`

export const Footer = styled.div`
  background: ${greyBackgroundAlternate};
  padding: 10px 27px 20px;
  display: flex;
  flex-direction: column;
  min-height: 350px;
  overflow: auto;
`

export const MinReviewWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  margin-top: 12px;
  padding: 0 15px;
`

export const StyledSelect = styled(Select)<{
  color: string
  textColor: string
}>`
  min-width: 175px;
  color: ${({ textColor }) => textColor};
  .ant-select-selector {
    border: 2px solid #dde2e5 !important;
    border-radius: 10px !important;
    background: ${({ color }) => color} !important;
    :focus {
      color: ${({ textColor }) => textColor} !important;
    }
    padding: 5px 16px !important;
    height: 44px !important;
    color: ${({ textColor }) => textColor} !important;
  }
  .ant-select-arrow {
    color: ${({ textColor }) => textColor} !important;
  }
  .ant-select-selection-item {
    color: ${({ textColor }) => textColor} !important;
  }
  font-size: 14px;
  .ant-select-selector:hover {
    border: 2px solid var(--button-blue) !important;
  }
`
export const Overlay = styled.div`
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const ControlsCard = styled.div`
  padding: 14px;
  border-radius: 8px;
  border: 2px solid ${cardBorder};
  box-shadow: ${cardBoxShadow};
  //position: relative;
`

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
`

export const Form = styled.form`
  height: 100%;
  overflow: auto;
`

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
`
export const CustomButtonGroupItem = styled.div<{ isSelected: boolean }>`
  background: ${({ isSelected }) =>
    isSelected ? 'white' : toggleBorderUnselected};
  border: ${({ isSelected }) =>
    isSelected ? '1px solid' + toggleBorderSelected : '1px solid transparent'};
  box-shadow: ${({ isSelected }) =>
    isSelected ? '0px 4px 4px 0px #0000001A' : 'none'};
  text-align: center;
  padding: 8px;
  cursor: pointer;
  color: ${lightBlueFontColor};
  z-index: ${({ isSelected }) => (isSelected ? 2 : 1)};
  &:hover {
    border: 1px solid ${secondaryNavy};
    z-index: 3;
  }
  transition: all 0.2s;
`

export const StyledInput = styled(Input)<{ isError: boolean }>`
  background: white;
  border-color: ${({ isError }) => (isError ? errorRed : 'rgb(239, 240, 246)')};
  border-radius: 10px;
  border: 2px solid ${cardBorderGrey};
  &:hover {
    border-color: ${buttonBlue};
  }
  &:focus {
    border-color: ${buttonBlue};
  }
  font-size: 14px;
  padding: 9px 16px;
`

export const StyledMultiSelectInput = styled(Select)`
  font-size: 14px;
  .ant-select-selector {
    padding: 6px 12px !important;
    border-radius: 10px !important;
    //height: 54px !important;
  }
`
export const SubmitButton = styled.button`
  font-size: 14px;
  background: ${newButtonBlue};
  padding: 12px 18px;
  min-width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    background: ${buttonHover};
    color: white;
  }
  &:focus {
    background: ${buttonBlue};
    color: white;
  }
`

export const RecipientItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  font-size: 14px;
  padding: 0px 15px;
`

export const Bold = styled.span`
  font-weight: 500;
`

export const StyledInputNumber = styled(InputNumber)`
  min-width: 80px;
  //height: 30px;
  text-align: center;
  border-radius: 10px;
  padding: 2px 4px 0px;
  border: 2px solid ${cardBorderGrey};
  &:hover {
    border-color: ${buttonBlue};
  }
  &:focus {
    border-color: ${buttonBlue};
  }
`

// AlertName.tsx
export const ErrorMessage = styled.div`
  color: red;
`
