import React from 'react'
import styled from 'styled-components'
import { LogoSpinner } from '../../components/LogoSpinner'
import logo from 'assets/images/logos/2024/Yogi-Horizontal-Logo-RGB-Full-Color-Large.png'
import { YogiButton } from '../../components/UI/YogiButton'

type Props = { paddingTop?: boolean }

export const Access: React.FC<Props> = ({ paddingTop = true }) => (
  <LoaderWrapper>
    <img src={logo} style={{ width: '300px' }} />
    <div style={{ maxWidth: '500px', margin: '0 auto' }}>
      <p style={{ textAlign: 'center' }}>
        You do not have access to this project. Please request access from a
        user in your org, or contact us at{' '}
        <a href="mailto:support@meetyogi.com">support@meetyogi.com</a>.
      </p>
    </div>
    <YogiButton type={'primary'}>
      <a href="/">Return Home</a>
    </YogiButton>
  </LoaderWrapper>
)
// export const Loader: React.FC<Props> = ({ paddingTop = true }) => (
//   <LoaderWrapper paddingTop={paddingTop}>
//     <Spheres src={LoadingShperesIcon} />
//     <Face src={FaceIcon} paddingTop={paddingTop} />
//   </LoaderWrapper>
// )

const LoaderWrapper = styled.div`
  height: 100vh;
  margin-top: -50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 100;
  gap: 20px;
`
