import React, { useMemo } from 'react'
import styled from 'styled-components'
import { initialState } from '../../projectStore/projectStore'
import { Filters } from '../../types'
import { PostType, TimeInterval } from '../charts/types'
import { Summary } from '../summary'
import Overview from './Components/Overview'
import { FocusListOption } from './Components/FocusList/FocusList'

interface FocusDetailProps {
  isComparative: boolean
  lens: string
  activeTab: string
  headerHeight: number
  filterValues: Filters
  item: FocusListOption
}

const FocusDetailContent: React.FC<FocusDetailProps> = ({
  item,
  isComparative,
  lens,
  activeTab,
  headerHeight,
  filterValues,
  // itemSelectorMaxHeight
}) => {
  const chartId = '5_4_16_rating'
  const tier1 = lens
  const postType: PostType = 'post'
  const timeInterval: TimeInterval = 'month'
  const reviewsCount = 0

  const localFilterValues = useMemo(() => {
    return {
      ...filterValues,
      values: [
        ...filterValues.values,
        { field: lens, values: item.identifiers },
      ],
    }
  }, [filterValues, lens, item.identifiers])

  const dashboardControls = JSON.parse(
    JSON.stringify(initialState.dashboardControls[0])
  )
  dashboardControls.legend = false

  return (
    <>
      <Content>
        <MainBody>
          {activeTab === '0' && (
            <Overview
              item={item}
              filterValues={localFilterValues}
              tier1={tier1}
              chartId={chartId}
              postType={postType}
              timeInterval={timeInterval}
              reviewsCount={reviewsCount}
            />
          )}
          {activeTab === '1' && <Summary />}
        </MainBody>
      </Content>
    </>
  )
}

const Content = styled.div`
  width: 100%;
`

const MainBody = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
`

export default FocusDetailContent
