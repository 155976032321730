import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Typography, Input, Tooltip, Button, Select } from 'antd'
import { useQuery } from 'react-query'
import { SearchOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { ProjectEditForm } from 'features/project-form'
import { Loader } from 'shared/components'
import { tooltip } from 'utils/tooltip-data'
import { SourceList } from '../components'
import { getSourceGroups, getProjectDetails } from '../model'
import { ProjectStatuses } from 'features/home/types'
import mixpanel from 'features/trackers/mixpanel'
import { fetchProjectsByGroup } from '../../../../home/model'
import { useProject } from '../../../hooks/useProject'
import { useGroupProjectList } from '../../../hooks/useGroupProjectList'
import { CATALOG_URL, CHARTS_URL, SOURCES_URL } from '../../../utils'
import { Link, useHistory } from 'react-router-dom'
import { useProjectStore } from '../../../projectStore/projectStore'
import { ProjectState } from '../../../types'
import { YogiButton } from '../../../../../components/UI/YogiButton'
import { OpenInNewOutlined } from '@mui/icons-material'
import { secondaryNavy } from '../../../../../assets/styles/variables'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'

type Props = {
  projectId: string
}

export const SourceContainer: React.FC<Props> = ({ projectId }) => {
  const parentDetails = useProjectStore((state: ProjectState) => state.details)
  const [isProjectEditModalVisible, setProjectEditModalVisible] =
    useState(false)
  const [selectedProject, setSelectedProject] = useState('')
  const activeProject = selectedProject ? selectedProject : projectId
  const { data: details } = useProject(activeProject)
  const isAdmin = details?.role === 'admin'

  const { data: groupProjects } = useGroupProjectList(projectId)

  const { isLoading, isError, data, error } = useQuery(
    ['sourcegroup', false, activeProject],
    () => getSourceGroups('sourcegroup', false, activeProject),
  )

  const { data: syndicated_data, isLoading: loadingSyndicatedData } = useQuery(
    ['syndicated_source_group', true, activeProject],
    () => getSourceGroups('syndicated_source_group', true, activeProject),
  )

  const { data: proj_details, isLoading: loading_details } = useQuery(
    ['proj_details', activeProject],
    () => getProjectDetails('proj_details', activeProject),
  )

  const { data: feature_flags } = useFeatureFlags()

  useEffect(() => {
    mixpanel.track('sources page')
  }, [])

  const [search, setSearch] = useState('')

  const onSearch = (e: any) => {
    setSearch(e.target.value)
  }

  // this is used so UI doesnt flicker when changing project on a grouped project view
  const [curDetails, setCurDetails] = useState(parentDetails)
  useEffect(() => {
    if (details) {
      setCurDetails(details)
    }
  }, [details])

  const isGroupProject =
    groupProjects?.data?.projects?.length &&
    groupProjects?.data?.projects?.length > 1

  const isViewingGroupParent = isGroupProject && !selectedProject

  if (!curDetails) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    )
  }

  if (isLoading || loadingSyndicatedData || loading_details) {
    return (
      <LoaderWrapper>
        <Loader />
        <LoaderTitle>{`Loading ${curDetails?.clientSettings?.project?.pages?.sources?.alias} Page`}</LoaderTitle>
      </LoaderWrapper>
    )
  }

  if (isError) {
    return <span> Error: {error}</span>
  }

  return (
    <Wrapper>
      {feature_flags?.verbatim_homepage && (
        <Banner>
          <InfoCircleOutlined style={{ marginRight: 10 }} />{' '}
          <span style={{ marginTop: 3 }}>
            Sources page will be moving to the Catalog page on April 30th
          </span>
          <Link
            to={`${CATALOG_URL}?category=source_type`}
            target={'_blank'}
            rel="noopener noreferrer"
            style={{
              textDecoration: 'none',
              color: 'white',
              marginRight: 5,
              marginLeft: 16,
            }}
          >
            {' '}
            <YogiButton type="dashed" style={{ display: 'flex' }}>
              <span style={{ fontSize: 18, marginTop: -3, marginRight: 5 }}>
                Open Catalog
              </span>
              <OpenInNewOutlined />
            </YogiButton>
          </Link>
        </Banner>
      )}
      <Header>
        {groupProjects?.data?.projects?.length &&
          groupProjects?.data?.projects?.length > 1 && (
            <SelectBody>
              <Select
                size="large"
                value={selectedProject}
                onChange={(e) => setSelectedProject(e)}
                style={{ marginLeft: 20 }}
              >
                <Select.Option value={''}>{'All Datasets'}</Select.Option>
                {groupProjects.data.projects.map((project) => (
                  <Select.Option key={project.uuid} value={project.uuid}>
                    {project.title}
                  </Select.Option>
                ))}
              </Select>
            </SelectBody>
          )}
        <Input
          id="source-search"
          prefix={<SearchOutlined />}
          placeholder="Search for Source"
          allowClear
          value={search}
          onChange={onSearch}
          style={{ margin: '20px 0px', width: '500px' }}
        />
        {isAdmin && !isViewingGroupParent && (
          <Button
            style={{ marginLeft: 'auto' }}
            type="primary"
            disabled={details.state !== ProjectStatuses.COMPLETE}
            onClick={() => setProjectEditModalVisible(true)}
          >
            Add Sources
          </Button>
        )}
      </Header>
      <Body id="source-container">
        <TitleWrapper>
          <Typography.Title id="source-original-title">
            Original Sources{' '}
            <Tooltip title={tooltip['original-sources']} placement="right">
              <InfoCircleOutlined style={{ fontSize: '16px' }} />
            </Tooltip>
          </Typography.Title>
        </TitleWrapper>
        <SourceWrapper id="source-list-original">
          {data && proj_details && (
            <SourceList
              sources={data.filter((data) => {
                return (
                  data.source.toLowerCase().indexOf(search.toLowerCase()) !== -1
                )
              })}
              syndicated={false}
              projectId={projectId}
              role={proj_details.role}
            />
          )}
        </SourceWrapper>

        <TitleWrapper>
          <Typography.Title id="source-syndicated-title">
            Syndicated Sources{' '}
            <Tooltip title={tooltip['syndicated-sources']} placement="right">
              <InfoCircleOutlined style={{ fontSize: '16px' }} />
            </Tooltip>
          </Typography.Title>
        </TitleWrapper>
        <SourceWrapper>
          {syndicated_data && proj_details && (
            <SourceList
              sources={syndicated_data.filter((data) => {
                return (
                  data.source.toLowerCase().indexOf(search.toLowerCase()) !== -1
                )
              })}
              syndicated={true}
              projectId={projectId}
              role={proj_details.role}
            />
          )}
        </SourceWrapper>
      </Body>

      {isProjectEditModalVisible && (
        <ProjectEditForm
          handleClose={() => setProjectEditModalVisible(false)}
          projectId={activeProject}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
`
const Body = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 var(--default-padding);
`
const TitleWrapper = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  width: 100%;
  margin-bottom: 10px;
  padding-left: 53px;
`
const SourceWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const LoaderWrapper = styled.div`
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Header = styled.div`
  padding: 0 var(--default-padding);
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const LoaderTitle = styled.div`
  //margin-top: var(--default-padding);
  text-align: center;
  font-size: var(--font-size-l);
  font-weight: 500;
`

const SelectWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`

const SelectBody = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  margin: 0 10px;
  top: 30%;
`

const Banner = styled.div`
  padding: 0px 20px;
  height: 50px;
  background: ${secondaryNavy};
  color: white;
  display: flex;
  align-items: center;
  font-size: 24px;
`
