import React, { useState } from 'react'
import { Tabs } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { YogiButton } from '../../../../components/UI/YogiButton'
import { Summary } from '../summary'
import { useProjectStore } from '../../projectStore/projectStore'
import { Filters, ProjectState } from '../../types'
import { lightText } from '../../../../assets/styles/variables'
import useResizeObserver from 'use-resize-observer'
import { DatePanel } from '../filters/features/date-panel/date-panel'
import { FocusListOption } from './PDPList'
import { emptyFilters } from '../../utils'
import PDPDetailContent from './PDPDetailContent'
import { ProductHierarchyInfo } from '../products/types'

// const pluralize = require('pluralize')

interface FocusDetailProps {
  lens: string
  item: ProductHierarchyInfo
  setValue: (value: string | null) => void
}

const PDPDetail: React.FC<FocusDetailProps> = ({ lens, item, setValue }) => {
  const { ref, height = 1 } = useResizeObserver<HTMLDivElement>()

  const [localFilters, setLocalFilters] = useState<Filters>(emptyFilters)

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Header ref={ref}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            // marginBottom: 10,
          }}
        >
          <YogiButton
            type="dashed"
            onClick={() => setValue(null)}
            icon={<LeftOutlined />}
            style={{
              border: 'none',
              boxShadow: 'none',
              color: lightText,
              paddingLeft: 0,
            }}
          >
            Back to all Products
          </YogiButton>
          <div></div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h1 style={{ marginBottom: 10 }}>
            Amazon PDP Insights: {item.hierarchy}
          </h1>
        </div>
      </Header>
      <Content headerHeight={height}>
        <PDPDetailContent item={item} lens={lens} filterValues={localFilters} />
      </Content>
    </div>
  )
}

const Header = styled.div`
  background: white;
  padding: var(--default-padding-quarter) var(--default-padding) 0px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`
const Content = styled.div<{ headerHeight?: number }>`
  height: calc(100% - ${(props) => props.headerHeight}px);
  overflow: auto;
  display: flex;
`
const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`

const MainBody = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 100%;
`

const LeftSection = styled.div`
  flex: 3;
  padding-right: 16px;
  border-right: 2px solid rgba(159, 183, 195, 0.15);
  max-width: 75%;
`

const RightSection = styled.div`
  flex: 1;
  padding-left: 16px;
`

const StyledTabs = styled(Tabs)`
  flex: 1;
  padding-left: 16px;

  .ant-tabs-nav {
    margin: 0;
  }
`
const LoadingWrapper = styled.div`
  height: 100%;
  max-width: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`

export default PDPDetail
