import React, { useRef, useState } from 'react'
import { FilterButton } from '../../../../filters/FilterButton'
import { SearchButton } from '../../../../filters/SearchButton'
import { FilterPanel } from '../../../../filters/features/FilterPanel/FilterPanel'
import { SearchPanel } from '../../../../filters/features/search-panel/search-panel'
import { PanelBadges } from '../../../../filters/features/badge-panel/panel-badges'
import { useQuery } from 'react-query'
import { fetchFilterOptions } from '../../../../../react-query-functions'
import { useProjectStore } from '../../../../../projectStore/projectStore'
import { useClickOutside } from '../../../../../../../shared/hooks'
import { Section, ControlsCard, ButtonWrapper } from '../CreateAlert.styles'
import {
  FilterPanelWrapper,
  SearchPanelWrapper,
} from '../../../../filters/header-filter-panel'
import { Filters } from '../../../../../types'

interface Props {
  filterValues: Filters
  setFilterValues: (filterValues: Filters) => void
}

const AlertDefinition: React.FC<Props> = ({
  filterValues,
  setFilterValues,
}) => {
  const projectId = useProjectStore((state) => state.projectId)
  const updateSelectedOptionField = useProjectStore(
    (state) => state.updateSelectedOptionField
  )
  const updateOpenedOptions = useProjectStore(
    (state) => state.updateOpenedOptions
  )
  const [isFilterPanelVisible, setFilterPanelVisible] = useState(false)
  const [isSearchPanelVisible, setSearchPanelVisible] = useState(false)
  const [localOpenedOptions, setLocalOpenedOptions] = useState<string[]>([])

  const panelRef = React.createRef<HTMLDivElement>()

  const { data: filterListData, isLoading: filterOptionsLoading } = useQuery(
    ['filter-list-data', projectId, localOpenedOptions, filterValues],
    async () => {
      return await fetchFilterOptions(
        projectId,
        localOpenedOptions,
        filterValues
      )
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId,
    }
  )

  useClickOutside(panelRef, (e) => {
    if (
      (e.target && e.target?.id.includes('filter-badge')) ||
      (e.target && e.target?.id.includes('filters-button')) ||
      (e.path && e.path[1]?.id === 'filters-button')
    )
      return
    setFilterPanelVisible(false)
  })

  function setLocalFilterValues(filterValues: Filters) {
    setFilterValues(filterValues)
    setFilterPanelVisible(false)
    setSearchPanelVisible(false)
  }

  // this helps us position the filter-panel in the right place if the CreateAlert body has been scrolled
  const buttonWrapperRef = useRef<HTMLDivElement>(null)
  const buttonWrapperTop = buttonWrapperRef?.current?.getBoundingClientRect()
    .top
    ? Math.ceil(buttonWrapperRef?.current?.getBoundingClientRect().top) + 32
    : 157

  return (
    <Section style={{ marginTop: 5 }}>
      <h3>Define Alert</h3>
      <ControlsCard>
        <ButtonWrapper ref={buttonWrapperRef}>
          <FilterButton
            filterValues={filterValues}
            filterList={filterListData ?? []}
            isFilterPanelVisible={isFilterPanelVisible}
            setFilterPanelVisible={setFilterPanelVisible}
            filterOptionsLoading={false}
          />
          <SearchButton
            filterValues={filterValues}
            filterList={filterListData ?? []}
            isSearchPanelVisible={isSearchPanelVisible}
            setSearchPanelVisible={setSearchPanelVisible}
            filterOptionsLoading={false}
            comparativePanelsNumber={1}
          />
        </ButtonWrapper>
        {isFilterPanelVisible && (
          <FilterPanelWrapper
            ref={panelRef}
            params={{
              comparativePanelsNumber: 1,
              isComparative: false,
              dragged: false,
            }}
            style={{ maxWidth: 645, top: buttonWrapperTop, left: 42 }}
          >
            <FilterPanel
              dragged={false}
              comparativeIndex={0}
              filterListData={filterListData}
              filterValues={filterValues}
              updateLocalFilterValues={setLocalFilterValues}
              localOpenedOptions={localOpenedOptions}
              setLocalOpenedOptions={setLocalOpenedOptions}
              height={'350px'}
            />
          </FilterPanelWrapper>
        )}
        {isSearchPanelVisible && (
          <div style={{ position: 'relative' }}>
            <SearchPanelWrapper>
              <SearchPanel
                filterValues={filterValues}
                closePanel={() => setSearchPanelVisible(false)}
                comparativeIndex={0}
                updateLocalFilterValues={setLocalFilterValues}
              />
            </SearchPanelWrapper>
          </div>
        )}
        {!!filterValues.values.length && (
          <div style={{ marginTop: 12 }}>
            <PanelBadges
              filterValues={filterValues}
              comparativeIndex={0}
              updateLocalFilterValues={setLocalFilterValues}
              openFilterPanel={(field) => {
                updateSelectedOptionField(0, field)
                updateOpenedOptions(0, field)
                setFilterPanelVisible(true)
              }}
              countThreshold={100}
            />
          </div>
        )}
      </ControlsCard>
    </Section>
  )
}

export default AlertDefinition
