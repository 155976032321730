import {
  ChartTypes,
  downloadChartAsCSV,
  fontSizeDict,
  getAdjustedDateRangeString,
  getGroupedChartValues,
  matrixChartIds,
  onCopyChart,
} from '../utils'
import { Button, Checkbox, Pagination, Popover, Select, Tooltip } from 'antd'
import { YogiButton } from '../../../../../components/UI/YogiButton'
import { orderNames } from '../../feedback'
import { findDateWindow } from '../../../utils'
import {
  ColumnWidthOutlined,
  CopyOutlined,
  DownloadOutlined,
  LinkOutlined,
  TableOutlined,
} from '@ant-design/icons'
import mixpanel from '../../../../trackers/mixpanel'
import {
  buttonBlue,
  cardBorderGrey,
} from '../../../../../assets/styles/variables'
import { tooltip } from '../../../../../utils/tooltip-data'
import { CSVLink } from 'react-csv'
import { ChartSettings } from './ChartSettings'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import {
  DashboardChartResponse,
  type TableChart as TableChartType,
  TimeInterval,
  UnionChart,
} from '../types'
import { useProjectStore } from '../../../projectStore/projectStore'
import { ProjectState } from '../../../types'
import { FullscreenRounded } from '@mui/icons-material'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import CompareInput from './CompareInput'
import { toast } from 'react-toastify'
import YogiDivider from '../../../../../components/UI/YogiDivider'

type Props = {
  wrapperRef: React.RefObject<HTMLDivElement>
  chartWrapperRef: React.RefObject<HTMLDivElement>
  currentChart: UnionChart
  comparativeIndex: number
  isDelta: boolean
  isUnlinked: boolean
  currentPage: number
  pageSize: number
  order: string
  toggleOrder: any
  onPageChange: any
  prevRange: any
  fontSize: any
  chartData?: DashboardChartResponse
  localDashboardControls?: any
  updateLocalDashboardControls?: (key: string, value: any) => void
  isEditing?: boolean
  setChartId?: any
  isFullScreen?: boolean
  expandedCategories?: string[]
  sortedByCategory?: boolean
  comparePeriod?: string
  timeInterval?: TimeInterval
  isPreviousChartLoading?: boolean
  hideParentCategories?: boolean
  disableGrouping?: boolean
  tier1: string
  showCategories: boolean | undefined
}
const ChartTools: React.FC<Props> = ({
  wrapperRef,
  chartWrapperRef,
  currentChart,
  chartData,
  localDashboardControls,
  updateLocalDashboardControls,
  isEditing,
  isDelta,
  isUnlinked,
  setChartId,
  currentPage,
  pageSize,
  order,
  toggleOrder,
  onPageChange,
  prevRange,
  fontSize,
  comparativeIndex,
  isFullScreen,
  expandedCategories,
  sortedByCategory,
  comparePeriod,
  timeInterval,
  isPreviousChartLoading,
  hideParentCategories,
  disableGrouping,
  tier1,
  showCategories,
}) => {
  const route = useProjectStore((state: ProjectState) => state.route)
  const dateWindows = useProjectStore((state) => state.dateWindows)

  const { data: feature_flags } = useFeatureFlags()

  const updateDashboardControls = useProjectStore(
    (state: ProjectState) => state.updateDashboardControls,
  )

  const chartValues = useMemo(() => {
    if (
      currentChart?.chart_type === ChartTypes.TABLE &&
      hideParentCategories &&
      (currentChart as TableChartType)?.values
    ) {
      return (currentChart as TableChartType).values
    }

    const reactiveShowMyBrands =
      !currentChart.category_mapping ||
      (showCategories === undefined ? tier1 === 'Theme' : showCategories)

    if (reactiveShowMyBrands) {
      return getGroupedChartValues(
        currentChart,
        expandedCategories ?? [],
        !!sortedByCategory,
        order ?? 'desc',
        disableGrouping,
      )
    } else {
      return (currentChart as TableChartType).values
    }
  }, [currentChart, expandedCategories, hideParentCategories])

  const [csvTableData, setCsvTableData] = useState<any>({
    headers: [],
    data: [],
  })
  const [isCopying, setIsCopying] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)

  useEffect(() => {
    if (currentChart?.chart_type === ChartTypes.TABLE) {
      const headers: any[] = []
      ;(currentChart as TableChartType).col_titles.forEach((item, index) => {
        headers.push({
          label: item,
          key: (currentChart as TableChartType).col_keys[index],
        })
      })
      setCsvTableData({
        headers,
        data: chartValues,
      })
    }
  }, [currentChart, chartValues])

  return (
    <Tools className={'tools'} data-html2canvas-ignore={'true'}>
      {(currentChart.chart_type === ChartTypes.BAR ||
        currentChart.chart_type === ChartTypes.STACK_BAR) &&
        !matrixChartIds.includes(currentChart.chart_id) && (
          <div
            style={{
              marginRight: 10,
              paddingRight: 10,
              borderRight: '1px solid lightgrey',
              display: 'flex',
              gap: 10,
              alignItems: 'center',
            }}
          >
            <Pagination
              size={'small'}
              showSizeChanger
              onChange={onPageChange}
              defaultCurrent={1}
              current={currentPage}
              total={chartValues?.length}
              pageSize={pageSize ?? chartValues?.length}
              pageSizeOptions={[5, 10, 20, 50, 100, chartValues?.length].filter(
                (el) => el <= chartValues?.length,
              )}
            />
            <Tooltip title={'Asc/Desc'}>
              <YogiButton
                id="feedback-sort-button"
                type="default"
                onClick={toggleOrder}
                style={{ padding: '0 10px' }}
              >
                {orderNames[order] ?? orderNames['desc']}
              </YogiButton>
            </Tooltip>
          </div>
        )}

      {feature_flags?.yoy_charts &&
        (currentChart.chart_type === ChartTypes.LINE ||
          currentChart.chart_type === ChartTypes.BAR) && (
          <CompareInput
            updateLocalDashboardControls={updateLocalDashboardControls}
            updateDashboardControls={updateDashboardControls}
            comparativeIndex={comparativeIndex}
            comparePeriod={comparePeriod}
            timeInterval={timeInterval}
            isPreviousChartLoading={isPreviousChartLoading}
          />
        )}

      {currentChart.chart_type === ChartTypes.TABLE && !!prevRange && (
        <>
          {currentChart.category_mapping && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: 5,
              }}
            >
              <Checkbox
                checked={
                  showCategories === undefined
                    ? tier1 === 'Theme'
                    : showCategories
                }
                onChange={(event) => {
                  mixpanel.track('chart config', {
                    action: 'show competitors/my brands',
                    value: event.target.checked,
                    ...route,
                  })
                  updateDashboardControls(
                    comparativeIndex,
                    'showCategories',
                    event.target.checked,
                  )
                }}
              >
                Group {tier1}s
              </Checkbox>
            </div>
          )}
          <Tooltip
            title={
              <>
                <div>
                  {isDelta ? 'Show Difference: On' : 'Show Difference: Off'}
                </div>
                <div>
                  Comparison Period:{' '}
                  {findDateWindow(dateWindows, prevRange?.min, prevRange?.max)
                    ?.label ?? getAdjustedDateRangeString(prevRange)}
                </div>
              </>
            }
          >
            <Button
              type={isDelta ? 'primary' : 'default'}
              icon={<ColumnWidthOutlined />}
              onClick={() => {
                mixpanel.track('toggle table chart', {
                  ...route,
                  value: currentChart.chart_id,
                })
                updateLocalDashboardControls
                  ? updateLocalDashboardControls('isDelta', !isDelta)
                  : updateDashboardControls(
                      comparativeIndex,
                      'isDelta',
                      !isDelta,
                    )
              }}
              style={{
                border: `2px solid ${isDelta ? buttonBlue : cardBorderGrey}`,
                borderRadius: '4px',
                boxShadow: 'none',
                marginRight: '10px',
                color: isDelta ? '#fff' : buttonBlue,
                background: isDelta ? buttonBlue : '#fff',
              }}
            />
          </Tooltip>
        </>
      )}
      {route.isDashboard && updateLocalDashboardControls && (
        <Tooltip
          title={`Unlinked charts can include data outside of the Dashboard Filter\n\nCurrent Status:${
            isUnlinked ? ' Unlinked' : ' Linked'
          }`}
          placement={'topLeft'}
        >
          <Button
            type={isUnlinked ? 'default' : 'primary'}
            icon={<LinkOutlined />}
            onClick={() => {
              mixpanel.track('toggle component link', {
                ...route,
                value: !isUnlinked,
              })
              updateLocalDashboardControls('isUnlinked', !isUnlinked)
            }}
            style={{
              border: `2px solid ${!isUnlinked ? buttonBlue : cardBorderGrey}`,
              borderRadius: '4px',
              boxShadow: 'none',
              marginRight: '10px',
              color: !isUnlinked ? '#fff' : buttonBlue,
              background: !isUnlinked ? buttonBlue : '#fff',
            }}
          />
        </Tooltip>
      )}
      {!isEditing && (
        <>
          <Tooltip title={tooltip['copy-button']} placement={'topLeft'}>
            <Button
              type="text"
              icon={<CopyOutlined />}
              loading={isCopying}
              onClick={async () => {
                setIsCopying(true)
                mixpanel.track('chart download', {
                  action: 'copy',
                  ...route,
                  value: currentChart.chart_id,
                })

                onCopyChart(
                  wrapperRef,
                  chartWrapperRef,
                  currentChart.title,
                ).then(() => {
                  setIsCopying(false)
                })
              }}
              style={{
                border: `2px solid ${cardBorderGrey}`,
                borderRadius: '4px',
                boxShadow: 'none',
                marginRight: '10px',
                background: '#fff',
              }}
            />
          </Tooltip>
          <Tooltip title={tooltip['print-button']} placement={'topLeft'}>
            <Button
              type="text"
              icon={<DownloadOutlined />}
              loading={isPrinting}
              onClick={() => {
                setIsPrinting(true)
                mixpanel.track('chart download', {
                  action: 'image',
                  ...route,
                  value: currentChart.chart_id,
                })
                onCopyChart(
                  wrapperRef,
                  chartWrapperRef,
                  currentChart.title,
                  true,
                ).then(() => {
                  setIsPrinting(false)
                })
              }}
              style={{
                border: `2px solid ${cardBorderGrey}`,
                borderRadius: '4px',
                boxShadow: 'none',
                marginRight: '10px',
              }}
            />
          </Tooltip>
          {currentChart.chart_type === ChartTypes.TABLE ? (
            <Tooltip title={tooltip['csv-download']} placement={'topLeft'}>
              <CSVLink
                headers={csvTableData.headers}
                data={csvTableData.data}
                filename={`${currentChart.title}.csv`}
              >
                <Button
                  type="text"
                  icon={<TableOutlined style={{ marginRight: 8 }} />}
                  onClick={() => {
                    mixpanel.track('csv download', {
                      action: 'csv chart',
                      value: currentChart.chart_id,
                      ...route,
                    })
                  }}
                  style={{
                    border: `2px solid ${cardBorderGrey}`,
                    borderRadius: '4px',
                    boxShadow: 'none',
                    marginRight: '10px',
                  }}
                >
                  CSV
                </Button>
              </CSVLink>
            </Tooltip>
          ) : (
            <>
              {'values' in currentChart && !!chartValues?.length && (
                <Tooltip title={tooltip['csv-download']} placement={'topLeft'}>
                  <Button
                    type="text"
                    icon={<TableOutlined style={{ marginRight: 8 }} />}
                    onClick={() => {
                      mixpanel.track('csv download', {
                        action: 'csv chart',
                        value: currentChart.chart_id,
                        ...route,
                      })
                      if (currentChart) {
                        try {
                          downloadChartAsCSV(currentChart, chartValues)
                          toast.success('CSV downloaded')
                        } catch (e) {
                          console.error(e)
                          toast.error('Error downloading CSV')
                        }
                      }
                    }}
                    style={{
                      border: `2px solid ${cardBorderGrey}`,
                      borderRadius: '4px',
                      boxShadow: 'none',
                      marginRight: '10px',
                    }}
                  >
                    CSV
                  </Button>
                </Tooltip>
              )}
            </>
          )}
          {currentChart.other_charts?.length > 0 && (
            <Select
              style={{
                maxWidth: '200px',
                fontSize: fontSizeDict[fontSize || 'medium'],
                marginRight: 10,
              }}
              value={currentChart.title}
              onChange={(value) => {
                const foundChart = currentChart?.other_charts.find(
                  (c) => c.chart_id === value,
                )
                if (foundChart) {
                  setChartId
                    ? setChartId(foundChart.chart_id as string)
                    : updateDashboardControls(
                        0,
                        'chartId',
                        foundChart.chart_id as string,
                      )
                }
              }}
            >
              {currentChart.other_charts.map((chart) => (
                <Select.Option
                  id="chart-option"
                  value={chart.chart_id}
                  selected={chart.chart_id === currentChart?.chart_id}
                  key={chart.chart_id}
                  style={{
                    fontSize: fontSizeDict[fontSize || 'medium'],
                  }}
                >
                  {chart.title}
                </Select.Option>
              ))}
            </Select>
          )}
        </>
      )}
      {!!feature_flags?.allow_fullscreen && (
        <Button
          type="text"
          icon={<FullscreenRounded />}
          onClick={() => {
            updateLocalDashboardControls
              ? updateLocalDashboardControls('isFullScreen', !isFullScreen)
              : updateDashboardControls(
                  comparativeIndex,
                  'isFullScreen',
                  !isFullScreen,
                )
          }}
          style={{
            border: `2px solid ${cardBorderGrey}`,
            borderRadius: '4px',
            boxShadow: 'none',
            // marginRight: '10px',
          }}
        />
      )}

      {chartData && localDashboardControls && updateLocalDashboardControls && (
        <ChartSettings
          dashboardData={chartData}
          dashboardControls={localDashboardControls}
          updateDashboardControls={updateLocalDashboardControls}
        />
      )}
    </Tools>
  )
}

export default ChartTools

const Tools = styled.div`
  display: flex;
  align-items: center;
`
