import React, { useEffect, useMemo, useState } from 'react'
import useResizeObserver from 'use-resize-observer'
import styled from 'styled-components'
import { Loader } from 'shared/components'
import { Header } from './components/header'
import { Chart, ChartWrapper } from './components/chart'
import { HeaderFilterPanel } from '../filters'
import mixpanel from 'features/trackers/mixpanel'
import { initialState, useProjectStore } from '../../projectStore/projectStore'
import { ProjectState } from '../../types'
import _ from 'lodash'
import { isInitialFlow } from '../../../home/utils'
import { useChartData } from '../../hooks/useChartData'
import { useQuery } from 'react-query'
import { fetchChartsForSearching } from './model'
import { Fetcher } from '../../../../shared/components/fetcher'
import { DashboardChartResponse } from './types'
import { AppliedFilterOptionSelect, FilterOptionSelect } from '../filters/types'
import { emptyFilters } from '../../utils'
import { Select } from 'antd'
import datasetGraphic from 'assets/images/empty/datasetGraphic.png'
import DatasetSelector from 'features/project/features/charts/components/DatasetSelector/DatasetSelector'
import Superset from 'components/Superset/Superset'
import { useFeatureFlags } from 'features/project/hooks/useFeatureFlags'

export const Charts: React.FC = () => {
  return <DashboardContentWrapper />
}

const DashboardContentWrapper = () => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const details = useProjectStore((state: ProjectState) => state.details)
  const comparativePanelsNumber = useProjectStore(
    (state: ProjectState) => state.comparativePanelsNumber,
  )
  const updateDashboardControls = useProjectStore(
    (state) => state.updateDashboardControls,
  )
  const updateChartData = useProjectStore((state) => state.updateChartData)
  const updateCurrentChartList = useProjectStore(
    (state) => state.updateCurrentChartList,
  )
  const { tier1, chartId } = useProjectStore(
    (state) => state.dashboardControls[0],
  )

  const { ref, height } = useResizeObserver<HTMLDivElement>()
  const chartData = useProjectStore((state: ProjectState) => state.chartData)

  useEffect(() => {
    mixpanel.track('dashboard page')
  }, [])

  const [loading, setLoading] = useState(true)

  const { data: chartList } = useQuery(
    ['dashboard-new-charts-search', projectId],
    () => fetchChartsForSearching(projectId),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !!projectId,
    },
  )

  const { data: defaultChart } = useChartData(
    0,
    emptyFilters,
    undefined,
    undefined,
    'post',
    'month',
    0,
  )

  useEffect(() => {
    if (defaultChart) {
      updateChartData(0, defaultChart)

      if (!chartId) {
        updateDashboardControls(0, 'chartId', defaultChart.charts[0].chart_id)
      }
      // some options, such as brand, do not hold their lens value in the charts list. so we use this function to update the UI with the lens returned from the charts response
      // this causes a double fetch, TODO how to optimize here
      // https://linear.app/yo-yo-gi/issue/EFE-107/header-chart-list-some-dont-have-a-field-associated-with-them-to
      if (!tier1) {
        const value =
          defaultChart.tier1.options[defaultChart.tier1.default].name
        if (value) {
          updateDashboardControls(0, 'tier1', value)
        }
      }

      updateCurrentChartList(0, defaultChart.charts[0])
    }
  }, [defaultChart])

  useEffect(() => {
    if (chartList && defaultChart) {
      setLoading(false)
    }
  }, [chartList, defaultChart])

  if (isInitialFlow(details.state) || loading) {
    return (
      <>
        <Loader />
        <LoaderTitle>{`Loading ${details?.clientSettings.project.pages.dashboard.alias} Page`}</LoaderTitle>
      </>
    )
  }

  return (
    <Container>
      <Header resizeRef={ref} headerHeight={height} />
      <Wrapper2>
        {_.times(comparativePanelsNumber, (index) => (
          <Wrapper key={index}>
            <DashboardContent
              // @ts-ignore
              headerHeight={height}
              comparativeIndex={index}
              data={chartData[index]}
              // isMultiFetching={isFetching}
              isMultiFetching={false}
            />
          </Wrapper>
        ))}
      </Wrapper2>
    </Container>
  )
}

type Props = {
  comparativeIndex: number
  headerHeight?: number
  data: DashboardChartResponse
  isMultiFetching: boolean
}

const DashboardContent: React.FC<Props> = ({
  comparativeIndex,
  headerHeight,
  isMultiFetching,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const details = useProjectStore((state: ProjectState) => state.details)
  const filterValues = useProjectStore(
    (state: ProjectState) => state.filters[comparativeIndex],
  )
  const updateFilterValues = useProjectStore(
    (state: ProjectState) => state.updateFilterValues,
  )
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList,
  )
  const isComparative = useProjectStore(
    (state: ProjectState) => state.isComparative,
  )
  const updateCurrentChartList = useProjectStore(
    (state: ProjectState) => state.updateCurrentChartList,
  )
  const hasLoadedFilters = useProjectStore(
    (state: ProjectState) => state.hasLoadedFilters,
  )
  const clearOpenedOptions = useProjectStore(
    (state: ProjectState) => state.clearOpenedOptions,
  )
  const updateDashboardControls = useProjectStore(
    (state) => state.updateDashboardControls,
  )
  const updateChartData = useProjectStore((state) => state.updateChartData)
  const { tier1, chartId, postType, timeInterval, reviewsCount } =
    useProjectStore((state) => state.dashboardControls[0])

  const { data: feature_flags } = useFeatureFlags()

  const { ref: legendRef, width: legendWidth } =
    useResizeObserver<HTMLDivElement>()

  const { ref: splitRef, width: splitWidth } =
    useResizeObserver<HTMLDivElement>()

  const datasetOptions = useMemo(() => {
    return [
      ...((
        defaultFilterList.find(
          (el) => el.field === 'proj_uuid',
        ) as FilterOptionSelect
      )?.values.map((el) => ({ value: el.cluster_uuid, label: el.value })) ||
        []),
      { value: '', label: 'All' },
    ]
  }, [defaultFilterList])

  const [showSelectDataset, setShowSelectDataset] = useState(
    datasetOptions.length > 1 &&
      (_.isEqual(filterValues, initialState.filters[0]) ||
        _.isEqual(filterValues, emptyFilters)),
  )

  const selectedDataset = useMemo(
    () =>
      (
        filterValues?.values.find(
          (el) => el.field === 'proj_uuid',
        ) as AppliedFilterOptionSelect
      )?.values[0],
    [filterValues?.values],
  )

  useEffect(() => {
    if (selectedDataset) {
      setShowSelectDataset(false)
    }
  }, [selectedDataset])

  useEffect(() => {
    if (
      !(
        _.isEqual(filterValues, initialState.filters[0]) ||
        _.isEqual(filterValues, emptyFilters)
      )
    ) {
      setShowSelectDataset(false)
    }
  }, [filterValues])

  const { data: dashboardData, isFetching } = useChartData(
    comparativeIndex,
    filterValues,
    tier1,
    chartId,
    postType,
    timeInterval,
    reviewsCount,
    showSelectDataset,
  )

  const [initialLegendWidthPercent, setInitialLegendWidthPercent] = useState(0)

  useEffect(() => {
    if (isFetching) {
      setInitialLegendWidthPercent(
        legendWidth ? (legendWidth / (splitWidth || 1)) * 100 : 0,
      )
    }
  }, [isFetching])

  const data = dashboardData

  useEffect(() => {
    if (dashboardData) {
      updateChartData(comparativeIndex, dashboardData)
      if (comparativeIndex === 0) {
        if (!chartId) {
          updateDashboardControls(
            0,
            'chartId',
            dashboardData.charts[0].chart_id,
          )
        }
        // some options, such as brand, do not hold their lens value in the charts list. so we use this function to update the UI with the lens returned from the charts response
        // this causes a double fetch, TODO how to optimize here
        // https://linear.app/yo-yo-gi/issue/EFE-107/header-chart-list-some-dont-have-a-field-associated-with-them-to
        if (!tier1) {
          const value =
            dashboardData.tier1.options[dashboardData.tier1.default].name
          if (value) {
            updateDashboardControls(0, 'tier1', value)
          }
        }
      }
      updateCurrentChartList(comparativeIndex, dashboardData.charts[0])
    }
  }, [dashboardData])

  // Whenever a filter is applied, opened options should be reset
  useEffect(() => {
    clearOpenedOptions(comparativeIndex)
  }, [filterValues])

  const setExpandedCategories = (categories: string[] | undefined) => {
    updateDashboardControls(comparativeIndex, 'expandedCategories', categories)
  }

  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if (initialized) {
      setExpandedCategories([])
    } else {
      setInitialized(true)
    }
  }, [tier1])

  const showSupersetDatasetSelector =
    feature_flags?.superset &&
    !filterValues.values.find((el) => el.field === 'proj_uuid')

  if (showSupersetDatasetSelector) {
    return (
      <>
        <HeaderFilterPanel
          // reset={reset}
          // settings={settings}
          filterValues={filterValues}
          comparativeIndex={comparativeIndex}
        />
        <ChartsContainer
          style={{ maxHeight: `calc(100vh - 120px - ${headerHeight}px)` }}
        >
          <Superset comparativeIndex={comparativeIndex} />
        </ChartsContainer>
      </>
    )
  }

  if (
    isInitialFlow(details?.state) ||
    !defaultFilterList.length ||
    !hasLoadedFilters
  ) {
    return (
      <>
        <Loader />
        <LoaderTitle>{`Loading ${details?.clientSettings.project.pages.dashboard.alias} Page`}</LoaderTitle>
      </>
    )
  }

  return (
    <>
      <HeaderFilterPanel
        filterPanelHeight={`calc(100vh - 180px - ${headerHeight}px)`}
        filterValues={filterValues}
        comparativeIndex={comparativeIndex}
      />
      <ChartsContainer
        style={{ maxHeight: `calc(100vh - 120px - ${headerHeight}px)` }}
      >
        <ChartsWrapper
          chartType={data?.charts[0]?.chart_type}
          chartCount={data?.charts?.length || 1}
        >
          {showSelectDataset ? (
            <DatasetWrapper>
              <SelectorWrapper>
                <div style={{ fontSize: 20, fontWeight: 500, color: 'white' }}>
                  Select A Dataset
                </div>
                <Select
                  placeholder={'Choose a Dataset'}
                  options={datasetOptions}
                  onChange={(value: any) => {
                    if (!value) {
                      setShowSelectDataset(false)
                      return
                    }
                    updateFilterValues(comparativeIndex, 'proj_uuid', [value])
                    setShowSelectDataset(false)
                    mixpanel.track('select dataset', {
                      dataset: value,
                    })
                  }}
                  value={selectedDataset}
                  style={{ minWidth: 300, marginTop: 5 }}
                />
              </SelectorWrapper>
              <img
                src={datasetGraphic}
                style={{
                  width: '80%',
                  maxHeight: '50%',
                  objectFit: 'contain',
                  marginTop: '50px',
                }}
              />
            </DatasetWrapper>
          ) : (
            <>
              {!data ? (
                <ChartWrapper />
              ) : (
                <>
                  {data?.charts?.map((chart) => (
                    <Chart
                      key={chart.chart_id}
                      filters={filterValues}
                      chart={chart}
                      comparativeIndex={comparativeIndex}
                      isComparative={isComparative}
                      legendRef={legendRef}
                      splitRef={splitRef}
                      initialLegendWidthPercent={initialLegendWidthPercent}
                      // setExpandedCategories={setExpandedCategories}
                    />
                  ))}
                </>
              )}
            </>
          )}
        </ChartsWrapper>
        {(isFetching || isMultiFetching) && (
          <LoadingLayer>
            <Fetcher />
          </LoadingLayer>
        )}
      </ChartsContainer>
    </>
  )
}

type ChartWrapperProps = {
  params: {
    chartType?: string
    chartCount: number
  }
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  //min-height: calc(100vh - 40px);
  max-height: calc(100vh - 40px);
  height: 100%;
  width: 100%;
  overflow: hidden;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  //min-height: calc(100vh - 40px);
  max-height: calc(100vh - 40px);
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 10px 0px;
`

const Wrapper2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  //min-height: calc(100vh - 40px);
  //max-height: calc(100vh - 40px);
  height: 100%;
  width: 100%;
  padding: 0px 10px;
  gap: 10px;
`
const LoadingLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const ChartsContainer = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius);
  box-shadow: var(--light-box-shadow);
`

export const ChartsWrapper = styled.div<{
  chartType?: string
  chartCount: number
}>`
  height: ${({ chartType }) =>
    chartType === 'numerical_charts' ? 'auto' : '100%'};
  grid-gap: 10px;
  display: ${({ chartType }) =>
    chartType === 'numerical_charts' ? 'flex' : 'block'};
  flex-direction: ${({ chartType }) =>
    chartType === 'numerical_charts' ? 'column' : 'row'};
  grid-template-columns: ${({ chartCount }) =>
    chartCount > 2 ? '1fr 1fr' : '1fr'};
`

const LoaderTitle = styled.div`
  text-align: center;
  font-size: var(--font-size-l);
  font-weight: 500;
`

const DatasetWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  border-radius: var(--border-radius);
  //min-height: inherit;
  background: white;
  //background: #c5d7e0;
  overflow-x: auto;
`

const SelectorWrapper = styled.div`
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  //gap: 20px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: calc(var(--border-radius) * 2);
  background-color: rgba(243, 134, 105, 0.85);
`
