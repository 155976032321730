import React, {
  useState,
  useEffect,
  createContext,
  useCallback,
  useContext,
} from 'react'
import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { User, AuthContextInterface, UserDetailsResponse } from '../types'
import { Loader } from 'shared/components'
import {
  signinURL,
  setupPasswordURL,
  newPasswordURL,
  // serviceDashboardURL,
  // serviceFeedbackURL,
  // serviceKeywordsURL,
  // serviceNetworkURL,
  signinCallbackURL,
  trialSignupURL,
  requestAccessURL,
} from 'utils/links'
import { axiosInstance, setToken, hasTemporaryToken } from 'utils/axios'
import tracker from 'features/trackers/crisp'
import mixpanel from 'features/trackers/mixpanel'
import { fetchUser } from '../model'
import { useQueryClient } from 'react-query'

const initialValue = {
  user: null,
  username: null,
  updateUsername: (p: string) => {},
  isAuthenticated: false,
  signIn: (p: User) => {},
  signOut: () => {},
  setUser: (p: User) => {},
  userDetails: null,
}

export const AuthContext = createContext<AuthContextInterface>(initialValue)
export const AuthContainer: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const history = useHistory()
  const location = useLocation()
  const [loading, setLoading] = useState<boolean>(true)
  const [user, setUser] = useState<AuthContextInterface['user']>(null)
  const [userDetails, setUserDetails] = useState<UserDetailsResponse | null>(
    null,
  )
  const [authenticated, setAuthenticated] = useState(false)
  const [username, updateUsername] =
    useState<AuthContextInterface['username']>(null)

  const queryClient = useQueryClient()

  useEffect(() => {
    if (userDetails) {
      const { email, first_name, last_name } = userDetails
      tracker.login(email?.toLowerCase(), first_name, last_name)
      mixpanel.identify(email?.toLowerCase())
      mixpanel.track('login', { date: new Date().toISOString() })

      try {
        // @ts-ignore
        window.clarity('set', 'email', email?.toLowerCase())
      } catch (e) {
        // do nothing
      }
    }
  }, [userDetails])

  const signIn = useCallback(async (user: User) => {
    setUser(user)
    setAuthenticated(true)

    setToken(user.signInUserSession.idToken.jwtToken)

    const email = user.attributes.email?.toLowerCase()
    const name = user.attributes.given_name + ' ' + user.attributes.family_name
    mixpanel.identify(email)
    mixpanel.set({
      $email: email,
      $name: name,
      USER_ID: email,
      cognito_id: user.attributes.sub,
    })

    // @ts-ignore
    window.clarity('set', 'email', email)

    try {
      const currentUserDetailsResponse = await fetchUser()
      setUserDetails(currentUserDetailsResponse.data)
    } catch (e: any) {
      if (e?.response?.status === 404) {
        history.push(requestAccessURL)
      }
    }
  }, [])

  const signOut = useCallback(async () => {
    await Auth.signOut()
    setAuthenticated(false)
    setUser(null)
    axiosInstance.defaults.headers.common['Authorization'] = null
    mixpanel.reset()
    queryClient.clear()
    history.push(signinURL)
  }, [])

  useEffect(() => {
    const checkAuth = async () => {
      try {
        setLoading(true)
        const user: User = await Auth.currentAuthenticatedUser()

        signIn(user)
        updateUsername(null)
      } catch (e) {
        if ([trialSignupURL].includes(location.pathname)) {
          history.push(trialSignupURL)
        } else if (
          !hasTemporaryToken() &&
          ![
            setupPasswordURL,
            newPasswordURL,
            // serviceDashboardURL,
            // serviceNetworkURL,
            // serviceFeedbackURL,
            // serviceKeywordsURL,
          ].includes(location.pathname) &&
          ![setupPasswordURL, newPasswordURL, signinCallbackURL].includes(
            location.pathname,
          )
        ) {
          if (
            history?.location?.pathname &&
            history.location.pathname !== '/' &&
            history.location.pathname !== '/sign-in'
          ) {
            history.push(
              `${signinURL}?redirect=${location.pathname}${location.search}`,
            )
          } else {
            history.push(`${signinURL}`)
          }
        }
      } finally {
        setLoading(false)
      }
    }

    checkAuth()
  }, [])

  if (loading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    )
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        username,
        updateUsername,
        userDetails,
        isAuthenticated: authenticated,
        signIn,
        signOut,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuthContext must be used within a AuthContext.')
  }
  return context
}

const LoaderWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
