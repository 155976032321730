import { BarChart } from '../types'
import {
  getGroupedChartValues,
  interpolateRatingColor,
  interpolateSentimentColor,
  splitLabelForChart,
} from '../utils'

export const configureMatrixChart = (
  currentChart: BarChart,
  tier1: string,
  showCategories: boolean,
  matrixScaleVolume?: boolean,
  matrixSort?: boolean,
  matrixSortAxis?: string,
  matrixSortValue?: string,
  matrixSpectrumColors?: boolean,
  matrixFlipAxis?: boolean,
  expandedCategories?: string[],
  isComparative: boolean = false,
  fullAxis?: boolean,
  wrapLabels?: boolean,
  maxAxesLength?: number,
): any => {
  if (!currentChart.values) return
  const data: any = {
    datasets: [],
  }
  const isRating = !!currentChart.values[0].rating

  let values = currentChart.values

  values = getGroupedChartValues(
    currentChart,
    expandedCategories ?? [],
    false,
    'desc',
  )

  const chartData = values.map((value: any) => ({
    x: value.category.split(' - ')[matrixFlipAxis ? 1 : 0],
    y: value.category.split(' - ')[matrixFlipAxis ? 0 : 1],
    v: value.sentiment ?? value.rating,
    volume: value.volume,
    color: value.color,
    hover: value.hover,
  }))

  if (matrixSort && matrixSortValue && matrixSortAxis === 'x') {
    sortByColumn(matrixSortValue)
  } else if (matrixSort && matrixSortValue && matrixSortAxis === 'y') {
    sortByRow(matrixSortValue)
  } else {
    chartData.sort((a, b) => {
      return a.y?.localeCompare(b.y)
    })
    chartData.sort((a, b) => {
      return a.x?.localeCompare(b.x)
    })
  }

  // Function to sort by a specific column
  function sortByColumn(columnIndex: string) {
    chartData.sort((a, b) => {
      if (a.x === columnIndex) {
        return -1
      }
      return 1
    })
    chartData.sort((a, b) => {
      if (a.x === columnIndex) {
        return b.v - a.v
      }
      return 0
    })
  }

  // Function to sort by a specific row
  function sortByRow(rowIndex: string) {
    chartData.sort((a, b) => {
      if (a.y === rowIndex) {
        return -1
      }
      return 1
    })
    chartData.sort((a, b) => {
      if (a.y === rowIndex) {
        return b.v - a.v
      }
      return 0
    })
  }

  const colors = values.map((v) => v.color)
  const labels = values.map((v) => v['category'])
  const legend = [
    ...currentChart.legend
      .filter((el) => el.category !== 'Null')
      .map((legendItem) => ({
        color: legendItem.color,
        label: legendItem.category,
        disabled: legendItem.disabled,
      })),
  ]
  data.labels = labels
  //@ts-ignore
  const xLabels = [...new Set(chartData.map((value: any) => value.x))]
  //@ts-ignore
  const yLabels = [...new Set(chartData.map((value: any) => value.y))].reverse()
  const volMax = Math.max(...chartData.map((value: any) => value.volume))
  // TODO need to create a map to brand and have the pctmax per brand
  const lensName = currentChart.x_title.split('-')[1]
  const lensToPercentVolume = new Map()
  chartData.forEach((value: any) => {
    if (lensToPercentVolume.has(value.y)) {
      lensToPercentVolume.set(
        value.y,
        Math.max(
          lensToPercentVolume.get(value.y),
          value.hover[`% Volume by ${matrixFlipAxis ? 'Theme' : 'Brand'}`],
        ),
      )
    } else {
      lensToPercentVolume.set(
        value.y,
        value.hover[`% Volume by ${matrixFlipAxis ? 'Theme' : 'Brand'}`],
      )
    }
  })
  const pctMax = Math.max(
    ...chartData.map(
      (value: any) =>
        value.hover[`% Volume by ${matrixFlipAxis ? 'Theme' : 'Brand'}`],
    ),
  )
  const dataset: any = {
    label: `Theme ${lensName} ${currentChart.y_title} Matrix`,
    data: chartData,
    backgroundColor(context: any) {
      let color = context.dataset.data[context.dataIndex]?.color
      let isUnfocused = color?.toString().includes(', 0.1')
      if (matrixSpectrumColors) {
        if (!isRating) {
          color = interpolateSentimentColor(
            context.dataset.data[context.dataIndex]?.v,
            isUnfocused,
          )
        } else {
          color = interpolateRatingColor(
            context.dataset.data[context.dataIndex]?.v,
            isUnfocused,
          )
        }
      }
      return color
    },
    borderColor(context: any) {
      const color = context.dataset.data[context.dataIndex]?.color
      return color
    },
    width({ dataset, dataIndex, chart }: any) {
      return (chart.chartArea || {}).width / xLabels.length - 5
    },
    height({ dataset, dataIndex, chart }: any) {
      return (chart.chartArea || {}).height / yLabels.length - 5
    },
  }

  // dataset.width = ({ dataset, dataIndex, chart }) =>
  //   ((chart.chartArea || {}).width / xLabels.length) *
  //   (dataset.data[dataIndex].volume / volMax)
  if (matrixScaleVolume) {
    dataset.width = ({ dataset, dataIndex, chart }: any) =>
      (chart.chartArea || {}).width / xLabels.length - 1
    dataset.height = ({ dataset, dataIndex, chart }: any) =>
      Math.max(
        (((chart.chartArea || {}).height / yLabels.length) *
          // (Math.log(dataset.data[dataIndex].volume) / Math.log(volMax)),
          dataset.data[dataIndex]?.hover[
            `% Volume by ${matrixFlipAxis ? 'Theme' : 'Brand'}`
          ]) /
          lensToPercentVolume.get(dataset.data[dataIndex]?.y),
        2,
      )
  }
  // dataset.borderWidth = ({ dataset, dataIndex, chart }) =>
  //   ((chart.chartArea || {}).width / xLabels.length -
  //     ((chart.chartArea || {}).width / xLabels.length) *
  //       (dataset.data[dataIndex].volume / volMax)) /
  //   2

  data.datasets.push(dataset)

  const maxAxesLabelLength = isComparative ? 15 : 30

  return {
    type: 'matrix',
    data: data,
    legend,
    options: {
      plugins: {
        datalabels: false,
        legend: false,
        tooltip: {
          callbacks: {
            title() {
              return ''
            },
            label(context: any) {
              const v = context.dataset.data[context.dataIndex]
              return [
                `${lensName}: ` + matrixFlipAxis ? v.x : v.y,
                'Theme: ' + matrixFlipAxis ? v.y : v.x,
                (isRating ? 'Rating: ' : 'Sentiment: ') + v.v,
                '# of Sentences: ' + v.volume,
                `% Volume of ${lensName}: ` +
                  v.hover['% Volume by Brand']?.toFixed(2) +
                  '%',
                '% Volume of Theme: ' +
                  v.hover['% Volume by Theme']?.toFixed(2) +
                  '%',
              ]
            },
          },
        },
      },

      layout: {
        padding: {
          left: 20,
          right: 20,
        },
      },
      animation: {
        colors: {
          type: 'color',
          duration: 300,
        },
      },
      responsive: true,
      resizeDelay: 0,
      maintainAspectRatio: false,
      onHover: (event: any, chartElement: any) => {
        event.native.target.style.cursor = chartElement[0]
          ? 'pointer'
          : 'default'
      },
      scales: {
        x: {
          type: 'category',
          labels: xLabels,
          ticks: {
            display: true,
          },
          grid: {
            display: false,
          },
        },
        y: {
          type: 'category',
          labels: yLabels,
          offset: true,
          ticks: {
            callback: function (value: any) {
              value = (this as any).getLabelForValue(value)
              if (wrapLabels) {
                return splitLabelForChart(value, maxAxesLength || 30)
              }
              return fullAxis
                ? value
                : value?.length > maxAxesLabelLength
                  ? value.substring(0, maxAxesLabelLength) + '...'
                  : value
            },
          },
          grid: {
            display: false,
          },
        },
      },
    },
    //TODO legend
    // legend: sortLegend(
    //   (currentChart as StackBarChart).legend.map((item) => ({
    //     ...item,
    //     label: item.category,
    //   })),
    //   (currentChart as StackBarChart).legend_order
    // ),
  }
}
