import React, { useState, useCallback, useEffect, useContext } from 'react'
import { Modal } from 'antd'
import styled from 'styled-components'
import { Input, Select } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useApiCall } from 'shared/hooks'
import { PageWrapper } from 'shared/wrappers'
import { ProjectNewForm } from 'features/project-form'
import {
  removeProject,
  getProjectMeta,
  fetchGroupProjectsBySearch,
} from '../model'
import { ProjectList } from '../components'
import { Helmet } from 'react-helmet'
import { useHomeContext, HomeProvider } from '../home-provider'
import { useProjectStore } from '../../project/projectStore/projectStore'
import { ProjectState } from '../../project/types'
import { useQuery, useQueryClient } from 'react-query'
import { projectURL } from '../../../utils/links'
import { useHistory } from 'react-router-dom'
import { Loader } from '../../../shared/components'
import { AuthContext } from '../../auth'

const { confirm } = Modal

export const HomeContainer = () => {
  // reset global state when switching projects
  const reset = useProjectStore((state: ProjectState) => state.reset)
  reset()
  const queryClient = useQueryClient()
  queryClient.resetQueries('project')

  return (
    <HomeProvider>
      <HomeContent />
    </HomeProvider>
  )
}

export const HomeContent = () => {
  // TODO put grouped projects into context?
  const {
    refetch,
    projects,
    loading,
    modalOpened,
    updateState,
    setModalOpened,
    setUpdateState,
    searchTerm,
    searchType,
    searchTypeChanged,
    setSearchTerm,
    setSearchType,
    setSearchTypeChanged,
  } = useHomeContext()
  const authContext = useContext(AuthContext)
  const { data: meta } = useApiCall(getProjectMeta, null, [])
  const history = useHistory()

  const [localSearchTerm, setLocalSearchTerm] = useState(searchTerm)

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchTerm(localSearchTerm), 700)
    return () => clearTimeout(timeOutId)
  }, [localSearchTerm])

  const onSearch = async (e: any) => {
    setLocalSearchTerm(e.target.value)
  }

  const onChange = async (e: any) => {
    if (searchTerm) setSearchTypeChanged(!searchTypeChanged)
    setSearchType(e)
  }

  const handleRemoveProject = useCallback(async (uuid: string) => {
    confirm({
      title: 'Do you want to delete this project?',
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        await removeProject(uuid)
        await refetch()
      },
      onCancel() {},
    })
  }, [])

  const openModal = useCallback(() => {
    setModalOpened(true)
  }, [])

  const { data: groupProjectData, isLoading: groupProjectDataLoading } =
    useQuery(['group-project-search'], () => fetchGroupProjectsBySearch(), {
      refetchOnWindowFocus: false,
    })

  useEffect(() => {
    if (
      groupProjectData?.data?.projects?.length &&
      authContext?.userDetails &&
      !authContext.userDetails.yogi_user &&
      !authContext.userDetails.email.includes('@churchdwight.com')
    ) {
      history.push(
        projectURL(
          groupProjectData.data.projects.find((el) => el.grouped)?.uuid ??
            groupProjectData.data.projects[0].uuid,
        ),
      )
    }
  }, [groupProjectData])

  // this is to pause and make sure we know if we are yogiuser or not before redirecting or keeping on old homepage
  if (groupProjectDataLoading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    )
  }

  return (
    <PageWrapper>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <UnscrollableContainer>
        <ProjectsTitle>Your Projects</ProjectsTitle>
        <Controls>
          <Select
            size="large"
            value={searchType}
            onChange={onChange}
            style={{ marginLeft: 20 }}
          >
            <Select.Option value="project">Search by Project</Select.Option>
            <Select.Option value="product">Search by Product</Select.Option>
            <Select.Option value="brand">Search by Brand</Select.Option>
          </Select>
          <Input
            prefix={<SearchOutlined />}
            size="large"
            allowClear
            placeholder={`Search for ${searchType}`}
            value={localSearchTerm}
            onChange={onSearch}
            style={{ marginLeft: 20, width: '30%' }}
          />
        </Controls>
      </UnscrollableContainer>
      <Projects>
        <ProjectList
          projects={projects}
          loading={loading}
          handleClickNew={openModal}
          handleRemoveProject={handleRemoveProject}
        />
      </Projects>
      {modalOpened && (
        <ProjectNewForm
          reservedKeywords={meta?.reserved_keys || []}
          handleClose={() => {
            setModalOpened(false)
            setUpdateState(!updateState)
          }}
        />
      )}
    </PageWrapper>
  )
}

const Controls = styled.div`
  margin-bottom: 20px;
`

const ProjectsTitle = styled.div`
  font-size: var(--font-size-xxl);
  font-weight: 600;
  margin-bottom: var(--default-padding);
`

const UnscrollableContainer = styled.div`
  width: 100%;
  position: fixed;
  top: 40px;
  z-index: 1;
  padding-top: 36px;
  background: #f9f9f9;
`
const Projects = styled.div`
  padding-top: 222px;
`
const LoaderWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`
