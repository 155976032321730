import React, { useCallback, useEffect, useState } from 'react'
import {
  Control,
  Controller,
  FieldErrors,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form'
import { Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { YogiInput } from '../../../../../../../components/UI/YogiInput'
import {
  alertTypeOptions,
  customLimitsList,
  intervalOptionsList,
  outlierAlertsList,
} from '../../../constants'
import {
  Section,
  ControlsCard,
  CustomButtonGroupItem,
  StyledSelect,
  MinReviewWrapper,
  StyledInputNumber,
} from '../CreateAlert.styles'
import { buttonBlue } from '../../../../../../../assets/styles/variables'
import { AlertType } from '../../../model'
import Divider from '../../../../../../../components/UI/YogiDivider'
import mixpanel from '../../../../../../trackers/mixpanel'
import { useProjectStore } from '../../../../../projectStore/projectStore'
import { ProjectState } from '../../../../../types'
import { debounce } from 'lodash'

const { Option } = StyledSelect

interface Props {
  alert: AlertType | undefined
  control: Control<AlertType>
  setValue: UseFormSetValue<AlertType>
  getValues: UseFormGetValues<AlertType>
  errors: FieldErrors<AlertType>
  selectedTriggerCategory: string
  setSelectedTriggerCategory: (category: string) => void
  trigger: UseFormTrigger<AlertType>
}

const AlertCriteria: React.FC<Props> = ({
  alert,
  control,
  setValue,
  getValues,
  errors,
  selectedTriggerCategory,
  setSelectedTriggerCategory,
  trigger,
}) => {
  // global state
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  // local state
  const [alertType, setAlertType] = useState(
    alert ? alert.alert_type : alertTypeOptions[0].value,
  )
  const [triggerType, setTriggerType] = useState(
    alert ? alert.trigger_type : outlierAlertsList[0].value,
  )

  // consts
  const showCompareValue = selectedTriggerCategory === 'limit'

  // functions
  const handleOutlierDetectionClick = () => {
    if (selectedTriggerCategory === 'outlier') return
    setSelectedTriggerCategory('outlier')
    setValue('trigger_type', 'Change')
    mixpanel.track('alerts', {
      action: 'trigger category',
      value: 'outlier',
      proj_uuid: projectId,
    })
  }

  const handleLimitClick = () => {
    if (selectedTriggerCategory === 'limit') return
    setSelectedTriggerCategory('limit')
    setValue('trigger_type', 'Above')
    mixpanel.track('alerts', {
      action: 'trigger category',
      value: 'limit',
      proj_uuid: projectId,
    })
  }

  const handleAlertTypeChange = (value: string) => {
    mixpanel.track('alerts', {
      action: 'alert type',
      value: value,
      proj_uuid: projectId,
    })
    setValue('alert_type', value)
    setAlertType(value)
  }

  const handleAlertConditionChange = (value: string) => {
    mixpanel.track('alerts', {
      action: 'alert condition',
      value: value,
      proj_uuid: projectId,
    })
    setValue('trigger_type', value)
    setTriggerType(value)
  }

  const debouncedMixpanelTrack = useCallback(
    debounce((action, value) => {
      mixpanel.track('alerts', {
        action: action,
        value: value,
        proj_uuid: projectId,
      })
    }, 1000),
    [projectId],
  )

  // effects
  // put this here because triggering the validation onChange of triggerType
  // evaluates the old form state, so this is the workaround
  useEffect(() => {
    trigger('compare_value')
  }, [triggerType, alertType, getValues().compare_value, trigger])

  return (
    <Section>
      <h3 style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
        Set Criteria
      </h3>
      <ControlsCard>
        <div style={{ display: 'flex' }}>
          <CustomButtonGroupItem
            style={{
              flexBasis: '50%',
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
            }}
            isSelected={selectedTriggerCategory === 'outlier'}
            onClick={handleOutlierDetectionClick}
          >
            Outlier Detection
          </CustomButtonGroupItem>
          <CustomButtonGroupItem
            style={{
              flexBasis: '50%',
              borderTopRightRadius: 10,
              borderBottomRightRadius: 10,
              marginLeft: -1,
            }}
            isSelected={selectedTriggerCategory === 'limit'}
            onClick={handleLimitClick}
          >
            Custom Limits
          </CustomButtonGroupItem>
        </div>
        <div style={{ marginTop: 12 }}>
          <div style={{ color: 'gray' }}>Send an alert when...</div>
          <div
            style={{
              marginTop: '8px',
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
            }}
          >
            <Controller
              name="alert_type"
              control={control}
              render={({ field }) => (
                // @ts-ignore - something about antd doesnt play nice with react-hook-form
                <StyledSelect
                  {...field}
                  // @ts-ignore
                  onChange={handleAlertTypeChange}
                  color={buttonBlue}
                  textColor={'white'}
                  style={{ outlineColor: buttonBlue }}
                >
                  {alertTypeOptions.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.icon}
                      &nbsp;&nbsp;&nbsp;
                      {option.label}
                    </Option>
                  ))}
                </StyledSelect>
              )}
            />
            <span style={{ color: 'gray' }}> has/is </span>
            <Controller
              name="trigger_type"
              control={control}
              render={({ field }) => (
                // @ts-ignore - something about antd doesnt play nice with react-hook-form
                <StyledSelect
                  {...field}
                  style={{ minWidth: '250px' }}
                  // @ts-ignore
                  onChange={handleAlertConditionChange}
                >
                  {(selectedTriggerCategory === 'limit'
                    ? customLimitsList
                    : outlierAlertsList
                  ).map((option) => (
                    <Option key={option.value} value={option.value}>
                      {alertType === 'Volume'
                        ? option.volume_label
                        : option.label}
                    </Option>
                  ))}
                </StyledSelect>
              )}
            />
            {showCompareValue ? (
              <>
                <Controller
                  name="compare_value"
                  control={control}
                  rules={{
                    required: true,
                    pattern: {
                      value: /^-?(\d+|\.\d+|\d+\.\d*)$/,
                      message: 'Please enter a number',
                    },
                    validate: {
                      starRatingRange: (value) => {
                        if (alertType !== 'Star Rating') return true
                        if (['Increased', 'Decreased'].includes(triggerType)) {
                          return (
                            (value >= 0 && value <= 4) ||
                            'Star Rating increase/decrease must be between 0 and 4'
                          )
                        }
                        return (
                          (value >= 1 && value <= 5) ||
                          'Star Rating must be between 1 and 5'
                        )
                      },
                      sentimentRange: (value) => {
                        if (alertType !== 'Sentiment') return true
                        if (['Increased', 'Decreased'].includes(triggerType)) {
                          return (
                            (value >= 0 && value <= 2) ||
                            'Sentiment increase/decrease must be between 0 and 2'
                          )
                        }
                        return (
                          (value >= -1 && value <= 1) ||
                          'Sentiment limit must be between -1 and 1'
                        )
                      },
                      volumeRange: (value) =>
                        alertType !== 'Volume' ||
                        value >= 0 ||
                        'Volume must be a positive number',
                    },
                  }}
                  render={({ field }) => (
                    <YogiInput
                      {...field}
                      onChange={(e) => {
                        field.onChange(e)
                        debouncedMixpanelTrack('threshold', e.target.value)
                      }}
                      title="threshold"
                      placeholder="0"
                      aria-label="threshold"
                      style={{
                        minWidth: '60px',
                        width: '60px',
                        textAlign: 'center',
                        fontStyle: 'normal',
                        border: '2px solid #dde2e5',
                        padding: '9px 16px',
                        borderRadius: '10px',
                      }}
                      isError={!!errors.compare_value}
                    />
                  )}
                />
                <Tooltip
                  overlayStyle={{ maxWidth: '300px' }}
                  title={
                    <div>
                      Star Rating is on a scale of 1 to 5, and Sentiment is on a
                      scale of -1 to 1.
                    </div>
                  }
                >
                  <a style={{ lineHeight: 1, fontSize: 14, marginLeft: 5 }}>
                    <InfoCircleOutlined />
                  </a>
                </Tooltip>
              </>
            ) : (
              <Tooltip
                overlayStyle={{ maxWidth: '400px' }}
                title={
                  <div>
                    <p>
                      Yogi alerts you when there’s an unusual change in a
                      negative direction, positive direction, or either
                      direction. The performance over the selected timeframe is
                      compared to a trailing period to determine if a notable
                      change has occurred.
                    </p>
                    <div>
                      The change threshold for alerts is 0.75 standard
                      deviations from the mean.
                    </div>
                  </div>
                }
              >
                <a style={{ lineHeight: 1, fontSize: 14, marginLeft: 5 }}>
                  <InfoCircleOutlined />
                </a>
              </Tooltip>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '12px',
              gap: 10,
            }}
          >
            <div style={{ color: 'gray' }}>in the last</div>
            <Controller
              name="time_interval"
              control={control}
              render={(
                { field }, // @ts-ignore - something about styled or antd doesnt play nice with react-hook-form
              ) => (
                // @ts-ignore - something about antd doesnt play nice with react-hook-form
                <StyledSelect
                  {...field}
                  onChange={(e: any) => {
                    field.onChange(e)
                    mixpanel.track('alerts', {
                      action: 'time interval',
                      value: e,
                      proj_uuid: projectId,
                    })
                  }}
                  style={{ minWidth: 100 }}
                >
                  {intervalOptionsList.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </StyledSelect>
              )}
            />
          </div>
        </div>
        {!!errors.compare_value && (
          <div style={{ color: 'red', marginTop: 10 }}>
            {errors.compare_value.type === 'required' &&
              selectedTriggerCategory === 'limit' && (
                <div>This field is required.</div>
              )}
            {errors.compare_value.type === 'pattern' && (
              <div>Please enter a valid number.</div>
            )}
            {(errors.compare_value.type === 'starRatingRange' ||
              errors.compare_value.type === 'sentimentRange' ||
              errors.compare_value.type === 'volumeRange') && (
              <div>{errors.compare_value.message}</div>
            )}
          </div>
        )}
        <Divider style={{ marginTop: 12 }} />
        <MinReviewWrapper>
          <div style={{ display: 'flex', gap: '10px' }}>
            <div style={{ marginRight: 'auto' }}>Minimum Review Count</div>
            <Tooltip title="Alerts won't trigger if the number of reviews in period are lower than this number">
              <a>
                <InfoCircleOutlined
                  style={{ marginTop: 3, cursor: 'pointer' }}
                />
              </a>
            </Tooltip>
          </div>
          <Controller
            name="reviews_filter"
            control={control}
            render={({ field }) => (
              // @ts-ignore - something about antd doesnt play nice with react-hook-form
              <StyledInputNumber
                {...field}
                onChange={(e: any) => {
                  field.onChange(e)
                  debouncedMixpanelTrack('minimum review count', e)
                }}
                min={0}
              />
            )}
          />
        </MinReviewWrapper>
      </ControlsCard>
    </Section>
  )
}

export default AlertCriteria
