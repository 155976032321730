import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import { Button, Select, Tooltip } from 'antd'
import styled from 'styled-components'
import {
  buttonBlue,
  cardBorderGrey,
  mainText,
} from '../../../../../../assets/styles/variables'
import { YogiSearch } from '../../../../../../components/UI/YogiSearch'
import mixpanel from '../../../../../trackers/mixpanel'
import { useProjectStore } from '../../../../projectStore/projectStore'
import { ProjectState } from '../../../../types'
import { FilterOptionSelect } from '../../../filters/types'
import { useQuery } from 'react-query'
import { getProductHierarchiesForProject } from '../../../products/model'
import { Loader } from '../../../../../../shared/components'
import Omnibar from '../../../home/ReviewComponent/Omnibar'
import _ from 'lodash'
import { focusFields } from '../../constants'
import { getSourceGroups } from '../../../sourcepage/model'
import { SourceList } from '../../../sourcepage/components'
import { tooltip } from '../../../../../../utils/tooltip-data'
import VirtuosoGridComponent from './components/VIrtuosoGridComponent/VirtuosoGridComponent'

export interface FocusListOption {
  value: string
  n_posts: number
  identifiers: string[]
  product_count?: number
  image_url?: string
  cluster_uuid?: string
}

export interface FocusData {
  title: string
  reviewCount: number
  identifiers: string[]
  productCount?: number
  imageUrl?: string
}

interface FocusListProps {
  setSelectedFocus: (value: string) => void
  selectedLens: string | null
  setSelectedLens: (title: string) => void
  focusListData: FocusListOption[]
  setFocusListData: (value: FocusListOption[]) => void
}

const FocusList: React.FC<FocusListProps> = ({
  setSelectedFocus,
  selectedLens,
  setSelectedLens,
  focusListData,
  setFocusListData,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const details = useProjectStore((state: ProjectState) => state.details)
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList
  )
  const isLLMDash = !!details.clientSettings['llm-dash']

  const { Option } = Select

  const [compareSet, setCompareSet] = useState<string[]>([])

  const containerRef = useRef(null)

  const { isLoading, data: hierarchyData } = useQuery(
    ['getProductHierarchiesForProject', projectId],
    () =>
      getProductHierarchiesForProject(
        'getProductHierarchiesForProject',
        projectId
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId && selectedLens === 'product_hierarchy',
    }
  )

  const lensList = useMemo(
    () =>
      defaultFilterList
        ?.filter((el) => !!(el as FilterOptionSelect).values)
        ?.filter((el) => focusFields.includes(el.field))
        ?.map((el) => ({
          label: el.alias === 'Source Type' ? 'Sources' : `${el.alias}s`,
          value: el.field,
        })),
    [defaultFilterList]
  )

  const { data: sourceData, isLoading: sourcesLoading } = useQuery(
    ['sourcegroup', false, projectId],
    () => getSourceGroups('sourcegroup', false, projectId),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId && selectedLens === 'source_type',
    }
  )

  const { data: syndicatedSourceData, isLoading: syndicatedSourceLoading } =
    useQuery(
      ['syndicated_source_group', true, projectId],
      () => getSourceGroups('syndicated_source_group', true, projectId),
      {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        staleTime: Infinity,
        cacheTime: Infinity,
        enabled: !!projectId && selectedLens === 'source_type',
      }
    )

  const data: FocusListOption[] = useMemo(() => {
    if (selectedLens === 'product_hierarchy') {
      return (
        hierarchyData?.map(
          (el) =>
            ({
              value: el.hierarchy,
              n_posts: el.review_count,
              image_url: el.image_url,
              product_count: el.product_count,
            } as any)
        ) ?? []
      )
    } else if (selectedLens === 'source_type') {
      return (
        sourceData?.map(
          (el) =>
            ({
              value: el.source,
              n_posts: el.reviews,
              image_url: el.source,
              product_count: el.nsources,
            } as any)
        ) ?? []
      )
    } else {
      const values =
        (
          defaultFilterList.find(
            (el) => el.field === selectedLens
          ) as FilterOptionSelect
        )?.values ?? []
      if (selectedLens === 'theme') {
        if (isLLMDash) {
          const groupThemeValues = []
          const valueMap: any = {}
          values.forEach((el) => {
            const groupName = el.value.split(': ')[0]
            if (valueMap[groupName]) {
              valueMap[groupName].n_posts += el.n_posts
              valueMap[groupName].identifiers.push(el.cluster_uuid ?? el.value)
            } else {
              valueMap[groupName] = _.cloneDeep(el)
              valueMap[groupName].value = groupName
              valueMap[groupName].identifiers = [el.cluster_uuid ?? el.value]
            }
          })
          for (const key in valueMap) {
            if (key !== 'Critics / Detractors' && key !== 'Fans / Attractors') {
              groupThemeValues.push(valueMap[key])
            }
          }
          return groupThemeValues
        } else {
          return values.filter(
            (el) =>
              el.value !== 'Critics / Detractors' &&
              el.value !== 'Fans / Attractors'
          )
        }
      }
      return values
    }
  }, [defaultFilterList, selectedLens, hierarchyData, sourceData])

  const [sortField, setSortField] = useState<'title' | 'date' | 'active'>(
    'title'
  )
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')

  const [searchTerm, setSearchTerm] = useState('')

  const onSearch = (e: any) => {
    setSearchTerm(e.target.value)
  }

  const dedupedValues: any = {}

  useEffect(() => {
    if (!data) return
    let sortData = _.cloneDeep(data)
    sortData.forEach((item: FocusListOption) => {
      if (!dedupedValues[item.value]) {
        dedupedValues[item.value] = item
        dedupedValues[item.value].identifiers = item?.identifiers ?? [
          item.value,
        ]
      } else {
        dedupedValues[item.value].n_posts += item.n_posts
        if (item.product_count) {
          dedupedValues[item.value].product_count += item.product_count
        }
      }
      dedupedValues[item.value].identifiers.concat(item.identifiers)
    })

    sortData = Object.keys(dedupedValues).map((key) => ({
      value: key,
      n_posts: dedupedValues[key].n_posts,
      product_count: dedupedValues[key].product_count,
      image_url: dedupedValues[key].image_url,
      identifiers: dedupedValues[key].identifiers,
    }))

    sortData.sort((a, b) => {
      let result

      if (sortField === 'title') {
        result = a.value.localeCompare(b.value)
      }
      // else if (sortField === 'date') {
      //   result =
      //     new Date(a.last_review).getTime() - new Date(b.last_review).getTime()
      // }
      else {
        result = a.n_posts - b.n_posts
      }

      return sortOrder === 'asc' ? result : -result
    })

    if (searchTerm) {
      sortData = sortData.filter((item) =>
        item.value.toLowerCase().includes(searchTerm.toLowerCase())
      )
    }
    setFocusListData(sortData)
  }, [data, sortField, sortOrder, searchTerm])

  return (
    <Container ref={containerRef}>
      <Header>
        <h1 style={{ margin: 0 }}>Catalog</h1>
        <div style={{ flexBasis: '50%' }}>
          <Omnibar size={'small'} />
        </div>
      </Header>
      <Controls>
        <FilterControls>
          {/* TODO make like chart lookup search */}
          <StyledSelect
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            style={{ width: 250 }}
            value={selectedLens}
            onChange={(v: any) => {
              mixpanel.track('catalog', { action: 'lens', value: v })
              setSelectedLens(v)
            }}
            placeholder="Catalog Lens"
            options={lensList}
          >
            {/*{lensList?.map((option: any) => (*/}
            {/*  <Select.Option*/}
            {/*    value={option.value}*/}
            {/*    key={option.value}*/}
            {/*    selected={option.value === selectedLens}*/}
            {/*  >*/}
            {/*    {option.label}*/}
            {/*  </Select.Option>*/}
            {/*))}*/}
          </StyledSelect>
          <SearchContainer>
            <YogiSearch onChange={onSearch} />
          </SearchContainer>
        </FilterControls>
        <SortControls>
          <label>
            Sort by:&nbsp;
            <Select value={sortField} onChange={(value) => setSortField(value)}>
              <Option value="title">Title</Option>
            </Select>
          </label>
          <StyledButton
            onClick={() =>
              setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'))
            }
          >
            {sortOrder === 'asc' ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
          </StyledButton>
        </SortControls>
      </Controls>
      <Content>
        {(selectedLens === 'product_hierarchy' && isLoading) ||
        (selectedLens === 'source_type' &&
          (sourcesLoading || syndicatedSourceLoading)) ? (
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Loader style={{ height: '50%', maxHeight: 300 }} />
            <LoaderTitle>{`Loading Options`}</LoaderTitle>
          </div>
        ) : (
          <>
            {selectedLens === 'source_type' && (
              <h2 style={{ marginLeft: 20, marginBottom: 0 }}>
                Original Sources{' '}
                <Tooltip title={tooltip['original-sources']} placement="right">
                  <InfoCircleOutlined
                    style={{ fontSize: '16px', cursor: 'pointer' }}
                  />
                </Tooltip>
              </h2>
            )}

            <VirtuosoGridComponent
              focusListData={focusListData}
              selectedLens={selectedLens}
              setSelectedFocus={setSelectedFocus}
              compareSet={compareSet}
              setCompareSet={setCompareSet}
            />
            {/*<Grid container spacing={2} style={{ padding: 20, marginTop: -30 }}>*/}
            {/*  {focusListData?.map((focus, index) => (*/}
            {/*    <Grid item xs={12} sm={6} md={4} key={index} spacing={2}>*/}
            {/*      <FocusListItem*/}
            {/*        item={focus}*/}
            {/*        category={selectedLens}*/}
            {/*        title={focus.value}*/}
            {/*        reviewCount={focus.n_posts}*/}
            {/*        identifiers={focus.identifiers}*/}
            {/*        productCount={focus.product_count}*/}
            {/*        imageUrl={focus.image_url}*/}
            {/*        onSelect={setSelectedFocus}*/}
            {/*        compareSet={compareSet}*/}
            {/*        onCompare={setCompareSet}*/}
            {/*      />*/}
            {/*    </Grid>*/}
            {/*  ))}*/}
            {/*</Grid>*/}
          </>
        )}
        {selectedLens === 'source_type' && (
          <>
            <h2 style={{ marginLeft: 20, marginTop: 10, marginBottom: 0 }}>
              Syndicated Sources{' '}
              <Tooltip title={tooltip['syndicated-sources']} placement="right">
                <InfoCircleOutlined
                  style={{ fontSize: '16px', cursor: 'pointer' }}
                />
              </Tooltip>
            </h2>
            <div
              style={{ display: 'flex', flexWrap: 'wrap', margin: '0px 10px' }}
            >
              {syndicatedSourceData && (
                <SourceList
                  sources={syndicatedSourceData.filter((el) =>
                    el.source.toLowerCase().includes(searchTerm.toLowerCase())
                  )}
                  syndicated={true}
                  projectId={projectId}
                  role={details.role}
                />
              )}
            </div>
          </>
        )}
      </Content>
      {/*)}*/}
      {/*<AlertDrawer isOpen={!!compareSet.length}>*/}
      {/*  <div>*/}
      {/*    {compareSet.map((item, index) => (*/}
      {/*      <div>{item}</div>*/}
      {/*    ))}*/}
      {/*  </div>*/}
      {/*  <div>*/}
      {/*    <YogiButton*/}
      {/*      type={'primary'}*/}
      {/*      onClick={() => setSelectedFocus(compareSet)}*/}
      {/*    >*/}
      {/*      Compare*/}
      {/*    </YogiButton>*/}
      {/*  </div>*/}
      {/*</AlertDrawer>*/}
    </Container>
  )
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  background: white;
  padding: var(--default-padding-half) var(--default-padding);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Controls = styled.div`
  padding: 18px;
`

const FilterControls = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  gap: 20px;
  width: 100%;
`

const SortControls = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;
`
const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 30%;
  width: 100%;
`

const Content = styled.div`
  //padding: var(--default-padding-half) var(--default-padding);
  height: 100%;
  overflow: auto;
`

const StyledSelect = styled(Select)`
  height: 36px;

  .ant-select {
    height: 100%;
  }

  .ant-select-selector {
    height: 100% !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 100%;
  }

  //.ant-switch-inner {
  //  font-size: 12px;
  //}
`

export default FocusList

const LoaderTitle = styled.div`
  text-align: center;
  font-size: var(--font-size-l);
  font-weight: 500;
`

const StyledButton = styled(Button)`
  border-radius: 6px;
  border: 2px solid ${cardBorderGrey};
  color: ${mainText};
  &:hover {
    border-color: ${buttonBlue};
  }
`
