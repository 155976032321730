import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useQuery } from 'react-query'
import { Typography, Button, Modal, Select } from 'antd'
import { ProjectEditForm } from 'features/project-form'
import { Loader } from 'shared/components'
import { ProductEdit } from './product-edit'
import { ProductPanel, ProductSearch } from '../components'
import { getProductHierarchiesForProject } from '../model'
import { ProjectStatuses } from 'features/home/types'
import mixpanel from 'features/trackers/mixpanel'
import { useProjectStore } from '../../../projectStore/projectStore'
import { ProjectState } from '../../../types'
import { useProject } from '../../../hooks/useProject'
import { OpenInNewOutlined } from '@mui/icons-material'
import { useGroupProjectList } from '../../../hooks/useGroupProjectList'
import {
  buttonBlue,
  secondaryNavy,
} from '../../../../../assets/styles/variables'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { CATALOG_URL } from '../../../utils'
import { YogiButton } from '../../../../../components/UI/YogiButton'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'

type Props = {
  projectId: string
}

export const Product: React.FC<Props> = ({ projectId }) => {
  const parentDetails = useProjectStore((state: ProjectState) => state.details)
  const [selectedProject, setSelectedProject] = useState('')
  const activeProject = selectedProject ? selectedProject : projectId
  const { data: details } = useProject(activeProject)
  const isAdmin = details?.role === 'admin'

  const { data: groupProjects, isLoading: groupLoading } =
    useGroupProjectList(projectId)

  const { data: feature_flags } = useFeatureFlags()

  const { isLoading, isError, data, error } = useQuery(
    ['getProductHierarchiesForProject', activeProject],
    () =>
      getProductHierarchiesForProject(
        'getProductHierarchiesForProject',
        activeProject,
      ),
    { refetchOnWindowFocus: false },
  )

  const [limit, setLimit] = useState(20)
  const [input, setInput] = useState<string>('')
  const [isEditModalVisible, setEditModalVisible] = useState<boolean>(false)
  const [isProjectEditModalVisible, setProjectEditModalVisible] =
    useState(false)

  useEffect(() => {
    mixpanel.track('products page')
  }, [])

  const filtered =
    data?.filter((item) => {
      return item.hierarchy
        .trim()
        .toLowerCase()
        .includes(input.trim().toLowerCase())
    }) || []

  // this is used so UI doesnt flicker when changing project on a grouped project view
  const [curDetails, setCurDetails] = useState(parentDetails)

  useEffect(() => {
    if (details) {
      setCurDetails(details)
    }
  }, [details])

  // todo put this in context/state?
  const isGroupProject =
    groupProjects?.data?.projects?.length &&
    groupProjects?.data?.projects?.length > 1

  const isViewingGroupParent = isGroupProject && !selectedProject

  if (!curDetails) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    )
  }

  if (isLoading || groupLoading) {
    return (
      <LoaderWrapper>
        <Loader />
        <LoaderTitle>{`Loading ${
          curDetails?.clientSettings?.project?.pages?.products?.alias ??
          'Products'
        } Page`}</LoaderTitle>
      </LoaderWrapper>
    )
  }

  if (isError) {
    return <span> Error: {error?.toString() ?? 'Error Fetching Products'}</span>
  }

  return (
    <Wrapper id="product-container">
      {feature_flags?.verbatim_homepage && (
        <Banner>
          <InfoCircleOutlined style={{ marginRight: 10 }} />{' '}
          <span style={{ marginTop: 3 }}>
            Product page will be moving to the Catalog page on April 30th
          </span>
          <Link
            to={`${CATALOG_URL}?category=theme`}
            target={'_blank'}
            rel="noopener noreferrer"
            style={{
              textDecoration: 'none',
              color: 'white',
              marginRight: 5,
              marginLeft: 16,
            }}
          >
            {' '}
            <YogiButton type="dashed" style={{ display: 'flex' }}>
              <span style={{ fontSize: 18, marginTop: -3, marginRight: 5 }}>
                Open Catalog
              </span>
              <OpenInNewOutlined />
            </YogiButton>
          </Link>
        </Banner>
      )}

      <Body>
        <Flex>
          {/*TODO this probably gets simplified*/}
          {groupProjects?.data?.projects?.length &&
            groupProjects?.data?.projects?.length > 1 && (
              <SelectBody>
                <Select
                  size="large"
                  value={selectedProject}
                  onChange={(e) => setSelectedProject(e)}
                  style={{ marginLeft: 20 }}
                >
                  <Select.Option value={''}>{'All Categories'}</Select.Option>
                  {groupProjects.data.projects.map((project) => (
                    <Select.Option key={project.uuid} value={project.uuid}>
                      {project.title}
                    </Select.Option>
                  ))}
                </Select>
              </SelectBody>
            )}
          <ProductSearch
            input={input}
            updateInput={(e: React.ChangeEvent<HTMLInputElement>) =>
              setInput(e.target.value)
            }
          />
          {isAdmin && !isViewingGroupParent && (
            <>
              <StyledButton
                style={{ marginLeft: 'auto' }}
                type="primary"
                disabled={details.state !== ProjectStatuses.COMPLETE}
                onClick={() => setProjectEditModalVisible(true)}
              >
                Add Products
              </StyledButton>
              <StyledButton
                type="primary"
                onClick={() => setEditModalVisible(true)}
              >
                Edit Products
              </StyledButton>
            </>
          )}
        </Flex>

        <TitleWrapper>
          <Typography.Title>Products</Typography.Title>
        </TitleWrapper>

        <ProductWrapper id="product-list">
          <ProductPanel
            data={filtered.slice(0, limit)}
            projectId={activeProject}
          />
        </ProductWrapper>
        {filtered.length > limit && (
          <LoadMore>
            <Button
              size="large"
              onClick={() => setLimit(limit + 20)}
              type="primary"
            >
              Load more
            </Button>
          </LoadMore>
        )}
      </Body>
      <Modal
        title="Edit Products"
        visible={isEditModalVisible}
        width={800}
        footer={null}
        onCancel={() => setEditModalVisible(false)}
      >
        <ProductEdit />
      </Modal>

      {isProjectEditModalVisible && (
        <ProjectEditForm
          handleClose={() => setProjectEditModalVisible(false)}
          projectId={projectId}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
`
const Body = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 var(--default-padding);
`
const Flex = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
`
const TitleWrapper = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  width: 100%;
  margin-bottom: 10px;
  padding-left: 53px;
`
const ProductWrapper = styled.div`
  align-items: center;
  justify-content: center;
  width: 100%;
`
const LoaderWrapper = styled.div`
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const LoadMore = styled.div`
  width: 100%;
  padding: var(--default-padding) 0;
  display: flex;
  justify-content: center;
`
const StyledButton = styled(Button)`
  margin-left: var(--default-padding-half);
`

const LoaderTitle = styled.div`
  //margin-top: var(--default-padding);
  text-align: center;
  font-size: var(--font-size-l);
  font-weight: 500;
`

const SelectBody = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  margin: 0 10px;
  top: 30%;
  margin-top: -10px;
`

const Banner = styled.div`
  padding: 0px 20px;
  height: 50px;
  background: ${secondaryNavy};
  color: white;
  display: flex;
  align-items: center;
  font-size: 24px;
`

const OpenInNewOutlinedStyled = styled(OpenInNewOutlined)`
  transition:
    fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 0.2s ease !important;

  &:hover {
    color: ${buttonBlue};
  }
`
