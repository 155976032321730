import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import FocusList, { FocusListOption } from './Components/FocusList/FocusList'
import FocusDetail from './FocusDetail'
import { useProjectStore } from '../../projectStore/projectStore'
import { ProjectState } from '../../types'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router'
import { LogoSpinner } from '../../../../components/LogoSpinner'
import { InnerTitle } from '../../../../components/InnerTitle'
import mixpanel from '../../../trackers/mixpanel'

// fyi this component used to be called Focus
export const Catalog: React.FC = () => {
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList
  )
  const location = useLocation()
  const history = useHistory()

  const [focusListData, setFocusListData] = useState<FocusListOption[]>([])

  const [selectedLens, setSelectedLens] = useState<string | null>(
    'product_hierarchy'
  )
  // TODO this should be a FocusData object so we can request information from themes with same name across grouped dash
  const [selectedFocus, setSelectedFocus] = useState<string | null>(null)

  useEffect(() => {
    mixpanel.track('catalog page')
  }, [])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    setSelectedLens(urlParams.get('category') || 'product_hierarchy')
    // @ts-ignore
    setSelectedFocus(!!urlParams.get('item') ? urlParams.get('item') : null)
  }, [location.search])

  const updateLens = (lens: string) => {
    // update url params to reflect lens
    const urlParams = new URLSearchParams(window.location.search)
    urlParams.set('category', lens)
    history.push(`${window.location.pathname}?${urlParams.toString()}`)
  }

  const updateFocus = (focus: string | null) => {
    // update url params to reflect catalog value(s)
    const urlParams = new URLSearchParams(window.location.search)
    if (focus) {
      urlParams.set('item', focus)
    } else {
      urlParams.delete('item')
    }
    history.push(`${window.location.pathname}?${urlParams.toString()}`)
  }

  const focusItem = focusListData.find((item) => item.value === selectedFocus)

  if (!defaultFilterList.length) {
    return (
      <Container>
        <LogoSpinner>
          <InnerTitle>Loading Catalog</InnerTitle>
        </LogoSpinner>
      </Container>
    )
  }

  return (
    <Container>
      {!focusItem ? (
        <FocusList
          setSelectedFocus={updateFocus}
          selectedLens={selectedLens}
          setSelectedLens={updateLens}
          focusListData={focusListData}
          setFocusListData={setFocusListData}
        />
      ) : (
        <>
          {selectedLens && selectedFocus && (
            <FocusDetail
              item={focusItem}
              lens={selectedLens}
              setValue={updateFocus}
            />
          )}
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  height: calc(100vh - 40px);
`
