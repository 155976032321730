import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { YogiSearch } from '../../../../../components/UI/YogiSearch'
import { InnerTitle } from '../../../../../components/InnerTitle'
import {
  BarChartOutlined,
  CommentOutlined,
  DotChartOutlined,
  EyeOutlined,
  FileSearchOutlined,
} from '@ant-design/icons'
import {
  mainText,
  secondaryNavy,
  secondaryRed,
} from '../../../../../assets/styles/variables'
import { useHistory } from 'react-router-dom'
import { CHARTS_URL } from '../../../utils'
import { SavedView } from '../../saved-vews/types'
import {
  initialState,
  useProjectStore,
} from '../../../projectStore/projectStore'
import { CompassResponse, ProjectState } from '../../../types'
import { Grid } from '@mui/material'
import { useSavedViews } from '../../saved-vews/utils'
import mixpanel from '../../../../trackers/mixpanel'
import { updateLastRecentlyUsed } from '../../saved-vews/model'
import { PostsEmpty } from '../../../../../components/PostsEmpty'
import Divider from '../../../../../components/UI/YogiDivider'
import _ from 'lodash'
import { Input } from 'antd'
import { PillContainer } from '../../../../../components/UI/Pills/PillContainer'
import { Pill } from '../../../../../components/UI/Pills/Pill'
import { VerticalDivider } from '../../../../../components/UI/VerticalDivider'
import {
  FilterOption,
  FilterOptionSelect,
  FilterOptionValue,
} from '../../filters/types'

type Props = {
  data: CompassResponse
  savedViews: SavedView[]
  headerHeight?: number
  filterList: Array<FilterOption>
}

const savedIconStyle = {
  fontSize: '20px',
  color: secondaryNavy,
}
const suggestedIconStyle = {
  fontSize: '20px',
  color: secondaryRed,
}

// this is stupid
export const savedIcons: { [key: string]: JSX.Element } = {
  dashboard: <BarChartOutlined style={savedIconStyle} />,
  'dashboard-new': <BarChartOutlined style={savedIconStyle} />,
  feedback: <CommentOutlined style={savedIconStyle} />,
  network: <DotChartOutlined style={savedIconStyle} />,
  keywords: <FileSearchOutlined style={savedIconStyle} />,
  default: <EyeOutlined style={savedIconStyle} />,
}
export const suggestedIcons: { [key: string]: JSX.Element } = {
  dashboard: <BarChartOutlined style={suggestedIconStyle} />,
  'dashboard-new': <BarChartOutlined style={suggestedIconStyle} />,
  feedback: <CommentOutlined style={suggestedIconStyle} />,
  network: <DotChartOutlined style={suggestedIconStyle} />,
  keywords: <FileSearchOutlined style={suggestedIconStyle} />,
  default: <EyeOutlined style={suggestedIconStyle} />,
}

export const Content: React.FC<Props> = ({
  data,
  savedViews,
  headerHeight,
  filterList,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const setFilters = useProjectStore((state: ProjectState) => state.setFilters)
  const history = useHistory()

  const {
    // question_answer_panels: questionAnswerPanels,
    quick_launch_panels: quickLaunchPanels,
  } = data

  const datasets: FilterOptionSelect = filterList.find(
    (el) => el.field === 'proj_uuid',
  ) as FilterOptionSelect

  const { updateFilters } = useSavedViews()

  const [searchedViews, setSearchedViews] = useState<SavedView[]>(savedViews)
  const [searchedDatasets, setSearchedDatasets] = useState<FilterOptionValue[]>(
    datasets?.values ?? [],
  )
  const [searchTerm, setSearchTerm] = useState<string>('')

  useEffect(() => {
    const filteredViews = savedViews.filter((view) => {
      return view.name.toLowerCase().includes(searchTerm.toLowerCase())
    })
    setSearchedViews(filteredViews)
    const filteredDatasets =
      datasets?.values?.filter((dataset) => {
        return dataset.value.toLowerCase().includes(searchTerm.toLowerCase())
      }) ?? []
    setSearchedDatasets(filteredDatasets)
  }, [searchTerm])

  const allCategories: string[] = []
  const categories: string[] = []

  const suggestedViewsObj: Record<string, SavedView[]> = {}
  searchedViews.forEach((view) => {
    if (view.view_type) {
      if (!suggestedViewsObj[view.view_type]) {
        suggestedViewsObj[view.view_type] = []
        categories.push(view.view_type)
      }
      suggestedViewsObj[view.view_type].push(view)
    }
  })
  savedViews.forEach((view) => {
    if (view.view_type) {
      if (!allCategories.includes(view.view_type)) {
        allCategories.push(view.view_type)
      }
    }
  })

  const [selectedType, setSelectedType] = useState('')

  const selfViews = searchedViews.filter((view) => view.author)
  const otherViews = searchedViews.filter((view) => !view.author)
  const questions = otherViews.filter((view) => view.name.includes('?'))
  const suggested = otherViews.filter((view) => !view.name.includes('?'))

  const [tempSearch, setTempSearch] = useState(
    'How is Battery Life being talked about for the Surface Pro 9 compared to the Surface Pro X?',
  )
  const [search, setSearch] = useState('')

  return (
    <Container headerHeight={headerHeight}>
      {/*<div style={{ width: '100%' }}>*/}
      {/*  <h3>How can Yogi help you today?</h3>*/}
      {/*  {search && (*/}
      {/*    <NLInput*/}
      {/*      userMessage={search}*/}
      {/*      aiResponse={*/}
      {/*        'According to data analysis, the Surface Pro X has received about twice as many reviews mentioning battery life as the Surface Pro 9. However, when comparing the rating distribution for battery life, the Surface Pro 9 outperforms the Pro X. Specifically, the i5/8GB/256GB model of the Surface Pro 9 has the highest share of 5-star reviews at 70%, while the Q/8/128 model of the Surface Pro X has only 56% 5-star reviews in the same category.' +*/}
      {/*        '\n' +*/}
      {/*        '\n' +*/}
      {/*        'Based off of the higher review quantity and lower average rating and sentiment of Battery Life for the Surface Pro X, this theme has become a negative driver overall in comparison to the Surface Pro 9.'*/}
      {/*      }*/}
      {/*    />*/}
      {/*  )}*/}
      {/*  <div*/}
      {/*    style={{*/}
      {/*      display: 'flex',*/}
      {/*      gap: '20px',*/}
      {/*      width: '100%',*/}
      {/*      marginBottom: '10px',*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <StyledInput*/}
      {/*      value={tempSearch}*/}
      {/*      onChange={(e: any) => setTempSearch(e.target.value)}*/}
      {/*      placeholder="Ask Yogi a question"*/}
      {/*      style={{*/}
      {/*        border: '2px solid ' + cardBorderGrey,*/}
      {/*        borderRadius: '6px',*/}
      {/*      }}*/}
      {/*    />*/}
      {/*    <YogiButton*/}
      {/*      type={'primary'}*/}
      {/*      onClick={() => {*/}
      {/*        setSearch(tempSearch)*/}
      {/*        setTempSearch('')*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      Search*/}
      {/*    </YogiButton>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<Divider style={{ margin: '5px 0px' }} />*/}
      <Controls>
        <SearchContainer>
          <YogiSearch
            onChange={(event: any) => setSearchTerm(event.target.value)}
          />
        </SearchContainer>
        <PillContainer>
          <Pill
            value={''}
            selected={!selectedType}
            onClick={() => {
              setSelectedType('')
            }}
          >
            All Suggested
          </Pill>
          <VerticalDivider />
          {allCategories.map((el, index) => (
            <>
              <Pill
                key={index}
                value={el}
                selected={el === selectedType}
                onClick={() => {
                  setSelectedType(el)
                }}
              >
                {_.startCase(_.camelCase(el)).replaceAll(' And ', ' & ')}
              </Pill>
            </>
          ))}
        </PillContainer>
      </Controls>
      <Body>
        {datasets && (
          <>
            <QuickLaunchWrapper style={{ marginRight: 10 }}>
              <InnerTitle style={{ marginBottom: '15px' }}>Datasets</InnerTitle>
              <QuestionList container spacing={2} alignItems={'stretch'}>
                {searchedDatasets.map((el, index) => {
                  return (
                    <QuestionListItem
                      key={index}
                      item
                      xs={12}
                      alignItems={'stretch'}
                    >
                      <a
                        style={{
                          textDecoration: 'none',
                          color: mainText,
                        }}
                        href={`#`}
                        onClick={(e) => {
                          e.preventDefault()
                        }}
                      >
                        <QuestionCard
                          onClick={() => {
                            mixpanel.track('home dataset', {
                              action: 'open',
                              value: el.value,
                            })
                            // TODO could be a
                            if (el.cluster_uuid) {
                              const datasetFilters = JSON.parse(
                                JSON.stringify(initialState.filters[0]),
                              )
                              datasetFilters.values = [
                                {
                                  field: 'proj_uuid',
                                  values: [el.cluster_uuid],
                                },
                              ]
                              setFilters([datasetFilters])
                              history.push(
                                `/project/${projectId}/${CHARTS_URL}`,
                              )
                            }
                          }}
                        >
                          <QuestionTitle>{el.value}</QuestionTitle>
                        </QuestionCard>
                      </a>
                    </QuestionListItem>
                  )
                })}
              </QuestionList>
            </QuickLaunchWrapper>

            <VerticalDivider />
          </>
        )}
        <QuickLaunchWrapper style={{ marginLeft: 10 }}>
          <InnerTitle style={{ marginBottom: '15px' }}>Saved Views</InnerTitle>
          <LaunchCardList container spacing={2}>
            {!selfViews?.length && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <PostsEmpty>No Saved Views...yet!</PostsEmpty>
              </div>
            )}
            {selfViews?.map((el, index) => (
              <LaunchCardListItem key={index} item xs={12}>
                <a
                  style={{ textDecoration: 'none', color: mainText }}
                  href={`${window.location.origin}/project/${projectId}/${el.page}${el.search_url}`}
                  onClick={(e) => {
                    e.preventDefault()
                  }}
                >
                  <LaunchCard
                    key={index}
                    onClick={() => {
                      mixpanel.track('home saved view', {
                        action: 'load',
                        type: 'user',
                        value: el.name,
                      })
                      updateLastRecentlyUsed(projectId, el.uuid)
                      updateFilters(el)
                    }}
                  >
                    <Icon>
                      {/*<TrendingUp style={{ color: secondaryGreen }} />*/}
                      {/*<TrendingUp style={{ color: secondaryGreen }} />*/}
                      {savedIcons[el?.page || 'default']}
                    </Icon>
                    <LaunchTitle>{el.name}</LaunchTitle>
                  </LaunchCard>
                </a>
              </LaunchCardListItem>
            ))}
          </LaunchCardList>
        </QuickLaunchWrapper>
        <VerticalDivider />
        <QuestionAnswerWrapper>
          {/*<InnerTitle style={{ marginBottom: '15px' }}>*/}
          {/*  <strong>Q&As</strong>*/}
          {/*</InnerTitle>*/}
          {/*<QuestionList container spacing={2}>*/}
          {/*  {!questions.length && (*/}
          {/*    <div*/}
          {/*      style={{*/}
          {/*        display: 'flex',*/}
          {/*        flexDirection: 'column',*/}
          {/*        justifyContent: 'center',*/}
          {/*        alignItems: 'center',*/}
          {/*        width: '100%',*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      <PostsEmpty>No Q&As...yet!</PostsEmpty>*/}
          {/*    </div>*/}
          {/*  )}*/}
          {/*  {questions.map((el, index) => (*/}
          {/*    <QuestionListItem key={index} item md={12} lg={6}>*/}
          {/*      <QuestionCard*/}
          {/*        onClick={() => {*/}
          {/*          mixpanel.track('home saved view', {*/}
          {/*            action: 'load',*/}
          {/*            type: 'project',*/}
          {/*            value: el.name,*/}
          {/*          })*/}
          {/*          updateFilters(el)*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        <QuestionHeader>*/}
          {/*          <Icon>*/}
          {/*            <Compare style={{ color: secondaryRed }} />*/}
          {/*          </Icon>*/}
          {/*          <QuestionTitle>{el.name}</QuestionTitle>*/}
          {/*        </QuestionHeader>*/}
          {/*        /!*<QuestionText>{el.description}</QuestionText>*!/*/}
          {/*      </QuestionCard>*/}
          {/*    </QuestionListItem>*/}
          {/*  ))}*/}
          {/*</QuestionList>*/}
          <InnerTitle style={{ marginBottom: '15px' }}>
            Suggested Views
          </InnerTitle>
          {(!otherViews.length ||
            Object.keys(suggestedViewsObj).length === 0) && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                marginTop: '-15px',
              }}
            >
              <PostsEmpty>No Suggested Views...yet!</PostsEmpty>
            </div>
          )}
          <SuggestedContainer>
            {categories.map((el, index) => {
              if (!selectedType || selectedType === el)
                return (
                  <SuggestedSection>
                    <span style={{ fontWeight: 500 }}>
                      {_.startCase(_.camelCase(el)).replaceAll(' And ', ' & ')}
                    </span>
                    <Divider style={{ marginBottom: 10, marginTop: 5 }} />
                    <QuestionList container spacing={2} alignItems={'stretch'}>
                      {suggestedViewsObj[el].map((el, index) => (
                        <QuestionListItem
                          key={index}
                          item
                          md={12}
                          lg={6}
                          alignItems={'stretch'}
                        >
                          <a
                            style={{
                              textDecoration: 'none',
                              color: mainText,
                            }}
                            href={`${window.location.origin}/project/${projectId}/${el.page}${el.search_url}`}
                            onClick={(e) => {
                              e.preventDefault()
                            }}
                          >
                            <QuestionCard
                              onClick={() => {
                                mixpanel.track('home saved view', {
                                  action: 'load',
                                  type: 'project',
                                  value: el.name,
                                })
                                updateFilters(el)
                              }}
                            >
                              <QuestionHeader>
                                {/*<Compare style={{ color: secondaryRed }} />*/}

                                {/*<Icon>{suggestedIcons[el?.page || 'default']}</Icon>*/}
                                <Icon>
                                  {suggestedIcons[el?.page || 'default']}
                                </Icon>
                                <QuestionTitle>{el.name}</QuestionTitle>
                              </QuestionHeader>
                              {/*<QuestionText>{el.description}</QuestionText>*/}
                            </QuestionCard>
                          </a>
                        </QuestionListItem>
                      ))}
                    </QuestionList>
                  </SuggestedSection>
                )
            })}
          </SuggestedContainer>
          {/*<QuestionList container spacing={2}>*/}
          {/*  {!otherViews.length && (*/}
          {/*    <div*/}
          {/*      style={{*/}
          {/*        display: 'flex',*/}
          {/*        flexDirection: 'column',*/}
          {/*        justifyContent: 'center',*/}
          {/*        alignItems: 'center',*/}
          {/*        width: '100%',*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      <PostsEmpty>No Suggested Views...yet!</PostsEmpty>*/}
          {/*    </div>*/}
          {/*  )}*/}
          {/*  {otherViews.map((el, index) => (*/}
          {/*    <QuestionListItem key={index} item md={12} lg={6}>*/}
          {/*      <QuestionCard*/}
          {/*        onClick={() => {*/}
          {/*          mixpanel.track('home saved view', {*/}
          {/*            action: 'load',*/}
          {/*            type: 'project',*/}
          {/*            value: el.name,*/}
          {/*          })*/}
          {/*          updateFilters(el)*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        <QuestionHeader>*/}
          {/*          /!*<Compare style={{ color: secondaryRed }} />*!/*/}

          {/*          /!*<Icon>{suggestedIcons[el?.page || 'default']}</Icon>*!/*/}
          {/*          <Icon>*/}
          {/*            <Compare style={{ color: secondaryRed }} />*/}
          {/*          </Icon>*/}
          {/*          <QuestionTitle>{el.name}</QuestionTitle>*/}
          {/*        </QuestionHeader>*/}
          {/*        /!*<QuestionText>{el.description}</QuestionText>*!/*/}
          {/*      </QuestionCard>*/}
          {/*    </QuestionListItem>*/}
          {/*  ))}*/}
          {/*</QuestionList>*/}
        </QuestionAnswerWrapper>
      </Body>
    </Container>
  )
}

const OnePageContainer = styled.div<{ headerHeight: number | undefined }>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 20px 40px;
  max-width: 1200px;
  margin: 0 auto;
  // -80 because of padding on header height that doesnt come through ref for some reason
  height: ${(props) =>
    props.headerHeight
      ? `calc(100vh - 80px - ${props.headerHeight}px)`
      : '100%'};
  overflow: hidden;
`

const Container = styled.div<{ headerHeight: number | undefined }>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 20px 40px;
  max-width: 1400px;
  margin: 0 auto;
  //overflow: auto;
`
const Controls = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  gap: 40px;
  width: 100%;
`
const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 30%;
  width: 100%;
`
const Body = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100% - 50px);
  gap: 10px;
`
const QuickLaunchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 30%;
  width: 100%;
  height: 100%;
`
const QuestionAnswerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 70%;
  width: 100%;
  height: 100%;
  margin-left: 10px;
`
export const LaunchCardList = styled(Grid)`
  //overflow: auto;
  padding-bottom: 10px;
  width: 100%;
  padding-right: 10px;

  /* width */

  //::-webkit-scrollbar {
  //  width: 10px;
  //}
  //
  ///* Track */
  //
  //::-webkit-scrollbar-track {
  //  background: #f1f1f1;
  //}
  //
  ///* Handle */
  //
  //::-webkit-scrollbar-thumb {
  //  background: #888;
  //}
  //
  ///* Handle on hover */
  //
  //::-webkit-scrollbar-thumb:hover {
  //  background: #555;
  //}
`
export const LaunchCardListItem = styled(Grid)``
export const LaunchCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;
  background: white;
  padding: 16px 24px;
  cursor: pointer;
  align-items: center;

  border: 1.5px solid rgba(159, 183, 195, 0.1);
  box-shadow: var(--default-box-shadow);
  border-radius: 8px;
  //border: 2px solid white;

  &:hover {
    background: #f4f4f4;
  }
`
export const Icon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 10%;
`
export const LaunchTitle = styled.div`
  display: flex;
  flex-basis: 90%;
`
const QuestionList = styled(Grid)`
  width: 100%;
  align-items: stretch;
`
const SuggestedContainer = styled.div`
  //overflow: auto;
  width: 100%;
  padding: 0px 10px 10px;
`
const SuggestedSection = styled.div`
  width: 100%;
  margin-bottom: 10px;
`
const QuestionListItem = styled(Grid)``
const QuestionCard = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: white;
  padding: 16px 24px;
  cursor: pointer;
  height: 100%;

  justify-content: center;

  border: 1.5px solid rgba(159, 183, 195, 0.1);
  box-shadow: var(--default-box-shadow);
  border-radius: 8px;
  transition: 0.15s ease-out;

  &:hover {
    background: #f4f4f4;
  }
`
const QuestionHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`
const QuestionTitle = styled.div`
  display: flex;
  flex-basis: 90%;
`
const QuestionText = styled.div`
  color: var(--light-text);
  display: flex;
  flex-basis: 90%;
`

const StyledInput = styled(Input)`
  .ant-input {
    width: 500px;
    background: white;
    border: 2px solid var(--card-border-grey);
    border-radius: 6px;
  }
`
